import React, { useState, useEffect } from "react";
import { Button, Modal, Checkbox } from 'react-materialize';
import $ from 'jquery';
import Html from "../../utils/Html";
import BtnExportSubmit from "./modal.export.btn.submit";

export default function ModalExport() {
    const [itemData, setItemData] = useState({data: {}, type: "", status: "", err: 0, err_msg: ""});
    const [exportType, setExportType] = useState({displayed: 0, all: 1, key: 1});
    let header = "Export to sheet";
    let content = "";
    let footer = "";
    let btns = [
        <div className="header">
            {header}
        </div>
    ];
    let modalCSS = {};
    modalCSS.height = "240px";
    const handleEvents = {
        BtnExport : {
            OnComplete : function() {
                setItemData({data: {}, type: "", status: "", err: 0, err_msg: ""});
                setExportType({displayed: 0, all: 1, key: 1});
            }
        },
        CheckboxExport : { 
            OnChange : function(evt) {
                let value = evt.target.value;
                let typeData = {displayed: 0, all: 0, key: (Math.floor((Math.random() * 10000) + 1))};
                if(value == "all") {
                    typeData.all = 1;
                }
                else {
                    typeData.displayed = 1;
                }
                setExportType(typeData);
            }
        },
        OnClose : function() {
            $("body").css({"overflow": "auto"});
            setItemData({data: {}, type: "", status: "", err: 0, err_msg: ""});
        },
        OnSubmit : function(evt) {
            // Form Submit
            evt.preventDefault();
        } 
    }
    useEffect(() => {
        $("#main-content").on("click", "#btn-export", function(evt){
            setItemData({
                data: {},
                status: "success",
                err: 0,
                err_msg: ""
            });
        });
    }, []);

    if(itemData.status === "") {
        return "";
    }
    else if ('err' in itemData && itemData.err === 1) {
        modalCSS.height = "170px";
        btns.push(<Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>);
        content = <div className="text-error">Fetch request error: {itemData.err_msg}</div>;
    }
    else if (itemData.status !== "success") {
        content = <div>
            <Html.Preloading addon={{
                css: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    margin: "auto"
                }
            }} />
        </div>;
    }
    else {
        //console.log("Edit", itemData);
        btns.push(<Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>); 
        content = <div className="modal-content-outer">
            <div key="modal-content-inner" className="modal-content-inner">
                <Feature.Content 
                    data={exportType} 
                    handleEvents={handleEvents.CheckboxExport} />
            </div>
            <div key="modal-loading" className="modal-loading hide">
                <Html.Preloading addon={{
                    css: {
                        position: "absolute",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        margin: "auto"
                    }
                }} />
            </div>
        </div>
        footer = <div className="modal-footer-bottom">
            <span className="modal-error-text hide"></span>
            <BtnExportSubmit 
                events={handleEvents.BtnExport} 
                header={header} />
        </div>
    }

    return (<Modal
        actions={btns}
        bottomSheet={false}
        fixedFooter={false}
        className="modal-user modal-user-footer"
        id="modal-user-export"
        open={true}
        options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: handleEvents.OnClose,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '5%'
        }}
        root={document.getElementById("root")}
        style={modalCSS}
        >
        {content}
        {footer}
    </Modal>);
}

const Feature = {};
Feature.Content = function({data, handleEvents}) {
    let content = [];
    content.push(<div key="content-export_displayed" className="row" id="content-export_displayed" style={{width: "80%"}}>
        <div className="col s4 title" style={{marginTop: "5px", textAlign: "right"}}>
            <Checkbox
                filledIn
                checked={(data.displayed == 1) ? true : false}
                key={"export-column-displayed-" + data.key}
                key-number={data.key}
                name="export-column-displayed"
                label=""
                value={"displayed"}
                onChange={handleEvents.OnChange}
            />
        </div>
        <span className="col s8 desc" style={{lineHeight: "30px", height: "30px"}}>
            Columns as displayed
        </span>
    </div>);
    content.push(<div key="content-export_all" className="row" id="content-export_all" style={{width: "80%"}}>
        <div className="col s4 title" style={{marginTop: "5px", textAlign: "right"}}>
            <Checkbox
                filledIn
                checked={(data.all == 1) ? true : false}
                key={"export-column-all-" + data.key}
                key-number={data.key}
                name="export-column-all"
                label=""
                value="all"
                onChange={handleEvents.OnChange}
            />
        </div>
        <span className="col s8 desc" style={{lineHeight: "30px", height: "30px"}}>
            All Columns
        </span>
    </div>);
    return <div style={{paddingTop: "15px"}}>
        {content}
    </div>
}
Feature.GetColumns = function() {
    let columns = [];
    columns.push({name: "IDU", value: "idu"});
    columns.push({name: "E-mail", value: "emailadd"});
    columns.push({name: "First Name", value: "fname"});
    columns.push({name: "Last Name", value: "lname"});
    columns.push({name: "Nickname", value: ""});
    columns.push({name: "Job title", value: ""});
    columns.push({name: "Job Class", value: ""});
    columns.push({name: "Job Type", value: ""});
    columns.push({name: "Work Location", value: ""});
    columns.push({name: "Department", value: ""});
    columns.push({name: "", value: ""});
    columns.push({name: "", value: ""});
}