import React from "react";

export default function TrHead() {
    const data = [
        "From",
        "To",
        "Total",
        "Leave type",
        "Status",
        "View",
        "Action"
    ];
    let list = [];
    for(const i in data) {
        list.push(<th key={"col-" + i}>
            {data[i]}
        </th>);
    }
    return <tr key="row-0">
        {list}    
    </tr>
}