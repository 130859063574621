import React, { useState, useEffect } from "react";
import { Table, Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import Func from "../../utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import TrHead from "./main.content.trhead";
import TrContent from "./main.content.trcontent";
import SelectLimit from "./main.content.select.limit";
import SelectPage from "./main.content.select.page";
import Btn from "./main.content.btn";
import BtnExport from "./main.content.btn.export";
import BtnSave from "./main.content.btn.save";
import ModalUpdate from "./modal.update";
import ModalCopy from "./modal.copy";
import ModalModify from "./modal.modify";
import ModalExport from "./modal.export";

export default function MainContent({ userData }) {
  const userID = userData.idu;
  const userCountry = userData.employed_by_code;
  const userSess = userData.user_sess;
  const defaultFilters = {
    country: [userData.is_exo_group == "yes" ? "GROUP" : userCountry],
    year: [Func.GetCurrentYear()],
    limit: 10,
    page: 1,
  };
  const [filters, setFilters] = useState(defaultFilters);
  const defaultData = {
    data: [],
    summary_total: 0,
    total_page: 0,
    active_page: 1,
    expired_date: "",
    status: "",
    err: 0,
    err_msg: "",
  };
  const [reqData, setReqData] = useState(defaultData);
  const [actionData, setActionData] = useState({
    key: 0,
    items: [],
    checked: false,
    pages: [],
  });
  const handleEvents = {
    BtnSave: {
      OnComplete: function () {
        setReqData({ status: "", err: 0, err_msg: "" });
        let filterData = Func.GetDataForm(
          $("#form-filter-request").serializeArray()
        );
        filterData["limit"] = $("#select-limit-row").val();
        filterData["page"] = 1;
        setFilters(filterData);
      },
    },
  };

  useEffect(() => {
    setReqData({ status: "processing" });
    const mainFunc = async () => await API.post("hr/assign/leave", {
      params: encryptJsonObject({
        userID: userID,
        filters: filters,
      })
    });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setReqData(decrypt);
        } else {
          setReqData({
            status: "",
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        //console.log(`Failes : ${err}`);
        setReqData({ status: "", err: 1, err_msg: err });
      });
  }, [filters]);

  let content = "";
  if ("err" in reqData && reqData.err === 1) {
    content = (
      <div className="text-error">Fetch request error: {reqData.err_msg}</div>
    );
  } else if (reqData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "150px",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("Requests", actionData);
    content = (
      <div id="table-content">
        <div id="table-total">
          <div>
            Total :{" "}
            <span id="table-total__number">{reqData.summary_total}</span>
          </div>
          <BtnSave
            year={filters.year}
            events={handleEvents.BtnSave}
            header=""
          />
        </div>
        <form id="form-edit-assign" action="">
          <Table className="highlight" id="table-leave-summary">
            <thead key="thead">
              <TrHead
                setActionData={setActionData}
                actionData={actionData}
                active_page={reqData.active_page}
                year={filters.year}
                userSess={userSess}
                expired_date={reqData.expired_date}
              />
            </thead>
            <tbody key="tbody">
              <TrContent
                data={reqData.data}
                actionData={actionData}
                userData={userData}
                setActionData={setActionData}
              />
            </tbody>
          </Table>
          <input
            type="hidden"
            name="action_items"
            value={JSON.stringify(actionData.items)}
          />
          <input
            type="hidden"
            name="action_pages"
            value={JSON.stringify(actionData.pages)}
          />
          <input type="hidden" name="changed_items" value="" />
        </form>
        <SelectPage
          totalPage={reqData.total_page}
          activePage={reqData.active_page}
          setFilters={setFilters}
          setReqData={setReqData}
        />
      </div>
    );
  }
  return (
    <div id="main-content">
      <Btn
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        setReqData={setReqData}
        setActionData={setActionData}
      />
      <div className="row title">
        Staff Annual Leave Summary
        <BtnExport />
        <SelectLimit
          limit={filters.limit}
          setFilters={setFilters}
          setReqData={setReqData}
        />
      </div>
      {content}
      <ModalUpdate
        year={filters.year}
        setFilters={setFilters}
        setReqData={setReqData}
        setActionData={setActionData}
      />
      <ModalCopy
        year={filters.year}
        setFilters={setFilters}
        setReqData={setReqData}
        setActionData={setActionData}
      />
      <ModalModify
        year={filters.year}
        setFilters={setFilters}
        setReqData={setReqData}
        setActionData={setActionData}
      />
      <ModalExport />
    </div>
  );
}
