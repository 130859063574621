import React from 'react';
import $ from 'jquery';

export default {
    GetDiffDays(startDate, endDate) {
        const diffInMs   = new Date(endDate) - new Date(startDate)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays;
    },
    GetKeyRandom() {
        return (Math.floor((Math.random() * 10000) + 1));
    },
    GetIntersect(a, b) {
        var t;
        if (b.length > a.length) {
            t = b;
            b = a;
            a = t; // indexOf to loop over shorter
        }
        return a.filter(function (e) {
            return b.indexOf(e) > -1;
        });
    },    
    GetEndTime(startTime, duration) {
        let sp = startTime.split(":");
        let hour = parseInt(sp[0]);
        let minute = sp[1];
        if(duration == "HD") { hour += 4; }
        else if(duration == "1H") { hour += 1; }
        else if(duration == "2H") { hour += 2; }
        else if(duration == "3H") { hour += 3; }
        else { hour += 8 + 1; }
        hour = (hour <= 9) ? "0" + hour : hour;
        let endTime =  hour + ":" + minute;
        return endTime;
    },
    GetStartDate(){
        return this.GetCurrentYear() + "-01-01";
    },
    GetEndDate(startDate){
        return this.GetNextMonth(startDate,12);
    },
    GetNextMonth(date, month){
        let dateArray = this.GetDateArray(date);
        let newMonth = dateArray.month + month;
        if(newMonth > 12){
            dateArray.year += 1;
            dateArray.month = (newMonth-12);
        }
        else{
            dateArray.month = newMonth;
        }
        dateArray.month = (dateArray.month <= 9) ? "0" + dateArray.month : dateArray.month;
        const lastDay = new Date(dateArray.year,dateArray.month,"0").getDate();
        if(dateArray.day > lastDay){
            dateArray.day = dateArray.day - lastDay;
            dateArray.month = parseInt(dateArray.month) + 1;
            dateArray.year = (dateArray.month > 12) ? dateArray.year + 1 : dateArray.year;
            dateArray.month = (dateArray.month <= 9) ? "0" + dateArray.month : dateArray.month;
        }
        dateArray.day = (dateArray.day <= 9) ? "0" + dateArray.day : dateArray.day;
        return dateArray.year + "-" + dateArray.month + "-" + dateArray.day;
    },
    GetDateArray(date){
        date = new Date(date);
        return {
            year: date.getFullYear(),
            month: date.getMonth()+1,
            day: date.getDate()
        };
    },
    ConvertDateFormat(date){
        let sp = date.split("-");
        return sp[2] + " " + this.GetShortMonthNames(sp[1]) + " " + sp[0];
    },
    GetMonthNames(month){
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthNames[month-1];
    },
    GetShortMonthNames(month){
        const monthshortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthshortNames[month-1];
    },
    GetDataForm(formArray, type){
        let formData = {};
        if(type == "save_confirm"){
            for(let i in formArray){
                let sp = (formArray[i].name).split("--");
                if(sp.length <= 1){
                    continue;
                }
                let name = sp[0];
                let id = sp[1];
                let value = formArray[i].value;
                if(!(id in formData)){
                    formData[id] = {};
                }
                if(!(name in formData[id])){
                    formData[id][name] = [];
                }
                if(value !== ""){
                    formData[id][name].push(value);
                }
            }
            formData = Object.values(formData);
        }
        else if(type == "save"){
            for(let i in formArray){
                let sp = (formArray[i].name).split("--");
                if(sp.length <= 1){
                    continue;
                }
                let name = sp[0];
                let id = sp[1];
                let elem = $("#row-input-" + name + "-" + id);
                if(!elem.hasClass("changed")){
                    continue;
                }
                let value = formArray[i].value;
                if(!(id in formData)){
                    formData[id] = {};
                }
                if(!(name in formData[id])){
                    formData[id][name] = [];
                }
                if(value !== ""){
                    formData[id][name].push(value);
                }
            }
        }
        else if(type == "noarray") {
            for(let i in formArray){
                let name = formArray[i].name;
                let value = formArray[i].value;
                formData[name] = value;
            }
        }
        else{
            for(let i in formArray){
                let name = formArray[i].name;
                let value = formArray[i].value;
                if(!(name in formData)){
                    formData[name] = [];
                }
                if(value !== ""){
                    formData[name].push(value);
                }
            }
        }
        //console.log(formData);
        return formData;
    },
    GetRangeYears(startYear, EndYear){
        let data = [];
        for(let i=startYear;i<=EndYear;i++){
            data.push({name: i, value: i});
        }
        return data;
    },
    GetCurrentDate(){
        let today = new Date();
        let year = today.getFullYear();
        let month = parseInt(today.getMonth()+1);
        month = (month <= 9) ? "0" + month : month;
        let day = parseInt(today.getDate());
        day = (day <= 9) ? "0" + day : day;
        return year + '-' + month + '-' + day;
    },
    GetCurrentYear(){
        return new Date().getFullYear();
    },
    GetArrayUnique(obj) {
        var a = [];
        for (var i=0, l=obj.length; i<l; i++)
            if (a.indexOf(obj[i]) === -1)
                a.push(obj[i]);
        return a;
    },
    GetArrayColumn(obj, key) {
        let result = [];
        for (let i in obj) {
            let items = obj[i];
            Object.keys(items).forEach((item) => {
                if(key != "" && key == item){
                    result.push(items[item]);
                }
            });
        }
        return result;
    },
    SearchObject(obj, value, key) {
        let values = value;
        if(!Array.isArray(value)){
            values = [value];
        }
        let result = {};
        for (let i in obj) {
            let items = obj[i];
            Object.keys(items).forEach((item) => {
                for(let j in values){
                    value = values[j]
                    if(key != "" && key == item){
                        if(items[item] == value){
                            result[i] = items;
                            break;
                        }
                    }
                    else if(items[item] == value){
                        result[i] = items;
                        break;
                    }
                }
            });
        }
        return result;
    },
    HtmlDecode(value) {
        return $("<textarea/>").html(value).text();
    },
    HtmlEncode(value) {
        return $('<textarea/>').text(value).html();
    },
    Sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    },
    IsEmptyObject(value) {
        return Object.keys(value).length === 0 && value.constructor === Object;
    },
    ConvertStringToObj(string){
        if(string === "" || string === null){
          return {};
        }
        var properties = string.split(', ');
        var obj = {};
        properties.forEach(function(property) {
            var tup = property.split(':');
            obj[tup[0]] = tup[1];
        });
        return obj;
    },
    PublicUrl(){
        return process.env.PUBLIC_URL;
    },
    ImageUrl(){
        return process.env.PUBLIC_URL + "/images/";
    },
    capitalize(s){
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    Space(height = "10px"){
        return <div style={{float: "left", width: "100%", height: height}} />
    },
    LoadingImageUrl(){
        return process.env.PUBLIC_URL + "/images/loading.gif";
    },
    IsArray(obj){
        return !!obj && obj.constructor === Array;
    }
};

