import React, { useState, useEffect } from "react";
import { RadioGroup } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Func from "../../utils/Func";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import ZoomChart from "./create.chart.zoom";
import Function from "./function";

export default function CreateChart({ userData, orgData }) {
  const userID = userData.idu;
  const [chartData, setChartData] = useState({
    data: orgData,
    type: "hybrid",
    status: "",
    err: 0,
    err_msg: "",
  });
  //const [chartData, setChartData] = useState({data: orgData, type: 'functional', status: '', err: 0, err_msg: ''});
  //const [chartData, setChartData] = useState({data: orgData, type: 'role', status: '', err: 0, err_msg: ''});
  var curDown = false,
    curYPos = 0,
    curXPos = 0;
  const handleEvents = {
    SelectType: {
      OnChange: function (evt) {
        let value = evt.target.value;
        //console.log(value);
        setChartData({
          data: orgData,
          type: value,
          status: "",
          err: 0,
          err_msg: "",
        });
      },
    },
  };
  const StaffNumbers = function () {
    if (chartData.status == "processing") {
      return;
    }
    $(".li-dept").each(function () {
      let elem = $(this);
      let div_badge = elem.find(".badge").first();
      let parent_badge = $(div_badge).parents("span").first();
      let numbers = elem.find(".staff").length;
      if (numbers > 0) {
        $(parent_badge).css({ paddingRight: "20px" });
        $(div_badge).text(numbers).css({ display: "block" });
      } else {
        $(parent_badge).css({ paddingRight: "0px" });
        $(div_badge).text("").css({ display: "none" });
      }
    });
  };
  useEffect(() => {
    Function.ImagesLoad("tree", chartData.status, {
      style: { border: "3px solid #fff" },
    });
    StaffNumbers();

    $("#tree").mousedown(function (m) {
      //$("#org-content").on("mousedown", "#tree", function(m){
      curYPos = m.pageY;
      curXPos = $(this).scrollLeft() + m.pageX;
      curDown = true;
    });
    $("#tree").mousemove(function (m) {
      if (curDown) {
        $(this).scrollLeft(curXPos - m.pageX);
        //console.log("Mousemove");
      }
    });
    $("#tree").mouseup(function (m) {
      curDown = false;
    });

    $(".btn-user-role").off("click");
    $(".btn-user-role").on("click", function () {
      let elem = $(this);
      let user_id = elem.attr("user-id");
      setChartData({ status: "processing" });
      const mainFunc = async () =>
        await API.post("org/role/data", {
          params: encryptJsonObject({
            userID: userID,
            idu: user_id,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setChartData(decrypt);
          } else {
            setChartData({
              status: "",
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          console.log(`Failes : ${err}`);
          setChartData({ status: "", err: 1, err_msg: err });
        });
    });

    $("#org-content").off("click", ".btn-expend");
    $("#org-content").on("click", ".btn-expend", function () {
      let elem = $(this);
      //let btn = $(elem).find(".btn-expend").first();
      let btn = elem;
      let dept_id = btn.attr("dept-id");
      let ol_elem = $(".sub-dept-" + dept_id);
      if (btn.hasClass("active")) {
        btn.removeClass("active");
        elem.find(".icon-add").first().removeClass("hide");
        elem.find(".icon-remove").first().addClass("hide");
        ol_elem.slideUp("1000", function () {});
      } else {
        btn.addClass("active");
        elem.find(".icon-add").first().addClass("hide");
        elem.find(".icon-remove").first().removeClass("hide");
        ol_elem.slideDown("1000", function () {});
      }
    });
    $("#org-content")
      .on("mouseenter", ".staff", function () {
        let elem = $(this);
        elem.addClass("hover");
        //console.log("Enter");
      })
      .on("mouseleave", ".staff", function () {
        let elem = $(this);
        elem.removeClass("hover");
        //console.log("Leave");
      });
  }, [chartData]);
  let content = "";
  if ("err" in chartData && chartData.err == 1) {
    content = (
      <div key="text-error-content" className="text-error">
        Fetch request error: {chartData.err_msg}
      </div>
    );
  } else if (chartData.status == "processing") {
    content = (
      <div key="preloading-content">
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "50px",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("create chart", chartData);
    let content_list = [];
    if (chartData.type == "role") {
      if (
        "user_root_id" in chartData.data &&
        chartData.data.user_root_id != ""
      ) {
        let elemIdu = chartData.data.user_root_id;
        let tmpData = {};
        tmpData.user_childs = chartData.data.user_childs;
        tmpData.user_details = chartData.data.user_details;
        tmpData.user_id = chartData.data.user_id;
        tmpData.user_elems = [elemIdu];
        content_list = Feature.TreeRole(tmpData, 0, content_list);
      } else {
        let userRoots = Feature.GetUserRoots(chartData.data);
        //userRoots["user_root"] = [490];
        //console.log("userRoots 1", userRoots);
        content_list = Feature.TreeRole(
          {
            user_childs: chartData.data.user_childs,
            user_details: chartData.data.user_details,
            user_id: "",
            user_elems: userRoots["user_root"],
          },
          1,
          content_list
        );
        if (content_list.length > 0) {
          let tmps = [];
          tmps.push(`<li>`);
          tmps.push(
            `<div class="level-1"><span>` +
              userRoots["dept_root"].name +
              `</span></div>`
          );
          tmps.push(`<ol>`);
          tmps.push(content_list.join(""));
          tmps.push(`</ol>`);
          tmps.push(`</li>`);
          content_list = tmps;
        }
      }
    } else {
      //console.log("Tree", elem, orgData);
      content_list = Feature.Tree(
        chartData.data,
        chartData.type,
        chartData.data.root_id
      );
    }
    let contents = "";
    if (content_list.length > 0) {
      contents = content_list.join("");
    }
    content = `<ol class="organizational-chart">`;
    content += contents;
    content += `</ol>`;
    content = (
      <div
        style={{ display: "flex", justifyContent: "center" }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }
  return (
    <div key="inner-org-chart" id="inner-org-chart">
      <Feature.SelectType
        type={chartData.type}
        handleEvents={handleEvents.SelectType}
      />
      <ZoomChart />
      <div id="tree">{content}</div>
    </div>
  );
}

const Feature = {};
Feature.Tree = (data, type, elem) => {
  let details = data.details;
  let childs = { ...data.childs };
  let user_depts = data.user_depts;
  let user_details = data.user_details;
  let queues = [elem];
  let level = 0;
  let content_list = [];
  //let is_hide_division = (type == "hybrid" && elem == 10) ? true : false;
  let is_hide_division = type == "functional" && elem == 1 ? true : false;
  //console.log("elem=" + elem + ", is_hide_division=" + is_hide_division);
  while (elem != null && elem != "" && queues.length > 0) {
    level++;
    //console.log("elem=" + elem + ", level=" + level, user_depts[elem]);
    content_list.push(`<li class="li-dept">`);
    content_list.push(
      Feature.SmallBoxChart(
        details[elem],
        elem in user_depts ? user_depts[elem] : [],
        user_details,
        type,
        {
          exclude_top_level: false,
          level: level,
          elem_id: elem,
          count_child_dept:
            elem in data["childs"] ? data["childs"][elem].length : 0,
          is_hide_division: is_hide_division,
        }
      )
    );

    if (elem in childs && childs[elem].length > 0) {
      let before_elem = elem;
      let ol_class = "";
      let ol_css = "";
      if (elem in data["childs"] && data["childs"][elem].length > 0) {
        ol_class = "sub-dept-" + elem;
        ol_css =
          is_hide_division &&
          "level_id" in details[elem] &&
          details[elem]["level_id"] >= 1
            ? "display: none;"
            : "";
      }
      queues.unshift(childs[elem][0]);
      elem = queues[0];
      content_list.push(`<ol class='` + ol_class + `' style='` + ol_css + `'>`);
      // Add staff under before department
      if (type == "hybrid" && before_elem in user_depts) {
        //console.log("user_dept", before_elem, user_depts[before_elem]);
        content_list.push(
          Feature.SmallBoxChart(
            details[before_elem],
            user_depts[before_elem],
            user_details,
            "staff",
            {
              exclude_top_level: true,
              level: level,
            }
          )
        );
      }
      // END Add staff under before department
      continue;
    }
    // Add end tag for display staff
    if (type == "hybrid" && elem in user_depts && user_depts[elem]) {
      const tmp_content = Feature.SmallBoxChart(
        details[elem],
        user_depts[elem],
        user_details,
        "staff",
        {
          exclude_top_level: true,
          level: level,
        }
      );
      if (tmp_content != "") {
        let ol_class = "sub-dept-" + elem;
        let ol_css =
          is_hide_division &&
          "level_id" in details[elem] &&
          details[elem]["level_id"] >= 1
            ? "display: none;"
            : "";
        content_list.push(
          `<ol class='` +
            ol_class +
            `' style='` +
            ol_css +
            `'>` +
            tmp_content +
            `</ol>`
        );
      }
    }
    // END Add end tag for display staff
    content_list.push(`</li>`);
    // Find next elem and have child
    let next_elem = null;
    let tmp_queues = [...queues];
    for (let i in tmp_queues) {
      let queue = tmp_queues[i];
      if (queue in childs && childs[queue].length > 0) {
        queues.unshift(childs[queue][0]);
        next_elem = queues[0];
        break;
      }
      if (elem != queue) {
        /*if(type == "hybrid" && queue in user_depts) {
                    content_list.push(Feature.SmallBoxChart(details[queue], user_depts[queue], user_details, "staff", {
                        exclude_top_level: true,
                        level: level
                    }));
                }*/
        content_list.push(`</ol></li>`);
      }
      const remove_queue = queues.shift();
      const parent_queue = details[queue]["parent"];
      if (parent_queue == null) {
        break;
      }
      if (parent_queue in childs && childs[parent_queue].length > 0) {
        const tmp_childs = childs[parent_queue].filter(
          (item) => item !== queue
        );
        childs[parent_queue] = tmp_childs;
      }
      level--;
    }
    elem = next_elem;
  }
  return content_list;
};
Feature.TreeRole = (data, def_level, content_list) => {
  if (data.user_elems.length == 0) {
    return [];
  }
  if (data.user_id == "" && !("ori_user_elems" in data)) {
    data.ori_user_elems = data.user_elems;
  }
  let tmp_user_elems = [...data.user_elems];
  let elem = tmp_user_elems.shift();
  let mainElem = elem;
  let details = data.user_details;
  let childs = { ...data.user_childs };
  let queues = [elem];
  let level = def_level != undefined ? def_level : 0;
  let user_id = data.user_id;
  let loop = 0;
  let maxLoop = 100;
  //console.log("user_elems", elem);
  while (elem != null && queues.length > 0 && loop <= maxLoop) {
    if (!(elem in details) || details[elem] == undefined) {
      break;
    }
    loop++;
    level++;
    //console.log("elem=" + elem, queues);
    content_list.push(`<li>`);
    content_list.push(
      Feature.SmallBoxChart(details[elem], [], [], "role", {
        level: level,
        user_id: user_id,
      })
    );
    // Remove duplicate childs for elem
    if (user_id == "" && elem in childs && childs[elem].length > 0) {
      let tmps = [];
      for (let i in childs[elem]) {
        let tmp_child = childs[elem][i];
        if (details[tmp_child]["level"] > 3) {
          tmps.push(tmp_child);
          continue;
        }
        let pos = data.ori_user_elems.indexOf(tmp_child);
        if (pos < 0) {
          tmps.push(tmp_child);
          continue;
        }
      }
      childs[elem] = tmps;
    }
    // END Remove duplicate childs for elem
    if (elem in childs && childs[elem].length > 0) {
      content_list.push(`<ol>`);
      queues.unshift(childs[elem][0]);
      elem = queues[0];
      continue;
    }
    content_list.push(`</li>`);
    // Find next elem and have child
    let next_elem = null;
    let tmp_queues = [...queues];
    for (let i in tmp_queues) {
      let queue = tmp_queues[i];
      // Remove duplicate childs for queue
      if (user_id == "" && queue in childs && childs[queue].length > 0) {
        let tmps = [];
        for (let i in childs[queue]) {
          let tmp_child = childs[queue][i];
          if (details[tmp_child]["level"] > 3) {
            tmps.push(tmp_child);
            continue;
          }
          let pos = data.ori_user_elems.indexOf(tmp_child);
          if (pos < 0) {
            tmps.push(tmp_child);
            continue;
          }
        }
        childs[queue] = tmps;
      }
      // END Remove duplicate childs for queue
      if (queue in childs && childs[queue].length > 0) {
        queues.unshift(childs[queue][0]);
        next_elem = queues[0];
        break;
      }
      if (elem != queue) {
        content_list.push(`</ol></li>`);
      }
      const remove_queue = queues.shift();
      const parent_queue = details[queue]["approver_id"];
      //console.log(parent_queue, elem);
      if (parent_queue == null) {
        break;
      }
      if (parent_queue in childs && childs[parent_queue].length > 0) {
        const tmp_childs = childs[parent_queue].filter(
          (item) => item !== queue
        );
        childs[parent_queue] = tmp_childs;
      }
      level--;
    }
    elem = next_elem;
  }
  if (tmp_user_elems.length > 0) {
    let tmpData = data;
    tmpData.user_elems = tmp_user_elems;
    content_list = Feature.TreeRole(tmpData, def_level, content_list);
  }
  return content_list;
};
Feature.GetUserRoots = (data) => {
  let root_id = data.root_id;
  let childs = data.childs;
  let details = data.details;
  let user_depts = data.user_depts;
  let user_details = data.user_details;
  let res = { dept_root: {}, user_root: [] };
  if (root_id == "") {
    return res;
  }
  if (root_id == 1) {
    root_id = 2;
  }
  if (!root_id in details) {
    return res;
  }
  res["dept_root"] = details[root_id];
  let root_childs = root_id in childs ? childs[root_id] : [];
  if (root_childs.length == 0) {
    return res;
  }
  const user_roots = [];
  let n = root_childs.length;
  for (let i = 0; i < n; i++) {
    let user_root_id = Feature.GetUserRootId(
      root_childs[i],
      user_depts,
      childs
    );
    if (user_root_id == 0) continue;
    user_roots.push(user_root_id);
  }
  res["user_root"] = user_roots;
  return res;
};
Feature.GetUserRootId = (dept_root_id, user_depts, childs) => {
  let user_root_id = 0;
  let queues = [dept_root_id];
  let tmpChilds = { ...childs };
  let loop = 0;
  let maxLoop = 100;
  while (user_root_id == 0 && queues.length > 0 && loop <= maxLoop) {
    loop++;
    let el = queues[0];
    if (el in user_depts) {
      user_root_id = user_depts[el][0];
      break;
    }
    if (el in tmpChilds && tmpChilds[el].length > 0) {
      let first_elem = parseInt(tmpChilds[el][0]);
      queues.unshift(first_elem);
      const tmp = tmpChilds[el].filter((item) => item !== first_elem);
      tmpChilds[el] = tmp;
    }
  }
  return user_root_id;
};
Feature.BoxStaff = (data, level, addon) => {
  let userPhoto = "";
  if (data.photo != "") {
    //if(data.photo == "xx") {
    let photoUrl = data.photo;
    //photoUrl = 'http://localhost:3000/hrms/images/google_calendar_icon.svg';
    //photoUrl = 'http://localhost:3000/hrms/images/0a8e127a7b5717b3aee6c1f0f508d1b0.jpg';
    //photoUrl = 'http://localhost:3000/hrms/images/0a829bf93822e2d5cfb86e4245cd020a.jpg';
    //photoUrl = '../exoNewServer/sales_report_new/upload/small/0a829bf93822e2d5cfb86e4245cd020a.jpg';
    //userPhoto = `<div class="staff-photo-` + data["idu"] + `" id="staff-photo-` + data["idu"] + `" style="border: 3px solid #edeaea; width: 100px; height: 100px; background-image: url('` + photoUrl + `'); background-repeat: no-repeat; background-size: 100% 100%; background-color:transparent;" data-src="` + photoUrl + `"></div>`;
    userPhoto =
      `<div class="image-circle image-loader staff-photo-` +
      data["idu"] +
      `" id="staff-photo-` +
      data["idu"] +
      `" style="border: 3px solid #edeaea;" data-src="` +
      photoUrl +
      `"><i class="material-icons">person</i></div>`;
    //userPhoto = `<div class="image-circle staff-photo-` + data["idu"] + `" id="staff-photo-` + data["idu"] + `" style="border: 3px solid #edeaea;"><img src='` + photoUrl + `' width='56px'/></div>`;
  } else {
    userPhoto = `<div class="image-circle" style="border: 3px solid #edeaea;"><i class="material-icons">person</i></div>`;
  }
  if (addon == undefined) {
    addon = {};
  }
  let content = "";
  content =
    `<div key="` +
    data["idu"] +
    `" id="staff-` +
    data["idu"] +
    `" class="staff staff-` +
    data["idu"] +
    ` level-` +
    level +
    ("class" in addon ? " " + addon.class : "") +
    `" style="` +
    ("css" in addon ? addon.css : "") +
    `">`;
  content += `<div class="photo">`;
  content += userPhoto;
  content += `</div>`;
  content += `<div class="detail">`;
  content += `<span class="position truncate">` + data["position"] + `</span>`;
  content +=
    `<span class="full-name truncate">` + data["full_name"] + `</span>`;
  //content += `<span class="id" style="font-size: 9px;margin-top: -6px;display: block;">( ` + data["idu"] + ` )</span>`;
  content += `</div>`;
  content += `</div>`;
  return content;
};
Feature.SmallBoxChart = (data, users, user_details, type, addon) => {
  let exclude_top_level = false;
  let level = 0;
  let elem_id = "";
  let count_child_dept = 0;
  let is_hide_division = false;
  let user_id = "";
  if (addon != undefined && !Func.IsEmptyObject(addon)) {
    if ("exclude_top_level" in addon) {
      exclude_top_level = addon["exclude_top_level"];
    }
    if ("level" in addon) {
      level = addon["level"];
    }
    if ("elem_id" in addon) {
      elem_id = addon["elem_id"];
    }
    if ("count_child_dept" in addon) {
      count_child_dept = addon["count_child_dept"];
    }
    if ("is_hide_division" in addon) {
      is_hide_division = addon["is_hide_division"];
    }
    if ("user_id" in addon) {
      user_id = addon["user_id"];
    }
  }
  //console.log('is_hide_division=' + is_hide_division);
  let boxContent = [];
  let top_level = "";
  let div_class = "";
  let btn_expend = "";
  let btn_expend_class = "";
  let icon_add_class = "";
  let icon_remove_class = " hide";
  let div_badge = "";
  if (
    !is_hide_division ||
    "is_org" in data ||
    ("level_id" in data && data.level_id < 1)
  ) {
    btn_expend_class = " active";
    icon_add_class = " hide";
    icon_remove_class = "";
  }
  if (count_child_dept > 0 || (users.length > 0 && type != "functional")) {
    div_class = " level div-dept";
    btn_expend =
      '<a class="btn-expend btn-floating btn-small' +
      btn_expend_class +
      '" dept-id=' +
      elem_id +
      ">";
    btn_expend +=
      '<i class="material-icons icon-add' + icon_add_class + '">add</i>';
    btn_expend +=
      '<i class="material-icons icon-remove' +
      icon_remove_class +
      '">remove</i>';
    btn_expend += "</a>";
    div_badge =
      '<span class="new badge" data-badge-caption="" style="display: none;"></span>';
  }
  if (type == "functional") {
    boxContent.push(
      `<div class="level-` +
        level +
        div_class +
        `"><span>` +
        data.name +
        btn_expend +
        `</span></div>`
    );
  } else if (type == "role") {
    //console.log(data);
    if ("is_top_level" in data && data["is_top_level"] == 1) {
      boxContent.push(
        `<div class="box-include-top-level role-chart top-level-` + level + `">`
      );
      boxContent.push(
        Feature.BoxStaff(data, level, {
          class: user_id != "" && data.idu == user_id ? " active" : "",
        })
      );
      boxContent.push(`</div>`);
    } else {
      boxContent.push(
        Feature.BoxStaff(data, level, {
          class: user_id != "" && data.idu == user_id ? " active" : "",
        })
      );
    }
  } else if (type == "staff") {
    if (users.length == 0) {
      return "";
    }
    if (user_details[users[0]]["is_top_level"] == 1 && users.length > 1) {
      top_level = users[0];
    }
    let boxContents = [];
    for (let i in users) {
      let user_detail = user_details[users[i]];
      //if(i == 0 && user_detail["is_top_level"] == 1) {
      if (user_detail["is_top_level"] == 1 && user_detail["level"] <= 4) {
        continue;
      }
      //console.log('name=' + data.name + ', level=' + level + ', top_level=' + top_level + ', exclude_top_level=' + exclude_top_level);
      let tmp = `<li>`;
      tmp += Feature.BoxStaff(user_detail, level + 1);
      //tmp += `<div key="` + users[i]["idu"] + `" class="staff level-` + (level+1) + `">` + user_detail["full_name"] + `</div>`;
      tmp += `</li>`;
      boxContent.push(tmp);
    }
    if (boxContent.length == 0) {
      return "";
    }
    boxContent.push(boxContents.join(""));
  } else {
    if (
      !exclude_top_level &&
      users.length > 0 &&
      user_details[users[0]]["is_top_level"] == 1
    ) {
      boxContent.push(
        `<div class="box-include-top-level top-level-` + level + `">`
      );
      boxContent.push(
        `<div class="level-` +
          level +
          div_class +
          `"><span>` +
          data.name +
          btn_expend +
          div_badge +
          `</span></div>`
      );
      let tmps = [];
      for (let i in users) {
        let user_detail = user_details[users[i]];
        if (user_detail["is_top_level"] == 0 || user_detail["level"] > 4)
          continue;
        let class_name = "staff-top-level";
        class_name += elem_id != "" ? " sub-dept-" + elem_id : "";
        tmps.push(
          Feature.BoxStaff(user_detail, level, {
            class: class_name,
            css: is_hide_division ? "display: none;" : "",
          })
        );
      }
      if (tmps.length > 0) {
        boxContent.push(`<div class="outer-staff-top-level">`);
        boxContent = boxContent.concat(tmps);
        boxContent.push(`</div>`);
      }
      boxContent.push(`</div>`);
    } else {
      boxContent.push(
        `<div class="level-` +
          level +
          div_class +
          `"><span>` +
          data.name +
          btn_expend +
          div_badge +
          `</span></div>`
      );
    }
  }

  return boxContent.join("");
};
Feature.SelectType = ({ type, handleEvents }) => {
  return (
    <div key="select-type-chart" id="select-type-chart">
      <span id="title">Organization Chart</span>
      <div id="radiogroup">
        <RadioGroup
          label=""
          name="type-chart"
          onChange={handleEvents.OnChange}
          options={[
            { label: "Hybrid", value: "hybrid" },
            { label: "Functional", value: "functional" },
            { label: "Role", value: "role" },
          ]}
          value={type}
          withGap
        />
      </div>
    </div>
  );
};
