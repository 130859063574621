import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Func from "../../utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Handle from "../Container/modal.handle";
import ModalDone from "../Container/modal.done";
import ModalError from "../Container/modal.error";
import ModalWarning from "../Container/modal.warning";

export default function BtnExportSubmit({ events, header }) {
  const [resData, setResData] = useState({
    spreadsheetUrl: "",
    status: "",
    err: 0,
    err_msg: "",
  });
  const callbackEvent = {
    OnClose: function () {
      setResData({ spreadsheetUrl: "", status: "", err: 0, err_msg: "" });
    },
    OnComplete: function () {
      events.OnComplete();
    },
  };
  const handleEvents = {
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setResData({ spreadsheetUrl: "", status: "", err: 0, err_msg: "" });
    },
    OnSubmit: function () {
      let exportType = "all";
      if ($('input[name="export-column-displayed"]').is(":checked")) {
        exportType = "displayed";
      }
      let filters = Func.GetDataForm(
        $("#form-filter-request").serializeArray()
      );
      //console.log(filters);return;
      Handle.loading.OnShow();
      const mainFunc = async () =>
        await API.post("org/user/export", {
          params: encryptJsonObject({
            userID: window.$user_profile.idu,
            userEmail: window.$user_profile.emailadd,
            exportType: exportType,
            filters: filters,
          }),
        });
      mainFunc()
        .then((res) => {
          //console.log(res.data);//return;
          Handle.loading.OnHide();
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setResData(decrypt);
          } else {
            setResData({
              spreadsheetUrl: "",
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          Handle.loading.OnHide();
          setResData({ spreadsheetUrl: "", err: 1, err_msg: "" });
        });
    },
  };
  //console.log("Edit Submit", resData);
  if ("err" in resData && resData.err === 1) {
    return (
      <ModalWarning
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if ("err" in resData && resData.err === 2) {
    return (
      <ModalError
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if (resData.status === "success") {
    let content =
      "<span style=''>Your report has been exported.</span><a href='" +
      resData.spreadsheetUrl +
      "' target='_blank' style='position: unset; display: inline-block;'>Open the sheet.</a>";
    let addon = {
      setTime: "no",
      height: "240px",
    };
    return (
      <ModalDone
        header={header}
        content={content}
        callbackEvent={callbackEvent}
        addon={addon}
      />
    );
  }
  return (
    <Button
      className=""
      id="btn-submit-export"
      node="button"
      onClick={handleEvents.OnSubmit}
    >
      Confirm
    </Button>
  );
}
