import React, { useState, useEffect } from "react";
import {
  Select,
  TextInput,
  Icon,
  Button,
  Checkbox,
  Table,
  Modal,
} from "react-materialize";
import axios from "axios";
import config from "../../config";
import API from "../../utils/API";
import Func from "../../utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Html from "./html";
import $ from "jquery";
import DeleteItem from "./deleteItem";

export default function CreateCalendar({ data, setFilterData }) {
  let work_loca = window.$user_profile.work_loca;
  let employed_by = window.$user_profile.employed_by;
  let is_exo_group = window.$user_profile.is_exo_group;
  const filters = {
    country_name: is_exo_group == "yes" ? work_loca : employed_by,
  };
  const [createCalendarData, setCreateCalendarData] = useState({
    status: "",
    holidayData: {},
  });

  useEffect(() => {
    $("#container").on("click", ".btn-open-modal", function () {
      const dataType = $(this).attr("data-type");
      if (dataType == "create-calendar") {
        setCreateCalendarData({
          status: "open",
          holidayData: {},
        });
      }
    });
  }, []);

  const handleEvents = {
    onClose: function () {
      setCreateCalendarData({
        status: "",
        holidayData: {},
      });
    },
    onSubmit: function (e) {
      e.preventDefault();
      modalEvents.loading.onShow();
      let formData = Func.GetDataForm(
        $("#form-create-calendar").serializeArray()
      );
      const country_id = formData.country_id[0];
      const year = formData.year[0];
      CheckCalendarExist({
        country_id: country_id,
        year: year,
        publicHolidayData: formData,
      });
    },
    onSaveConfirm: function (e) {
      e.preventDefault();
      modalEvents.loading.onShow();
      const form = $("#calendar-form-edit-content");
      const formData = Func.GetDataForm(form.serializeArray(), "save_confirm");
      const country_id = $("input[name=calendar_country_id]").val();
      const year = $("input[name=calendar_year]").val();
      const res = {
        data: formData,
        country_id: country_id,
        country_name: data.countries[country_id].country_name,
        year: year,
      };
      SubmitCreateCalendar({
        country_id: country_id,
        country_name: data.countries[country_id].country_name,
        year: year,
        publicHolidayData: formData,
      });
    },
  };

  const SubmitCreateCalendar = function (formData) {
    //console.log(formData);
    const mainFunc = async () =>
      await API.post("holiday/create/calendar", {
        params: encryptJsonObject({
          userID: window.$user_profile.idu,
          forms: formData,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        console.log("response=", decrypt);
        if (decrypt?.status === "success" && decrypt?.inserted) {
          modalEvents.loading.onHide();
          modalEvents.error.onHide();
          setCreateCalendarData({
            status: "success",
            holidayData: {},
          });
          // Refresh
          let filters = Func.GetDataForm(
            $("#form-filter-search").serializeArray()
          );
          filters["country_id"] = [formData.country_id];
          filters["year"] = [formData.year];
          filters["page"] = [1];
          filters["limit"] = [$("#select-limit-row").val()];
          filters["is_edit_mode"] = $("#btn-edit-mode").attr("data-mode");
          filters["delete_items"] = [];
          filters["is_refresh"] = true;
          filters["key_form"] = Math.random();
          setFilterData(filters);
        } else {
          modalEvents.loading.onHide();
          modalEvents.error.onShow(`Failes : ` + decrypt?.err_msg ?? "");
        }
      })
      .catch((err) => {
        modalEvents.loading.onHide();
        modalEvents.error.onShow(`Failed : ${err}`);
      });
  };

  const CheckCalendarExist = function (resData) {
    const mainFunc = async () =>
      await API.post("holiday/check/calendar/exist", {
        params: encryptJsonObject({
          country_id: resData.country_id,
          year: resData.year,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success" && !decrypt?.is_exist) {
          GetPublicHolidayEvents(
            data.countries[decrypt.country_id],
            decrypt.year
          );
        } else {
          modalEvents.loading.onHide();
          modalEvents.error.onShow(`Failes : ` + decrypt?.err_msg ?? "");
        }
      })
      .catch((err) => {
        modalEvents.loading.onHide();
        modalEvents.error.onShow(`Failed : ${err}`);
      });
  };

  const GetPublicHolidayEvents = async (countryData, year) => {
    const country_id = countryData.id;
    const country_calendar = countryData.country_calendar;
    const country_name = countryData.country_name;
    const country_code = countryData.country_code;
    const apiKey = config.googleApiKey;
    const result = await axios(
      "https://www.googleapis.com/calendar/v3/calendars/en." +
        country_calendar +
        "%23holiday%40group.v.calendar.google.com/events?key=" +
        apiKey
    );
    let publicHolidayData = [];
    if ("items" in result.data) {
      const items = result.data.items;
      let count = 0;
      for (let i in items) {
        let holidayDate = items[i].start.date;
        let holidayYear = new Date(holidayDate).getFullYear();
        if (holidayYear != year) {
          continue;
        }
        //if(count > 0) continue;
        publicHolidayData.push({
          id: "draft-" + i,
          holiday_name: items[i].summary,
          calendar_date: holidayDate,
          country_name: country_name,
          country_code: country_code,
          holiday_year: holidayYear,
          status: items[i].status,
        });
        count++;
      }
    }
    if (publicHolidayData.length > 0) {
      publicHolidayData = publicHolidayData.sort((a, b) =>
        a.calendar_date > b.calendar_date ? 1 : -1
      );
      setCreateCalendarData({
        status: "processing",
        holidayData: {
          data: publicHolidayData,
          detail_country_codes: [country_code],
          country_id: country_id,
          year: year,
        },
      });
      modalEvents.loading.onHide();
      modalEvents.error.onHide();
    } else {
      modalEvents.loading.onHide();
      modalEvents.error.onShow(
        `Failed : There are not public holiday for this country`
      );
    }
  };

  let header = "Create New Calendar";
  let content = "";
  let btns = [];
  let modalCSS = {};
  let modalID = "modal-create-calendar";
  //console.log("Create Calendar, status=" + createCalendarData.status);
  if (createCalendarData.status === "") {
    return "";
  } else if (createCalendarData.status === "success") {
    modalCSS.height = "225px";
    btns = [
      <Button flat modal="close" node="button" waves="red">
        Close
      </Button>,
    ];
    content = <div className="modal-done-text">Done</div>;
  } else if (
    createCalendarData.status === "processing" &&
    "data" in createCalendarData.holidayData
  ) {
    modalID = "modal-edit-calendar";
    modalCSS.height = "80%";
    btns = [
      <span className="modal-error-text hide"></span>,
      <Button
        className="blue darken-3"
        id="btn-submit-create-calendar"
        node="button"
        onClick={handleEvents.onSaveConfirm}
      >
        Save & Confirm
      </Button>,
      <Button flat modal="close" node="button" waves="red">
        Close
      </Button>,
    ];
    content = [];
    content.push(
      <div key="modal-content-inner" className="modal-content-inner">
        <CreateFormEdit
          itemData={createCalendarData.holidayData}
          data={data}
          events={handleEvents}
        />
      </div>
    );
    content.push(
      <div key="modal-loading" className="modal-loading hide">
        {Html.Preloading({
          css: {
            position: "absolute",
            top: "50px",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
          },
        })}
      </div>
    );
    //content.push(<div key="modal-delete-item"><ModalDeleteItem /></div>);
  } else {
    //console.log(data);
    modalCSS.height = "320px";
    btns = [
      <span className="modal-error-text hide"></span>,
      <Button
        className="blue darken-3"
        id="btn-submit-create-calendar"
        node="button"
        waves="green"
        onClick={handleEvents.onSubmit}
      >
        Create
      </Button>,
      <Button flat modal="close" node="button" waves="red">
        Close
      </Button>,
    ];
    content = [];
    content.push(
      <div key="modal-content-inner" className="modal-content-inner">
        <CreateForm data={data} filters={filters} />
      </div>
    );
    content.push(
      <div key="modal-loading" className="modal-loading hide">
        {Html.Preloading({
          css: {
            position: "absolute",
            top: "50px",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
          },
        })}
      </div>
    );
  }
  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={true}
      header={header}
      id={modalID}
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.onClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
    </Modal>
  );
}

const CreateFormEdit = ({ itemData, data, events }) => {
  const [deleteItems, setDeleteItems] = useState({ key: 0, items: [] });

  const handleEvents = {
    DeleteAllItemChanged: function (evt) {
      let checked = false;
      if ($(evt.target).is(":checked")) {
        checked = true;
      }
      let form = $(evt.target).parents("form").first();
      let items = $(form).find("input[name=delete_items]").val();
      if (items == "") {
        items = [];
      } else {
        items = JSON.parse(items);
      }
      $(form)
        .find("input[name='delete-item']")
        .each(function () {
          let value = $(this).val();
          const pos = items.indexOf(value);
          if (checked) {
            if (pos == -1) {
              items.push(value);
            }
          } else {
            if (pos > -1) {
              items.splice(pos, 1);
            }
          }
        });
      setDeleteItems({
        key: Math.floor(Math.random() * 10000 + 1),
        items: items,
      });
    },
  };

  //console.log(data);
  const isEditMode = true;
  let detailData = [];
  if ("detail_country_codes" in itemData && "details" in data) {
    for (const i in itemData.detail_country_codes) {
      const detail_country_code = itemData.detail_country_codes[i];
      if (detail_country_code in data.details) {
        for (const j in data.details[detail_country_code]) {
          detailData.push(data.details[detail_country_code][j]);
        }
      }
    }
  }
  itemData.details = detailData;
  /*let deleteData = {
        key: deleteItems.key, 
        items: deleteItems.items
    };*/
  //console.log(deleteItems);
  return (
    <form
      id="calendar-form-edit-content"
      method="post"
      action=""
      onSubmit={events.onSaveConfirm}
    >
      <Table className="highlight">
        <thead key="thead">
          {Html.TrHead({ details: detailData }, isEditMode, true, handleEvents)}
        </thead>
        <tbody key="tbody">
          {Html.TrContent(itemData, isEditMode, deleteItems)}
        </tbody>
      </Table>
      <input
        type="hidden"
        name="calendar_country_id"
        value={itemData.country_id}
      />
      <input type="hidden" name="calendar_year" value={itemData.year} />
      <input
        type="hidden"
        name="delete_items"
        value={JSON.stringify(deleteItems.items)}
      />
      <DeleteItem
        form_id="calendar-form-edit-content"
        setDeleteItems={setDeleteItems}
      />
    </form>
  );
};

const CreateForm = ({ data, filters }) => {
  if (!"countries" in data) {
    return;
  }
  let user_sess = window.$user_profile["user_sess"];
  //user_sess["userCanAccessAllCountries"] = false;
  let work_loca = window.$user_profile.work_loca;
  let employed_by = window.$user_profile.employed_by;
  let is_exo_group = window.$user_profile.is_exo_group;
  let filter_country = data.countries;
  // console.log("data", data);
  if (
    (!("userCanAccessAllCountries" in user_sess) ||
      !user_sess["userCanAccessAllCountries"]) &&
    is_exo_group != "yes"
  ) {
    filter_country = Func.SearchObject(
      data.countries,
      [employed_by],
      "country_name"
    );
  }
  let filter_country_id = "";
  if ("country_id" in filters) {
    filter_country_id = filters.country_id;
  } else if ("country_name" in filters) {
    let filter_country_data = Func.SearchObject(
      filter_country,
      [filters.country_name],
      "country_name"
    );
    filter_country_id =
      Object.keys(filter_country_data).length > 0
        ? Object.keys(filter_country_data)[0]
        : "";
  }
  let form = [];
  form.push(
    <div key="create-filter-country" className="row">
      <strong className="col s4">Country</strong>
      <div className="col s8">
        <Select
          multiple={false}
          id="create-filter-inp-country"
          name="country_id"
          options={{
            classes: "",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value={filter_country_id}
        >
          {Html.Options(filter_country, {
            key: "country",
            name: "country_name",
            value: "id",
          })}
        </Select>
      </div>
    </div>
  );
  const currentYear = Func.GetCurrentYear();
  form.push(
    <div key="create-filter-year" className="row">
      <strong className="col s4">year</strong>
      <div className="col s8">
        <Select
          multiple={false}
          id="create-filter-inp-year"
          name="year"
          options={{
            classes: "",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value={`currentYear`}
        >
          {Html.Options(Func.GetRangeYears(currentYear, currentYear + 1), {
            key: "year",
          })}
        </Select>
      </div>
    </div>
  );
  return (
    <form key="form-create-calendar" id="form-create-calendar" action="">
      {form}
    </form>
  );
};

const modalEvents = {
  loading: {
    onHide: function () {
      $(".modal-loading").addClass("hide");
      $(".modal-content-inner").removeClass("hide");
      $(".modal-footer").removeClass("hide");
    },
    onShow: function () {
      $(".modal-loading").removeClass("hide");
      $(".modal-content-inner").addClass("hide");
      $(".modal-footer").addClass("hide");
    },
  },
  error: {
    onHide: function () {
      $(".modal-error-text").text("").addClass("hide");
    },
    onShow: function (err) {
      $(".modal-error-text").text(err).removeClass("hide");
    },
  },
};
