import React from "react";
import BtnView from "../Container/main.content.btn.view";
import BtnEdit from "../Container/main.content.btn.edit";

export default function TrContent({data, users, photos}) {
    const colspan = 9;
    let list = [];
    for(const i in data) {
        const requested_by = data[i].requested_by;
        const user = (requested_by in users) ? users[requested_by] : "";
        data[i].full_name = "";
        data[i].photo = "";
        if(user != "") {
            data[i].full_name = user["full_name"];
            data[i].photo = (user["photo"] in photos) ? photos[user["photo"]]["image_url"] : "";
        }
        //console.log(data[i]);
        list.push(<tr key={"row-" + i}>
            <Feature.TdContent 
                data={data[i]} index={i} />
        </tr>);
    }
    if(data.length == 0) {
        list.push(<tr key="row-1">
            <td colSpan={colspan}>No Data</td>
        </tr>);
    }
    return list
}

const Feature = {};
Feature.TdContent = ({data, index}) => {
    let td = [];
    let btnEdit = "";
    if(data.isCanEdit){
        btnEdit = <BtnEdit id={data.id} />;
    }
    let userPhoto = "";
    if(data.photo != "") {
        userPhoto = <div className="image-circle" style={{
            background: "url('" + data.photo + "') no-repeat center", 
            backgroundSize: "cover"}} />
    }
    else {
        userPhoto = <div className="image-circle">
            <i className="material-icons">person</i>
        </div>
    }
    td.push(<td key={"row-" + index + "-col-photo"} className="td-photo">
        {userPhoto}
    </td>);
    td.push(<td key={"row-" + index + "-col-name"} className="text-left">
        <span className="text">{data.full_name}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-startdate"}>
        <span className="text">{data.full_start_date}</span>
        {(data.full_start_time != "") ? <span className="small-text">{data.full_start_time}</span> : ""}
    </td>);
    td.push(<td key={"row-" + index + "-col-enddate"}>
        <span className="text">{data.full_end_date}</span>
        {(data.full_end_time != "") ? <span className="small-text">{data.full_end_time}</span> : ""}
    </td>);
    td.push(<td key={"row-" + index + "-col-total"}>
        <span className="text total">{data.full_total_days}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-leavetype"}>
        <span className="text">{data.leavetype_name}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-status"}>
        <div className={"status-text " + data.status_code}>
            {data.status_desc}
        </div>
    </td>);
    td.push(<td key={"row-" + index + "-col-view"}>
        <BtnView id={data.id} />
    </td>);
    td.push(<td key={"row-" + index + "-col-edit"}>
        {btnEdit}
    </td>);
    return td;
}