import React from "react";
import { Button, Icon } from 'react-materialize';

export default function BtnCalendar({}) {
    const handleEvents = {
        OnClick : function(evt) {
            //evt.preventDefault();
        }
    }
    return <Button
        flat
        node="a"
        waves="light"
        className="btn-view-calendar textcolor-green"
        href="https://calendar.google.com/calendar/" 
        target="_blank"
        style={{fontWeight: "normal"}}
    >
        View in Google Calendar
        <i className="material-icons right">
            <img 
                title="google-calendar" 
                src={process.env.PUBLIC_URL + '/images/google_calendar_icon.svg'} 
                alt="google-calendar"
                height="22px" />
        </i>
    </Button>
}
