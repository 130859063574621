import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select, Checkbox } from 'react-materialize';
import Html from "../../utils/Html";
import Func from "../../utils/Func";

export default function FilterDepartment({data, filters}) {
    const filterName = "department";
    const filterTitle = "Department";
    const [innerData, setInnerData] = useState({
        data: filters.data, 
        key_form: filters.key_form, 
        checked: false
    });
    let handleEvents = {
        BtnSelectAll : {
            OnClick : function(evt){
                let selectedAll = false;
                if($(evt.target).is(":checked")) {
                    selectedAll = true;
                }
                let selectedArr = [];
                if(selectedAll){
                    $("#filter-inp-" + filterName + " option").each(function () {
                        if($(this).attr("disabled") == undefined) {
                            selectedArr.push($(this).val());
                        }
                    });
                }
                //("Btn Select All => " + filterName, selectedArr);
                setInnerData({
                    data: selectedArr, 
                    key_form: Func.GetKeyRandom(),
                    checked: selectedAll
                });
            }
        },
        OnChange : function(evt){
            
        }
    };
    useEffect(() => {
        
    }, []);
    let itemData = [];
    let country = [];
    let n = 0;
    if('country' in filters && filters.country.length > 0) {
        country = filters.country;
        n = country.length;
    }
    for(let i in country) {
        let country_code = country[i];
        if(country_code in data) {
            itemData = itemData.concat(data[country_code]);
            /*let names = Func.GetArrayColumn(data[country_code], "dept_name");
            if(i == 0) {
                tmp = names;
                itemData = data[country_code];
            }
            else {
                let diffs = names.filter(x => !tmp.includes(x));
                console.log("Diff", tmp, diffs);
            }*/
        }
    }
    // OLD CODE
    /*for(let name in data) {
        if(n > 0) {
            let values = data[name];
            let searchObj = Func.SearchObject(values, country, "country_code");
            if(Object.keys(searchObj).length > 0) {
                itemData.push({name: Func.HtmlDecode(name)});
            }
        }
        else {
            itemData.push({name: Func.HtmlDecode(name)});
        }
    }*/
    return <div key={"filter-" + filterName + "-" + innerData.key_form} className="filter-input-field select">
        <div className="btn-select-all">
            <Checkbox
                checked={innerData.checked}
                filledIn
                className=""
                id={"btn-select-all-" + filterName}
                label="All"
                value="yes"
                onClick={handleEvents.BtnSelectAll.OnClick}
            />
        </div>
        <Select 
            multiple={true} 
            id={"filter-inp-" + filterName} 
            name={filterName} 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={innerData.data}
            onChange={handleEvents.OnChange}
        >
            <Html.Options data={itemData} addon={{
                key: filterName, 
                name: (n > 1) ? "display_name" : "dept_name", 
                value: "dept_id",
                title: filterTitle
            }} />
        </Select>
        <span className="select-helper-text hide" id={"helper-text-" + filterName}>{"Please select " + filterName}</span>
    </div>
}