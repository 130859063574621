// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const COOKIE_NAME = "google_login_token";
  const [googleProfile, setGoogleProfile] = useState({ email: "", err: 0, err_msg: "" });

  // Function to set the login token in a cookie
  const setLoginCookie = (email) => {
    const tokenData = { email, timestamp: Date.now() }; // Add additional data if needed
        
    // Calculate the expiration date (3 hours from now)
    const expires = new Date(new Date().getTime() + 3 * 60 * 60 * 1000);

    // Set the cookie with the expiration time
    Cookies.set(COOKIE_NAME, JSON.stringify(tokenData), { expires: expires })
  };

  // Function to check the login token from the cookie
  const getLoginCookie = () => {
    const cookie = Cookies.get(COOKIE_NAME);
    if (cookie) {
      try {
        const parsed = JSON.parse(cookie);
        return parsed.email ? parsed : null;
      } catch (error) {
        console.error("Failed to parse login cookie:", error);
        return null;
      }
    }
    return null;
  };

  // Function to handle Google login success and set cookie
  const handleLoginSuccess = (googleUser) => {
    const profile = googleUser.getBasicProfile();
    const userData = {
      fullName: profile.getName(),
      email: profile.getEmail(),
    };
    // userData.email = "";
    // Set the cookie with the user's email after login
    setLoginCookie(userData.email);

    // Update the state to reflect the logged-in user
    setGoogleProfile({ email: userData.email, err: 0, err_msg: "" });

    console.log("Login successful:", userData);
  };

  const login = () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signIn().then((googleUser) => {
      handleLoginSuccess(googleUser); // Handle successful login
    }).catch((error) => {
      console.log("Error signing in", error);
      setGoogleProfile({ email: "", err: 1, err_msg: error });
    });
  };

  const checkLoginStatus = () => {
    const cookieData = getLoginCookie();

    if (cookieData) {
      console.log("User is already logged in from cookie");
      setGoogleProfile({ email: cookieData.email, err: 0, err_msg: "" });
    } else {
      // Initialize Google API
      window.gapi.load("auth2", () => {
        window.gapi.auth2.init({ 
          client_id: process.env.REACT_APP_CLIENTID, // Replace with your Client ID
          ux_mode: "redirect", // Use redirect mode
          redirect_uri: process.env.REACT_APP_BASEURL, // Your redirect URI
          scope: "email profile", // Request profile and email
          debug: true, // Enables detailed logs
        }).then(() => {
          const auth2 = window.gapi.auth2.getAuthInstance();
          // Check if the user is signed in with Google
          if (auth2.isSignedIn.get()) {
            const googleUser = auth2.currentUser.get();
            handleLoginSuccess(googleUser); // Auto sign in if already logged in with Google
          } else {
            // Trigger Google sign-in if not signed in
            login();
          }
        }).catch((error) => {
          console.error("Error initializing Google Auth:", error);
          setGoogleProfile({ email: "", err: 1, err_msg: error });
        });
      });
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ googleProfile, setGoogleProfile, checkLoginStatus }}>
      {children}
    </AuthContext.Provider>
  );
};