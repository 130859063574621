import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select, Button, Checkbox } from 'react-materialize';
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";

export default function FilterOrganization({data, filters}) {
    const [orgFilters, setOrgFilters] = useState({data: filters.data, key_form: filters.key_form, checked: false});
    let handleEvents = {
        BtnSelectAll : {
            OnClick : function(evt){
                let selectedAll = false;
                if($(evt.target).is(":checked")) {
                    selectedAll = true;
                }
                let selectedArr = [];
                if(selectedAll){
                    $("#filter-inp-organization option").each(function () {
                        if($(this).attr("disabled") == undefined) {
                            selectedArr.push($(this).val());
                        }
                    });
                }
                //console.log("Btn Select All => Organization");
                setOrgFilters({
                    data: selectedArr, 
                    key_form: Math.random(),
                    checked: selectedAll
                });
            }
        },
        OnChange : function(evt){
            
        }
    };
    useEffect(() => {
        
    }, []);
    //let tmp = [{id: 0, name: "All Organization"}];
    //data = tmp.concat(data);
    data.sort(function (x, y) {
        //let a = x.name.toUpperCase(),
        //    b = y.name.toUpperCase();
        let a = x.id,
            b = y.id;
        return a == b ? 0 : a > b ? 1 : -1;
    });
    //console.log("Filter Organization");
    return <div key={"filter-organization" + orgFilters.key_form} className="filter-input-field select" id="filter-organization">
        <div className="btn-select-all">
            <Checkbox
                checked={orgFilters.checked}
                filledIn
                className=""
                id="btn-select-all-organization"
                label="All"
                value="yes"
                onClick={handleEvents.BtnSelectAll.OnClick}
            />
        </div>
        <Select 
            multiple={true} 
            key={"filter-inp-organization" + orgFilters.key_form}
            id="filter-inp-organization" 
            name="organization" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: false,
                    constrainWidth: true,
                    coverTrigger: false,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={orgFilters.data}
            onChange={handleEvents.OnChange}
        >
            <Html.Options data={data} addon={{
                key: "organization", 
                name: "name", 
                value: "id",
                title: "Organization"
            }} />
            
        </Select>
        <span className="select-helper-text hide" id="helper-text-organization">Please select organization</span>
    </div>
}