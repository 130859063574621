import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select, Checkbox } from 'react-materialize';
import Html from "../../utils/Html";
import Func from "../../utils/Func";

export default function FilterCountry({data, filters, setFilters}) {
    const filterName = "country";
    const filterTitle = "Country";
    const [innerData, setInnerData] = useState({
        data: filters.data, 
        key_form: filters.key_form, 
        checked: false
    });
    let handleEvents = {
        BtnSelectAll : {
            OnClick : function(evt){
                let selectedAll = false;
                if($(evt.target).is(":checked")) {
                    selectedAll = true;
                }
                let selectedArr = [];
                if(selectedAll){
                    $("#filter-inp-" + filterName + " option").each(function () {
                        if($(this).attr("disabled") == undefined) {
                            selectedArr.push($(this).val());
                        }
                    });
                }
                //console.log("Btn Select All => " + filterName);
                setInnerData({
                    data: selectedArr, 
                    key_form: Func.GetKeyRandom(),
                    checked: selectedAll
                });
            }
        },
        OnChange : function(evt){
            const values = $(evt.target).val();
            setFilters({
                country: values
            });
        }
    };
    useEffect(() => {
        
    }, []);
    return <div key={"filter-" + filterName + "-" + innerData.key_form} className="filter-input-field select">
        <div className="btn-select-all">
            <Checkbox
                checked={innerData.checked}
                filledIn
                className=""
                id={"btn-select-all-" + filterName}
                label="All"
                value="yes"
                onClick={handleEvents.BtnSelectAll.OnClick}
            />
        </div>
        <Select 
            multiple={true} 
            id={"filter-inp-" + filterName} 
            name={filterName} 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={innerData.data}
            onChange={handleEvents.OnChange}
        >
            <Html.Options data={data} addon={{
                key: filterName, 
                name: "country_name", 
                value: "country_code",
                title: filterTitle
            }} />
        </Select>
        <span className="select-helper-text hide" id={"helper-text-" + filterName}>{"Please select " + filterName}</span>
    </div>
}