import React, { useState, useEffect } from "react";
import { Button, Modal, Textarea } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import BtnEditSubmit from "./modal.edit.btn.submit";

export default function ModalEdit({ setFilters, setReqData }) {
  const defaultItemData = {
    data: {},
    type: "",
    status: "",
    err: 0,
    err_msg: "",
    isChangeRQ: 0,
  };
  const [itemData, setItemData] = useState(defaultItemData);
  let header = itemData.type == "delete" ? "Cancel leave" : "Change leave";
  if (itemData.isChangeRQ === "1") {
    header = "Cancel Change Request";
  }
  let content = "";
  let footer = "";
  let btns = [<div className="header">{header}</div>];
  let modalCSS = {};
  modalCSS.height = "70%";
  const handleEvents = {
    BtnEdit: {
      OnComplete: function () {
        setItemData(defaultItemData);
        setReqData({ status: "", err: 0, err_msg: "" });
        setFilters({
          limit: $("#select-limit-row").val(),
          page: 1,
        });
      },
    },
    BtnDelete: {
      OnComplete: function () {
        setItemData(defaultItemData);
        setReqData({ status: "", err: 0, err_msg: "" });
        setFilters({
          limit: $("#select-limit-row").val(),
          page: 1,
        });
      },
    },
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setItemData(defaultItemData);
    },
    OnSubmit: function (evt) {
      // Form Submit
      evt.preventDefault();
    },
  };
  useEffect(() => {
    $("#main-content").on("click", ".btn-edit, .btn-delete", function (evt) {
      const type = $(this).hasClass("btn-delete") ? "delete" : "";
      setItemData({ type: type, status: "processing" });
      const reqId = $(this).attr("req-id");
      const isChangeRQ = $(this).attr("is-change-rq");
      const mainFunc = async () =>
        await API.post("user/leave/request/id", {
          params: encryptJsonObject({
            id: reqId,
            type: type,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("request id=", decrypt);
          if (decrypt?.status === "success") {
            if (isChangeRQ !== undefined) {
              decrypt.isChangeRQ = isChangeRQ;
            }
            setItemData(decrypt);
          } else {
            setItemData({
              ...defaultItemData,
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          //console.log(`Failes : ${err}`);
          setItemData({ ...defaultItemData, err: 1, err_msg: err });
        });
    });
  }, []);

  if (itemData.status === "") {
    return "";
  } else if ("err" in itemData && itemData.err === 1) {
    modalCSS.height = "170px";
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="text-error">Fetch request error: {itemData.err_msg}</div>
    );
  } else if (itemData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("Edit", itemData);
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="modal-content-outer">
        <div key="modal-content-inner" className="modal-content-inner">
          <Feature.Content data={itemData.data} type={itemData.type} />
          <input
            type="hidden"
            id="inp-req-type"
            name="inp-req-type"
            value={itemData.type}
          />
        </div>
        <div key="modal-loading" className="modal-loading hide">
          <Html.Preloading
            addon={{
              css: {
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
              },
            }}
          />
        </div>
      </div>
    );
    footer = (
      <div className="modal-footer-bottom">
        <span className="modal-error-text hide"></span>
        <BtnEditSubmit
          events={
            itemData.type == "delete"
              ? handleEvents.BtnDelete
              : handleEvents.BtnEdit
          }
          header={header}
        />
      </div>
    );
  }

  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={false}
      className="modal-user modal-user-footer"
      id="modal-user-edit"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.OnClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
      {footer}
    </Modal>
  );
}

const Feature = {};
Feature.Content = function ({ data, type }) {
  let content = [];
  let userPhoto = "";
  if ("photo" in data && data.photo != "") {
    userPhoto = (
      <div
        className="image-circle"
        style={{
          background: "url('" + data.photo + "') no-repeat center",
          backgroundSize: "cover",
        }}
      />
    );
  } else {
    userPhoto = (
      <div className="image-circle">
        <i className="material-icons">person</i>
      </div>
    );
  }
  content.push(
    <div
      key="content-req-user"
      className="row content-req"
      id="content-req-user"
    >
      <strong className="col s4 title">{userPhoto}</strong>
      <div className="col s8 desc">{data.full_name}</div>
    </div>
  );
  content.push(
    <div key="content-req-leavetype" className="row content-req">
      <strong className="col s4 title">Leave Type</strong>
      <div className="col s8 desc">{data.leavetype_name}</div>
    </div>
  );
  content.push(
    <div key="content-req-duration" className="row content-req">
      <strong className="col s4 title">Duration</strong>
      <div className="col s8 desc">{data.duration_desc}</div>
    </div>
  );
  content.push(
    <div key="content-req-from" className="row content-req">
      <strong className="col s4 title">From</strong>
      <div className="col s8 desc">
        {data.full_start_date +
          (data.full_start_time != "" ? ", " + data.full_start_time : "")}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-to" className="row content-req">
      <strong className="col s4 title">To</strong>
      <div className="col s8 desc">
        {data.full_end_date +
          (data.full_end_time != "" ? ", " + data.full_end_time : "")}
      </div>
    </div>
  );
  content.push(
    <div
      key="content-req-reason"
      className="row content-req"
      id="content-req-reason"
    >
      <strong className="col s4 title">
        {type == "delete" ? "Reason" : "Change Reason"}
      </strong>
      <div className="col s8 desc">
        <Textarea
          id="inp-req-reason"
          req-id={data.id}
          name="reason"
          defaultValue={type == "delete" ? "" : data.reason}
        />
      </div>
    </div>
  );
  if (data.leavetype_code == "SL" && "file" in data) {
    content.push(
      <div
        key="content-req-file"
        className="row content-req"
        id="content-req-file"
      >
        <strong className="col s4 title">File</strong>
        <div className={"col s8 desc"}>
          <a target="_blank" href={data["file"].url}>
            <img className="responsive-img" src={data["file"].url} />
          </a>
        </div>
      </div>
    );
  }

  return <div>{content}</div>;
};
