import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-materialize";
import config from "../../config";
import API from "../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Html from "./html";
import $ from "jquery";

export default function DeleteItem({ form_id, setDeleteItems }) {
  const [itemData, setItemData] = useState({
    status: "",
    text: "",
    ids: [],
    form_id: form_id,
  });
  useEffect(() => {
    $("#root").off("click", "#" + form_id + " .btn-open-modal");
    $("#root").on("click", "#" + form_id + " .btn-open-modal", function (e) {
      e.preventDefault();
      const dataType = $(this).attr("data-type");
      if (dataType == "delete-item") {
        let items = $("#" + form_id)
          .find("input[name=delete_items]")
          .val();
        if (items == "") {
          items = [];
        } else {
          items = JSON.parse(items);
        }
        if (items.length > 0) {
          setItemData({
            status: "open",
            text: "",
            ids: items,
            form_id: form_id,
          });
        }
      }
    });
    $("#root").off("click", "#" + form_id + " .btn-delete-single-item");
    $("#root").on(
      "click",
      "#" + form_id + " .btn-delete-single-item",
      function (e) {
        e.preventDefault();
        let items = $(this).attr("data-id");
        setItemData({
          status: "open",
          text: "",
          ids: [items],
          form_id: form_id,
        });
      }
    );
  }, []);

  const handleEvents = {
    onClose: function () {
      $("#" + form_id)
        .find("input[name='delete-item']")
        .each(function () {
          $(this).prop("checked", false);
        });
      $("#" + form_id)
        .find("input[name=delete_items]")
        .val("");
      setItemData({
        status: "close",
        text: "",
        ids: [],
        form_id: "",
      });
      if (form_id != "calendar-form-edit-content") {
        setDeleteItems({
          key: Math.floor(Math.random() * 10000 + 1),
          items: [],
        });
      }
    },
    onSubmit: function (e) {
      e.preventDefault();
      let itemID = itemData.ids;
      if (itemID.length === 0) {
        return;
      }
      //console.log(formData);
      setItemData({ status: "processing", text: "" });
      const isDraft = itemID[0].split("draft").length > 1 ? true : false;
      let form = $(e.target).parents("form").first();
      if (isDraft) {
        for (let i in itemID) {
          let tr = $("#row-input-holiday_name-" + itemID[i]).parents("tr")[0];
          $(tr).remove();
        }
        $("#" + form_id)
          .find("input[name=delete_items]")
          .val("");
        setItemData({ status: "success", text: "Done" });
        //setDeleteItems({key: (Math.floor((Math.random() * 10000) + 1)), items: []});
        return;
      }

      const mainFunc = async () =>
        await API.post("holiday/delete/item", {
          params: encryptJsonObject({
            itemID: itemID,
            userID: window.$user_profile.idu,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.deletedItem) {
            let deletedItem = decrypt.deletedItem;
            for (let i in deletedItem) {
              const id = deletedItem[i];
              let tr = $("#row-input-holiday_name-" + id).parents("tr")[0];
              $(tr).hide();
            }
            const total =
              parseInt($("#total-text__number").text()) - deletedItem.length;
            $("#total-text__number").text(total);
            $("#" + form_id)
              .find("input[name=delete_items]")
              .val("");
            setItemData({ status: "success", text: "Done" });
            setDeleteItems({
              key: Math.floor(Math.random() * 10000 + 1),
              items: [],
            });
          } else {
            setItemData({
              tatus: "error",
              text: `Failes : ` + decrypt?.err_msg ?? "",
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          setItemData({ status: "error", text: `Failed : ${err}` });
        });
    },
  };

  let header = "Delete Holiday";
  let content = "";
  let btns = [];
  let modalCSS = { height: "225px" };

  if (itemData.status === "" || itemData.status === "close") {
    return "";
  } else if (itemData.status === "success") {
    btns = [
      <Button flat modal="close" node="button">
        Close
      </Button>,
    ];
    content = <div className="modal-done-text">{itemData.text}</div>;
  } else if (itemData.status === "error") {
    btns = [
      <Button flat modal="close" node="button">
        Close
      </Button>,
    ];
    content = <div className="modal-error-text">{itemData.text}</div>;
  } else if (itemData.status == "processing") {
    content = Html.Preloading({
      css: {
        position: "absolute",
        top: "50px",
        left: "0",
        right: "0",
        bottom: "0",
        margin: "auto",
      },
    });
  } else {
    btns = [
      <Button
        id="btn-submit-delete-item"
        node="button"
        waves="green"
        onClick={handleEvents.onSubmit}
      >
        Yes, please.
      </Button>,
      <Button flat modal="close" node="button">
        No, I change my mind.
      </Button>,
    ];
    content = (
      <div style={{ margin: "20px 0", fontSize: "18px" }}>
        Are you sure to delete?
      </div>
    );
  }
  //console.log("Create Delete");
  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={true}
      header={header}
      id="modal-delete-item"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.onClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
    </Modal>
  );
}
