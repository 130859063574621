import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { Button, Icon } from 'react-materialize';
import Html from "../../utils/Html";
import html2canvas from "html2canvas";


export default function ZoomChart() {
    const canvasRef = useRef(null);
    useEffect(() => {
        //var canvas = document.getElementById("previewImg");
        /*const canvas = canvasRef.current;
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "#FF0000";
        ctx.fillRect(0, 0, 150, 75);*/
    }, []);
    let handleEvents = {
        ZoomIn : {
            OnClick : function(evt) {
                evt.preventDefault();
                let zoomElem = $("#org-content #tree");
                let zoomValue = zoomElem.css("zoom");
                zoomValue = parseFloat(zoomValue) + 0.1;
                if(zoomValue > 1.5) {
                    zoomValue = 1.5;
                }
                zoomElem.css({zoom: zoomValue});
            }
        },
        ZoomOut : {
            OnClick : function(evt) {
                evt.preventDefault();
                let zoomElem = $("#org-content #tree");
                let zoomValue = zoomElem.css("zoom");
                zoomValue = parseFloat(zoomValue) - 0.1;
                if(zoomValue < 0.7) {
                    zoomValue = 0.6;
                }
                zoomElem.css({zoom: zoomValue});
            }
        }
    }
    const downloadURI = function(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        //clearDynamicLink(link); 
    };
    return <div key="zoom-chart" className="zoom-chart">
        <Button
            flat 
            className="btn-zoom-chart"
            node="button"
            onClick={handleEvents.ZoomIn.OnClick}
            >
            <Icon left>zoom_in</Icon>
        </Button>
        <Button
            flat 
            className="btn-zoom-chart"
            node="button"
            onClick={handleEvents.ZoomOut.OnClick}
            >
            <Icon left>zoom_out</Icon>
        </Button>
        <Button
            flat 
            className="btn-download-chart"
            id="btn-download-chart"
            node="button"
            >
            <Icon left>cloud_download</Icon>
        </Button>
    </div>
}