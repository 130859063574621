import React, { Component, useState, useEffect } from "react";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import "./style.css";
import TopContent from "./top.content";
import MainContent from "./main.content";

const HrAssign = (props) => {
  let userData = window.$user_profile;
  const [filterData, setFilterData] = useState({
    data: "",
    err: 0,
    err_msg: "",
  });
  const mainFunc = async () =>
    await API.post("hr/assign/filter", {
      params: encryptJsonObject({
        country_code: userData.employed_by_code,
        is_access_all_country: userData.user_sess.userCanAccessAllCountries,
        is_exo_group: userData.is_exo_group,
        user_country_access_code: userData?.user_countries_access?.codes ?? [],
      }),
    });
  useEffect(() => {
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setFilterData(decrypt);
        } else {
          setFilterData({
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        setFilterData({ err: 1, err_msg: `Failes : ${err}` });
      });
  }, []);

  if (
    !("user_sess" in userData) ||
    !userData["user_sess"].userCanAccessHrAssign
  ) {
    return <Html.NoAccess />;
  }
  if (filterData.err == 1) {
    return <div>Fetch request error: {filterData.err_msg}</div>;
  } else if (filterData.data == "") {
    return (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  }

  return (
    <div id="hr-content" className="inner-container">
      <TopContent userData={userData} filterData={filterData.data} />
      <MainContent userData={userData} />
    </div>
  );
};
export default HrAssign;
