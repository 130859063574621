import React from "react";

export default function TrHead() {
    const data = [
        "",
        "Country",
        "First Name",
        "Last Name",
        "Position",
        "Department",
        "Division",
        "Managed By",
        "Manages"
    ];
    let list = [];
    for(const i in data) {
        list.push(<th key={"col-" + i} className={"th-" + data[i].toLocaleLowerCase()}>
            {data[i]}
        </th>);
    }
    return <tr key="row-0">
        {list}    
    </tr>
}