import React, { useState, useEffect } from "react";
import { Button, Modal, TextInput } from 'react-materialize';
import $ from 'jquery';
import Html from "../../utils/Html";
import Func from "../../utils/Func";
import BtnModifySubmit from "./modal.modify.btn.submit";

export default function ModalModify({year, setFilters, setReqData, setActionData}) {
    const [itemData, setItemData] = useState({status: "", err: 0, err_msg: ""});
    let header = "Modify unused balance";
    let content = "";
    let footer = "";
    let btns = [
        <div className="header">
            {header}
        </div>
    ];
    let modalCSS = {};
    modalCSS.height = "240px";
    const handleEvents = {
        BtnConfirm : {
            OnComplete : function() {
                setItemData({status: "", err: 0, err_msg: ""});
                setReqData({status: "", err: 0, err_msg: ""});
                let filterData = Func.GetDataForm($("#form-filter-request").serializeArray());
                filterData["limit"] = $("#select-limit-row").val();
                filterData["page"] = 1; 
                setFilters(filterData);
                setActionData({key: 0, items: [], checked: false, pages: []});
            }
        },
        OnClose : function() {
            $("body").css({"overflow": "auto"});
            setItemData({status: "", err: 0, err_msg: ""});
        },
        OnSubmit : function(evt) {
            // Form Submit
            evt.preventDefault();
        } 
    }
    useEffect(() => {
        $("#main-content").on("click", "#btn-action-modify", function(evt){
            let items = $("#form-edit-assign").find("input[name=action_items]").val();
            items = (items == "") ? [] : JSON.parse(items);
            if(items.length == 0){
                setItemData({
                    status: "error", 
                    err: 1, 
                    err_msg: "Please select staff!"
                });
                return;
            }
            setItemData({
                status: "success",
                err: 0,
                err_msg: ""
            });
        });
    }, []);

    if(itemData.status === "") {
        return "";
    }
    else if ('err' in itemData && itemData.err === 1) {
        modalCSS.height = "200px";
        btns.push(<Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>);
        content = <div className="text-error">Fetch request error: {itemData.err_msg}</div>;
    }
    else if (itemData.status !== "success") {
        content = <div>
            <Html.Preloading addon={{
                css: {
                    position: "absolute",
                    top: "20px",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    margin: "auto"
                }
            }} />
        </div>;
    }
    else {
        //console.log("Edit", itemData);
        btns.push(<Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>);
        content = <div className="modal-content-outer">
            <div key="modal-content-inner" className="modal-content-inner">
                <Feature.Content />
            </div>
            <div key="modal-loading" className="modal-loading hide">
                <Html.Preloading addon={{
                    css: {
                        position: "absolute",
                        top: "20px",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        margin: "auto"
                    }
                }} />
            </div>
        </div>
        footer = <div className="modal-footer-bottom">
            <span className="modal-error-text hide"></span>
            <BtnModifySubmit 
                events={handleEvents.BtnConfirm} 
                header={header} 
                year={year} />
        </div>
    }

    return (<Modal
        actions={btns}
        bottomSheet={false}
        fixedFooter={false}
        className="modal-user modal-user-footer"
        id="modal-assign-modify"
        open={true}
        options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: handleEvents.OnClose,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '5%'
        }}
        root={document.getElementById("root")}
        style={modalCSS}
        >
        {content}
        {footer}
    </Modal>);
}

const Feature = {};
Feature.Content = function({}) {
    let content = [];
    content.push(<div 
        key="content-modify_percentage" 
        className="row" 
        id="content-modify_percentage" 
        style={{marginTop: "10px"}}>
        <strong className="col s6 title" style={{height: "35px", lineHeight: "35px"}}>
            Decrease by percentage %
        </strong>
        <div className="col s5 desc">
            <TextInput
                className="modal-inp-assign no-default"
                id="inp-req-percentage" 
                name="percentage"
                type="text"
                defaultValue="50"
            />
        </div>
        <div className="col s12" style={{color: "red", marginTop: "15px", padding: "0px", fontSize: "14px"}}>
            Once confirmed. It cannot be undone.
        </div>
    </div>);
    return <div>
        {content}
    </div>
}