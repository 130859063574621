import React from "react";
import $ from 'jquery';
import Html from "../../utils/Html";
import Func from "../../utils/Func";

export default function SelectPage({totalPage, activePage, setFilters, setReqData}) {
    const handleEvents = {
        OnClick : function(evt) {
            evt.preventDefault();
            let page = $(evt.target).attr("page-index");
            if(page == undefined) {
                let li = $(evt.target).parents("li");
                if($(li).hasClass("disabled")){
                    return;
                }
                page = $(evt.target).parent().attr("page-index");
            }
            let filters = {};
            filters["status"] = ["PD"];
            filters["limit"] = $("#select-limit-row").val();
            filters["page"] = page;
            setFilters(filters);
            setReqData({status: "", err: 0, err_msg: ""});
        }
    }
    return <Html.Paginations 
        totalPage={totalPage} 
        activePage={activePage} 
        className={"table-pagination"} 
        handleEvents={handleEvents.OnClick} />
}
