import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select, Checkbox } from 'react-materialize';
import Html from "../../utils/Html";

export default function FilterDepartment({data, key_form}) {
    const [departmentFilters, setDepartmentFilters] = useState({data: [], key_form: key_form, checked: false});
    let handleEvents = {
        BtnSelectAll : {
            OnClick : function(evt) {
                let selectedAll = false;
                if($(evt.target).is(":checked")) {
                    selectedAll = true;
                }
                let selectedArr = [];
                if(selectedAll){
                    $("#filter-inp-department option").each(function () {
                        if($(this).attr("disabled") == undefined) {
                            selectedArr.push($(this).val());
                        }
                    });
                }
                //console.log("Btn Select All => Department");
                setDepartmentFilters({
                    data: selectedArr, 
                    key_form: Math.random(),
                    checked: selectedAll
                });
            }
        }
    };
    const n = Object.keys(data).length;
    let selected = [0];
    let itemData = [];
    //itemData.push({name: "All Department", id: 0});
    for(let org_id in data) {
        const sortedData = Feature.SortedDepartment(data[org_id], (n > 1 ? true : false));
        itemData = itemData.concat(sortedData);
    }
    //console.log("itemData", data, itemData);
    return <div key={"filter-inp-department" + key_form} className="filter-input-field select">
        <div className="btn-select-all">
            <Checkbox
                checked={departmentFilters.checked}
                filledIn
                className=""
                id="btn-select-all-department"
                label="All"
                value="yes"
                onClick={handleEvents.BtnSelectAll.OnClick}
            />
        </div>
        <Select 
            multiple={true} 
            key={"filter-inp-department" + departmentFilters.key_form}
            id="filter-inp-department" 
            name="department" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={departmentFilters.data}
        >
            <Html.Options data={itemData} addon={{
                key: "department", 
                name: "name", 
                value: "id",
                title: "Department"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-department">Please select department</span>
    </div>
}

const Feature = {};
Feature.SortedDepartment = (data, isOrgs) => {
    let items = [];
    let root_id = "";
    let childs = {};
    let org_name = "";
    for(let id in data) {
        id = parseInt(id);
        const parent = data[id]["parent"];
        if(parent != null) {
            if(!(parent in childs)) {
                childs[parent] = [];
            }
            childs[parent].push(id);
        }
        if(root_id == "" && ('ori_parent' in data[id]) && data[id]['ori_parent'] == null) {
            root_id = id;
            org_name = data[id]['org_name'];
        }
    }
    if(root_id == "") {
        return items;
    }
    let elem = root_id;
    let queues = [elem];
    let isFirst = false;
    while(elem != null && queues.length > 0) {
        /*if(!isFirst) {
            items.push({
                id: elem, 
                name: data[elem]["org_name"],
                isHead: true 
            });
            isFirst = true;
        }*/
        items.push({
            id: elem, 
            name: data[elem]["name"] + (isOrgs ? " (" + org_name + ")" : ""), 
            parent: data[elem]["parent"]
        });
        if(elem in childs && childs[elem].length > 0) {
            queues.unshift(childs[elem][0]);
            elem = queues[0];
            continue;
        }
        // Find next elem and have child
        let next_elem = null;
        let tmp_queues = [...queues];
        for(let i in tmp_queues) {
            let queue = tmp_queues[i];
            if(queue in childs && childs[queue].length > 0) {
                queues.unshift(childs[queue][0]);
                next_elem = queues[0];
                break;
            }
            const remove_queue = queues.shift();
            const parent_queue = data[queue]["parent"];
            if(parent_queue == null) {
                break;
            }
            if(parent_queue in childs && childs[parent_queue].length > 0) {
                const tmp_childs = childs[parent_queue].filter(item => item !== queue);
                childs[parent_queue] = tmp_childs;
            }
        }
        elem = next_elem;
    }
    
    return items;
}