import React from 'react';
import { Button, Icon } from 'react-materialize';
import $ from 'jquery';

export default {
    ImagesLoad(mainElem, status, addon) {
        if(status == "processing"){
            return;
        }
        console.log("Image Loader => ", status);
        var imageLoaded = function(evt) {
            // Run onload code.
            let elem = $(evt.target);
            let id = elem.attr("id");
            let src = elem.attr("src");
            let styles = {};
            styles["background-image"] = "url('" + src + "')";
            styles["background-repeat"] = "no-repeat";
            styles["background-size"] = "cover";
            styles["background-position"] = "center";
            $("#" + mainElem + " ." + id).find("i").remove();
            $("#" + mainElem + " ." + id).css(styles);
            if('style' in addon) {
                $("#" + mainElem + " ." + id).css(addon.style);
            }
        }
        var imageError = function(err) {
            // Run onload code.
            console.log("Image load is error");
        }
        $("#" + mainElem + " .image-loader").each(function() {
            var tmpImg = new Image();
            tmpImg.onload = imageLoaded;
            tmpImg.onerror = imageError;
            tmpImg.src = $(this).attr('data-src');
            tmpImg.id = $(this).attr('id');
        }) ;
    },
    StaffManages(data, maxInx) {
        let manages = [];
        if('manages' in data) {
            let inx = 0;
            let isManages = false;
            for(let i in data["manages"]) {
                let manageClass = "";
                if(inx >= maxInx) {
                    manageClass = " hide";
                    isManages = true;
                }
                inx++;
                manages.push(<div key={"manages-" + i} className={"box-small-staff" + manageClass}>
                    {this.ImageCircle(data["manages"][i]["photo"], data["manages"][i]["idu"])}
                    <span className="text">{data["manages"][i]["fname"]}</span>
                </div>);
            }
            if(isManages) {
                manages = <div className="box-manages">
                    <div className="name">{manages}</div>
                    <div className="icon">
                        <Button
                            className="btn-more-manages grey darken-1"
                            icon={<Icon>more_horiz</Icon>}
                            small
                            node="button"
                            />
                    </div>
                </div>
            }
        }
        if(manages.length == 0) {
            manages.push(<span key="manages-0" className="text">-</span>);
        }
        return manages;
    },
    StaffManagedBy(data) {
        let managedBy = "-";
        if('managed_by' in data && 'full_name' in data["managed_by"]) {
            managedBy = <div className="box-small-staff">
                {this.ImageCircle(data["managed_by"]["photo"], data["managed_by"]["idu"])}
                <span className="text">{data["managed_by"]["full_name"]}</span>
            </div>
        }
        return managedBy;
    },
    ImageCircle(image, id, style) {
        let photo = "";
        if(image != "") {
        //if(image == "xx") {
            //photo = <div className="image-circle" style={{background: "url('" + image + "') no-repeat center", backgroundSize: "cover"}} />
            photo = <div className={"image-circle image-loader staff-photo-" + id }
                id={"staff-photo-" + id} 
                data-src={image}>
                <i className="material-icons">person</i>
            </div>
        }
        else {
            photo = <div className="image-circle">
                <i className="material-icons">person</i>
            </div>
        }
        return photo;
    }
}