import React from 'react';
import { useParams } from "react-router";
//import "./style.css";
import config from "../../config";
import Html from "../../utils/Html";
import BtnRefreshLeave from "./btn.refresh.leave";
import BtnRemoveHoliday from "./btn.remove.holiday";
import BtnRefreshHoliday from "./btn.refresh.holiday";
import BtnBackupTables from './btn.backup.tables';
import BtnRestoreTables from './btn.restore.tables';
import FormCarryover from './form.carryover';

const Setting = (props) => {
    //let { reqId } = useParams();
    let userData = window.$user_profile;
    if (!('user_sess' in userData) || !userData["user_sess"].isAdmin){
        return <Html.NoAccess />;
    }
    let content = [];
    content.push(<div className="row">
        <BtnRemoveHoliday header="Remove Calendar Event Public Holiday" />
        <BtnRefreshHoliday header="Refresh Public Holiday" />
        <BtnRefreshLeave header="Refresh Leave" />
        <div style={{color: "red", display: "block", float: "left", margin: "20px 0"}}>
            * Please click 'Remove Calendar Event Public Holiday' first and please check 'EXO Travel Public Holidays' calendar too. 
            it should to remove all events in there.
            If still have some events in that, please click again and can do step 2 and 3
        </div>
    </div>);
    content.push(<div key="box-recalculete-leave" className="row inner-container">
        <BtnBackupTables header="Backup Tables" />
        <BtnRestoreTables header="Restore Tables" />
    </div>);
    return (<div id="main-content">
        <div className="row title">
            Setting
        </div>
        {content}
    </div>);
}
export default Setting;