import React from "react";
import { Checkbox } from 'react-materialize';
import $ from 'jquery';

export default function CheckboxAction({data, index, actionData, setActionData}) {
    let action_items = ('items' in actionData) ? actionData.items : [];
    let actionChecked = (action_items.indexOf(data.idu + "-" + data.country_code) > -1) ? true : false;

    const handleEvents = {
        OnClick : function(evt) {
            let elem = $(evt.target);
            let checked = false;
            if(elem.is(":checked")){
                checked = true;
            }
            const formElem = elem.parents("form").first();
            const id = elem.val();
            let items = $(formElem).find("input[name=action_items]").val();
            if(items == ""){
                items = [];
            }
            else{
                items = JSON.parse(items);
            }
            const pos = items.indexOf(id);
            if(checked){
                if(pos == -1){
                    items.push(id);
                }
            }
            else{
                if(pos > -1){
                    items.splice(pos, 1);
                }
            }
            // Pages
            let pages = $(formElem).find("input[name=action_pages]").val();
            if(pages == ""){
                pages = [];
            }
            else{
                pages = JSON.parse(pages);
            }
            // END Pages
            //console.log(items, pages);
            setActionData({
                key: (Math.floor((Math.random() * 10000) + 1)),
                items: items,
                pages: pages,
                checked: checked
            }); 
            //console.log(items);
            //$(formElem).find("input[name=action_items]").val(JSON.stringify(items));
        }
    }

    return  <div key="checkbox-action-item" style={{marginTop: "8px"}}>
        <Checkbox
            key={"action-item-" + index + "-" + actionData.key}
            key-number={actionData.key}
            id={"action-item-" + index}
            checked={actionChecked}
            filledIn
            name="action-item"
            label=""
            value={data.idu + "-" + data.country_code}
            onClick={handleEvents.OnClick}
        />
    </div>
}
