import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select } from 'react-materialize';
import Html from "../../utils/Html";

export default function FilterOrgLevel({data, filters}) {
    data = [];
    if('isBranch' in filters && filters["isBranch"]) {
        data.push({name: "City", id: "City"});
    }
    else {
        data.push({name: "Global", id: "Global"});
        data.push({name: "Geographical", id: "Geographical"});
    }
    const [orgLevelFilters, setLevelFilters] = useState({
        data: filters.data, 
        key_form: filters.key_form, 
        checked: false,
        disable: filters.disable
    });
    let handleEvents = {
        OnChange : function(evt){
            
        }
    };
    useEffect(() => {
        
    }, []);
    //console.log("Filter org Level");
    return <div key={"filter-org_level" + orgLevelFilters.key_form} className="filter-input-field select" id="filter-org_level">
        <Select 
            multiple={false} 
            key={"filter-inp-org_level" + orgLevelFilters.key_form}
            id="filter-inp-org_level" 
            name="org_level" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: false,
                    constrainWidth: true,
                    coverTrigger: false,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            defaultValue={orgLevelFilters.data}
            onChange={handleEvents.OnChange}
            disabled={orgLevelFilters.disable}
        >
            <Html.Options data={data} addon={{
                key: "org_level", 
                name: "name", 
                value: "id",
                title: "Level"
            }} />   
        </Select>
        <span className="select-helper-text hide" id="helper-text-org_level">Please select level</span>
    </div>
}