import React, { useState, useEffect } from "react";
import { Button, Icon } from 'react-materialize';
import $ from 'jquery';
import Function from "./function";

export default function TrContent({data}) {
    useEffect(() => {
        Function.ImagesLoad("org-table", "", {});
        
        $("#org-table").off("click", ".btn-more-manages");
        $("#org-table").on("click", ".btn-more-manages", function(){
            let isActive = false;
            if($(this).hasClass("active")) {
                isActive = true;
                $(this).removeClass("active");
            }
            else {
                $(this).addClass("active");
            }
            let mainBox = $(this).parents(".box-manages").first();
            let boxs = $(mainBox).find(".box-small-staff");
            let n = boxs.length;
            for(let i=0;i<n;i++) {
                if(i == 0) {
                    continue;
                }
                let box = boxs[i];
                if(!isActive) {
                    $(box).removeClass("hide");
                }
                else {
                    $(box).addClass("hide");
                }
            }
        });
    }, []);
    const colspan = 12;
    let list = [];
    for(const i in data) {
        list.push(<tr key={"row-" + i}>
            <Feature.TdContent 
                data={data[i]} 
                index={i} />
        </tr>);
    }
    if(data.length == 0) {
        list.push(<tr key="row-1">
            <td colSpan={colspan}>No Data</td>
        </tr>);
    }
    return list
}

const Feature = {};
Feature.ImageCircle = ({image, style}) => {
    let photo = "";
    if(image != "") {
        photo = <div className="image-circle" style={{background: "url('" + image + "') no-repeat center", backgroundSize: "cover"}} />
        //photo = <div className="image-circle"><i className="material-icons">person</i></div>
    }
    else {
        photo = <div className="image-circle">
            <i className="material-icons">person</i>
        </div>
    }
    return photo;
}
Feature.TdContent = ({data, index}) => {
    let td = [];
    let managedBy = "";
    let manages = [];
    if('managed_by' in data && 'full_name' in data["managed_by"]) {
        managedBy = <div className="box-small-staff">
            <Feature.ImageCircle image={data["managed_by"]["photo"]} />
            <span className="text">{data["managed_by"]["full_name"]}</span>
        </div>
    }
    if('manages' in data) {
        let inx = 0;
        let isManages = false;
        for(let i in data["manages"]) {
            let manageClass = "";
            if(inx >= 1) {
                manageClass = " hide";
                isManages = true;
            }
            inx++;
            manages.push(<div key={"manages-" + i} className={"box-small-staff" + manageClass}>
                <Feature.ImageCircle image={data["manages"][i]["photo"]} />
                <span className="text">{data["manages"][i]["fname"]}</span>
            </div>);
        }
        if(isManages) {
            manages = <div className="box-manages">
                <div className="name">{manages}</div>
                <div className="icon">
                    <Button
                        className="btn-more-manages grey darken-1"
                        icon={<Icon>more_horiz</Icon>}
                        small
                        node="button"
                        />
                </div>
            </div>
        }
    }
    if(manages.length == 0) {
        manages.push(<span key="manages-0" className="text">-</span>);
    }
    td.push(<td key={"row-" + index + "-col-photo"} className="td-photo">
        {Function.ImageCircle(data.photo, data.idu)}
    </td>);
    td.push(<td key={"row-" + index + "-col-country"} className="text-left">
        <span className="text">{data.work_loca}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-fname"} className="text-left">
        <Button
            className="btn-user-role grey darken-3"
            icon={<Icon>search</Icon>}
            small
            node="button"
            user-id={data.idu}
            />
        <span className="text">{data.fname}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-lname"} className="text-left">
        <span className="text">{data.lname}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-position"} className="text-left">
        <span className="text">{data.position}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-department"} className="text-left">
        <span className="text">{data.department}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-division"} className="text-left">
        <span className="text">{(data.division != null) ? data.division : "-"}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-managed_by"} className="text-left">
        {Function.StaffManagedBy(data)}
    </td>);
    td.push(<td key={"row-" + index + "-col-manages"} className="text-left">
        {Function.StaffManages(data, 1)}
    </td>);
    
    return td;
}