import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select, Checkbox } from 'react-materialize';
import Html from "../../utils/Html";

export default function FilterBranch({data, key_form}) {
    const [branchFilters, setBranchFilters] = useState({data: [], key_form: key_form, checked: false});
    let handleEvents = {
        BtnSelectAll : {
            OnClick : function(evt) {
                let selectedAll = false;
                if($(evt.target).is(":checked")) {
                    selectedAll = true;
                }
                let selectedArr = [];
                if(selectedAll){
                    $("#filter-inp-branch option").each(function () {
                        if($(this).attr("disabled") == undefined) {
                            selectedArr.push($(this).val());
                        }
                    });
                }
                //console.log("Btn Select All => Branch");
                setBranchFilters({
                    data: selectedArr, 
                    key_form: Math.random(),
                    checked: selectedAll
                });
            }
        }
    };
    const n = Object.keys(data).length;
    let selected = [0];
    let itemData = [];
    //itemData.push({name: "All Branch", value: 0});
    for(let org_id in data) {
        for(let i in data[org_id]) {
            itemData.push({
                name: data[org_id][i]['name'] + (n > 1 ? " (" + data[org_id][i]['org_name'] + ")" : ""), 
                value: data[org_id][i]['id']
            });
        }
    }
    return <div key={"filter-inp-branchs" + key_form} className="filter-input-field select">
        <div className="btn-select-all">
            <Checkbox
                checked={branchFilters.checked}
                filledIn
                className=""
                id="btn-select-all-branch"
                label="All"
                value="yes"
                onClick={handleEvents.BtnSelectAll.OnClick}
            />
        </div>
        <Select 
            multiple={true} 
            key={"filter-inp-branch" + branchFilters.key_form} 
            id="filter-inp-branch"
            name="branch" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={branchFilters.data}
        >
            <Html.Options data={itemData} addon={{
                key: "branch", 
                name: "name", 
                value: "value",
                title: "Branch"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-branch">Please select branch</span>
    </div>
}