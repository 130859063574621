import React, { useState, useEffect } from "react";
import { Button, Modal, TextInput } from "react-materialize";
import Html from "../../utils/Html";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import BtnEdit from "./modal.edit.btn";
import Functions from "./functions";
import Func from "../../utils/Func";

export default function ModalEdit({ userData, setFilters, setReqData }) {
  const userID = userData.idu;
  const [itemData, setItemData] = useState({
    type: "",
    data: {},
    status: "",
    err: 0,
    err_msg: "",
  });

  const handleEvents = {
    BtnEdit: {
      OnComplete: function () {
        setItemData({
          type: "",
          data: {},
          type: "",
          status: "",
          err: 0,
          err_msg: "",
        });
        setReqData({ status: "", err: 0, err_msg: "" });
        let filters = Func.GetDataForm(
          $("#form-filter-request").serializeArray()
        );
        filters["key_form"] = Func.GetKeyRandom();
        setFilters(filters);
      },
    },
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setItemData({ type: "", data: {}, status: "", err: 0, err_msg: "" });
    },
    OnSubmit: function (evt) {
      // Form Submit
      evt.preventDefault();
    },
  };
  useEffect(() => {
    //Function.ImagesLoad("org-view-staff", itemData.status, {});
    $("#org-content").off("click", ".btn-edit");
    $("#org-content").on("click", ".btn-edit", function (evt) {
      const elemID = $(this).attr("elem-id");
      const elemType = $(this).attr("elem-type");
      const editType = $(this).attr("edit-type");
      if (editType != undefined) {
        GetData(elemID, elemType, editType);
      }
    });
  }, [itemData]);

  const GetData = function (elemID, elemType, editType) {
    setItemData({ type: editType, status: "processing" });
    const mainFunc = async () =>
      await API.post("org/unit/setting/edit/data", {
        params: encryptJsonObject({
          userID: userID,
          elemID: elemID,
          elemType: elemType,
          editType: editType,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setItemData(decrypt);
        } else {
          setItemData({
            type: editType,
            data: {},
            status: "",
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        //console.log(`Failes : ${err}`);
        setItemData({
          type: editType,
          data: {},
          status: "",
          err: 1,
          err_msg: err,
        });
      });
  };

  let header = "Edit" + (itemData.status != "" ? " " + itemData.type : "");
  let content = "";
  let footer = "";
  let btns = [<div className="header">{header}</div>];
  let modalCSS = {};
  modalCSS.height = "70%";

  if (itemData.status === "") {
    return "";
  } else if ("err" in itemData && itemData.err === 1) {
    modalCSS.height = "170px";
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="text-error">Fetch request error: {itemData.err_msg}</div>
    );
  } else if (itemData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("View", itemData);
    let contentModal = "";
    if (itemData.type == "department" || itemData.type == "division") {
      contentModal = Functions.GetContentModalDept(
        itemData.data,
        itemData.type,
        handleEvents,
        { isEdit: true }
      );
    } else {
      contentModal = Functions.GetContentModalOrg(
        itemData.data,
        itemData.type,
        handleEvents,
        { isEdit: true }
      );
    }
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="modal-content-outer">
        <div key="modal-content-inner" className="modal-content-inner">
          <form
            key="form-add"
            id="form-add"
            method="post"
            onSubmit={handleEvents.OnSubmit}
          >
            {contentModal}
            <input type="hidden" name="action_type" value={itemData.type} />
          </form>
        </div>
        <div key="modal-loading" className="modal-loading hide">
          <Html.Preloading
            addon={{
              css: {
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
              },
            }}
          />
        </div>
      </div>
    );
    footer = (
      <div className="modal-footer-bottom">
        <span className="modal-error-text hide"></span>
        <BtnEdit events={handleEvents.BtnEdit} header={header} />
      </div>
    );
  }

  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={false}
      className="modal-user modal-user-footer"
      id="modal-edit"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.OnClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
      {footer}
    </Modal>
  );
}
