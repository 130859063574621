import React from "react";
import BtnCreateNewLeave from "./top.content.btn.create";
import BtnRequestCompensation from "./top.content.btn.request.compensation";
import Func from "../../utils/Func";

export default function TopContent({ data }) {
  const annual_data = { days: [], hours: [], isHtml: true };
  annual_data["days"].push(
    <span key="annual-day" className="sub-title">
      <span className="sub-title">
        {data.user_leave.annual_leave_days["days"]} days
      </span>
    </span>
  );
  annual_data["hours"].push(
    <span key="annual-hour" className="sub-title" style={{ width: "100%" }}>
      {data.user_leave.annual_leave_days["hours"] > 0 ? (
        <span
          className="sub-title"
          style={{ textAlign: "left", margin: "0 6px" }}
        >
          (+{data.user_leave.annual_leave_days["hours"]} hours)
        </span>
      ) : (
        ""
      )}
      <span
        className="sub-title"
        style={{ textAlign: "right", margin: "0 6px", color: "#fff" }}
      >
        as of today,&nbsp;
        {data.user_leave.annual_left_days["days"] +
          " day(s)" +
          (data.user_leave.annual_left_days["hours"] > 0
            ? " " + data.user_leave.annual_left_days["hours"] + " hour(s)"
            : "")}{" "}
        left
      </span>
    </span>
  );

  const previous_data = { days: [], hours: [], isHtml: true };
  previous_data["days"].push(
    <span key="previous-day" className="sub-title">
      <span className="sub-title">
        {data.user_leave.previous_year_balance["days"]} days
      </span>
    </span>
  );
  previous_data["hours"].push(
    <span key="previous-hour" className="sub-title" style={{ width: "100%" }}>
      {data.user_leave.previous_year_balance["hours"] > 0 ? (
        <span
          className="sub-title"
          style={{ textAlign: "left", margin: "0 6px" }}
        >
          (+{data.user_leave.previous_year_balance["hours"]} hours)
        </span>
      ) : (
        ""
      )}
      <span
        className="sub-title"
        style={{ textAlign: "right", margin: "0 6px" }}
      >
        as of today,&nbsp;
        {data.user_leave.previous_year_left_days["days"] +
          " day(s)" +
          (data.user_leave.previous_year_left_days["hours"] > 0
            ? " " +
              data.user_leave.previous_year_left_days["hours"] +
              " hour(s)"
            : "")}{" "}
        left
      </span>
    </span>
  );
  const balance_data = { days: [], hours: [], isHtml: true };
  balance_data["days"].push(
    <span key="balance-day" className="sub-title">
      <span className="sub-title">
        {data.user_leave.leave_days_balance["days"]} days
      </span>
    </span>
  );
  balance_data["hours"].push(
    <span key="balance-hour" className="sub-title" style={{ width: "100%" }}>
      {(data.user_leave.leave_days_balance["hours"] > 0) ? <span
        className="sub-title"
        style={{ textAlign: "left", margin: "0 12px" }}
      >
        (+{data.user_leave.leave_days_balance["hours"]} hours)
      </span> : ""}
      <span
        className="sub-title"
        style={{ textAlign: "right", margin: "0 12px", color: "#676464" }}
      >
        *pro rate leave:{" "}
        {data.user_leave.pro_rata_days_balance["days"] +
          " D" +
          (data.user_leave.pro_rata_days_balance["hours"] > 0
            ? " " + data.user_leave.pro_rata_days_balance["hours"] + " H"
            : "")}
      </span>
    </span>
  );
  const balance_desc = [];
  balance_desc.push(
    <span key="balance-desc-1">
      Includes unused balance from last year and annual leave entitlement
      [right].
    </span>
  );
  balance_desc.push(
    <span
      key="balance-desc-2"
      className="color-red"
      style={{ marginTop: "15px" }}
    >
      *Annual pro rata leave balance calculation
      <br />
      (duration from 1st Jan until today date) x (leave balance days) / 365
    </span>
  );
  let content = (
    <div id="top-content">
      <div className="row">
        <Feature.SubBox
          type="annual"
          data={annual_data}
          title={"Annual leave entitled"}
          desc={"Note: Unused balance will be carried forward to next year"}
        />
        <Feature.SubBox
          type="previous"
          data={previous_data}
          title={"Balance from last year"}
          desc={"Expires on " + data.user_leave.expiry_date}
          is_expired={data.user_leave.is_expired}
        />
        <Feature.SubBox
          type="balance"
          data={balance_data}
          title={"Leave days balance"}
          desc={balance_desc}
        />
        <Feature.SubBox
          type="compensation"
          data={data.user_leave.compensation_days_balance}
          title={"Compensatory day"}
          desc={""}
        />
      </div>
      <div className="row top-sub-bottom">
        <div>
          <span className="title">Your Approver is</span>
          <span>{data.eleave_approver_name}</span>
        </div>
        <div>
          <span className="title">Your Co-Approver is</span>
          <span>{data.eleave_co_approver_name}</span>
        </div>
        <BtnRequestCompensation />
        <BtnCreateNewLeave />
      </div>
    </div>
  );
  return content;
}

const Feature = {};
Feature.SubBox = ({ type, data, title, desc, is_expired }) => {
  let textDay = "";
  let textHour = "";
  if ("isHtml" in data && data.isHtml) {
    textDay = data.days;
    textHour = data.hours;
  } else {
    textDay = data.days + " days";
    if (data.hours > 0) {
      textHour = "(+" + data.hours + " hours)";
    }
  }
  return (
    <div key="top-sub-box" className="col s3 top-sub-box">
      <div id={"top-sub-box-" + type}>
        <span className="title">{title}</span>
        <span
          className={
            "text-day" + (is_expired == 1 ? " textcolor-expired" : " textcolor")
          }
        >
          {textDay}
        </span>
        <span
          className={
            "text-hour" +
            (is_expired == 1 ? " textcolor-expired" : " textcolor")
          }
        >
          {textHour}
        </span>
      </div>
      <span className="desc">{desc}</span>
    </div>
  );
};
