import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Select } from "react-materialize";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import FilterBranch from "./top.content.filter.branch";
import FilterDepartment from "./top.content.filter.department";

export default function RelateFilter({ filters, data }) {
  const [filterData, setFilterData] = useState({
    data: data.data,
    err: data.err,
    err_msg: data.err_msg,
    status: data.status,
    key_form: filters.key_form,
  });
  let formSubmit = function (orgs) {
    let filters = {};
    filters["organization"] = orgs;
    const mainFunc = async () =>
      await API.post("org/filter", {
        params: encryptJsonObject({
          filters: filters,
        }),
      });
    mainFunc()
      .then((res) => {
        //console.log("RelateFilter", res.data);
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setFilterData({ ...decrypt, key_form: Math.random() });
        } else {
          setFilterData({
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        setFilterData({ err: 1, err_msg: `Failes : ${err}` });
      });
  };
  useEffect(() => {
    $("#org-content").off("click", "#btn-select-all-organization");
    $("#org-content").on(
      "click",
      "#btn-select-all-organization",
      function (evt) {
        let selectedAll = false;
        if ($(evt.target).is(":checked")) {
          selectedAll = true;
        }
        let selectedArr = [];
        if (selectedAll) {
          $("#filter-inp-organization option").each(function () {
            if ($(this).attr("disabled") == undefined) {
              selectedArr.push($(this).val());
            }
          });
        }
        formSubmit(selectedArr);
      }
    );
    $("#org-content").off("change", "#filter-inp-organization");
    $("#org-content").on("change", "#filter-inp-organization", function (evt) {
      let selectedArr = [];
      $("#filter-inp-organization option:selected").each(function () {
        if ($(this).attr("disabled") == undefined) {
          selectedArr.push($(this).val());
        }
      });
      formSubmit(selectedArr);
    });
  }, []);
  if (filterData.err === 1) {
    return <div>Fetch request error: {filterData.err_msg}</div>;
  } else if (filterData.status !== "success") {
    return (
      <div key="">
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  }
  //console.log("TopContent => RelateFilters");
  let content = [];
  content.push(
    <div key={"filter-branch" + filterData.key_form}>
      <FilterBranch
        data={filterData["data"]["branches"]}
        keyform={filterData.key_form}
      />
    </div>
  );
  content.push(
    <div key={"filter-department" + filterData.key_form}>
      <FilterDepartment
        data={filterData["data"]["departments"]}
        keyform={filterData.key_form}
      />
    </div>
  );
  return content;
}
