import React, { Component, useState, useEffect } from 'react';
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import "./org.css";
import $ from 'jquery';
import TopContent from "./top.content";
import MainContent from "./main.content";
import Func from "../../utils/Func";
window.$first_load = true;

const Org = (props) => {
    let userData = window.$user_profile;
    const defaultFilters = {
        organization: userData.user_org,
        //organization: [10],
        //organization: [71, 72, 74],
        //organization: [3, 10, 2],
        //organization: userData.user_org,
        //department: [10001],
        //name: ["nat"],
        limit: 5,
        page: 1, 
        key_form: 0
    };
    const [clearFilters, setClearFilters] = useState(0);
    useEffect(() => {
        $("#container").css({maxWidth: "none"});
    }, []);
    return (<div id="org-content" className="inner-container">
        <TopContent defaultFilters={defaultFilters} key_form={clearFilters} />
        <MainContent userData={userData} 
            defaultFilters={defaultFilters}
            setClearFilters={setClearFilters} />
    </div>);
}
export default Org;
