import React, { useState, useEffect } from "react";
import { Select, TextInput, Icon, Button, Checkbox, Table, Dropdown } from 'react-materialize';
import $ from 'jquery';
import config from "../../config";
import API from "../../utils/API";
import Func from "../../utils/Func";
import Html from "../../utils/Html";
import TableContent from "./tableContent";
import CreateItem from "./createItem";
import CreateCalendar from "./createCalendar";
//import DeleteItem from "./deleteItem";
import FilterSearch from "./content.filterSearch";
import Handle from "./handle";
import M from 'materialize-css';  


export default function Content({data}) {
    let filter_country_id = [];
    let work_loca = window.$user_profile.work_loca;
    let employed_by = window.$user_profile.employed_by;
    let is_exo_group = window.$user_profile.is_exo_group;
    if('countries' in data) {
        let filter_country_data = Func.SearchObject(data.countries, [(is_exo_group == 'yes') ? work_loca : employed_by], "country_name");
        filter_country_id = Object.keys(filter_country_data);        
    }
    const defaultFilter = {
        year: [Func.GetCurrentYear()],
        country_id: filter_country_id,
        limit: [10],
        page: [1],
        key_form: 0,
    };
    const [filterData, setFilterData] = useState(defaultFilter);

    const handleEvents = {
        clearFilterButton : {
            OnClick : function(evt) {
                let filters = defaultFilter;
                filters["delete_items"] = [];
                filters["is_refresh"] = true;
                filters["key_form"] = Math.random(); 
                setFilterData(filters);
            }
        },
        filterButton : {
            OnSubmit : function(evt) {
                evt.preventDefault();
                //console.log("Form Submit");
                let arr = ["country", "year"];
                let isEmpty = false;
                for(let i in arr) {
                    let name = arr[i];
                    let value = $("#filter-inp-" + name).val();
                    const helper = $("#helper-text-" + name);
                    if(value.length == 0) {
                        if(!isEmpty) {
                            isEmpty = true;
                        }
                        helper.removeClass("hide");
                    }
                    else {
                        helper.addClass("hide");
                    }
                }
                if(isEmpty) {
                    return;
                }
                let filters = Func.GetDataForm($("#form-filter-search").serializeArray());
                filters["page"] = [1];
                filters["is_edit_mode"] = $("#btn-edit-mode").attr("data-mode");
                filters["delete_items"] = [];
                filters["is_refresh"] = true; 
                setFilterData(filters);
            }
        },
        selectLimit : {
            OnChange : function(evt) {
                const limitRow = $(evt.target).val();
                let filters = Func.GetDataForm($("#form-filter-search").serializeArray());
                filters["limit"] = [limitRow];
                filters["page"] = [1];
                filters["is_edit_mode"] = $("#btn-edit-mode").attr("data-mode");
                let deleteItems = $("input[name=delete_items]").val();
                deleteItems = (deleteItems != "") ? JSON.parse(deleteItems) : [];
                filters["delete_items"] = deleteItems;
                filters["is_refresh"] = true;
                setFilterData(filters);
            }
        },
        selectPage : {
            OnClick : function(evt) {
                evt.preventDefault();
                let page = $(evt.target).attr("page-index");
                if(page == undefined) {
                    let li = $(evt.target).parents("li");
                    if($(li).hasClass("disabled")){
                        return;
                    }
                    page = $(evt.target).parent().attr("page-index");
                }
                let filters = Func.GetDataForm($("#form-filter-search").serializeArray());
                filters["page"] = [page];
                filters["is_edit_mode"] = $("#btn-edit-mode").attr("data-mode");
                let deleteItems = $("input[name=delete_items]").val();
                deleteItems = (deleteItems != "") ? JSON.parse(deleteItems) : [];
                filters["delete_items"] = deleteItems;
                filters["is_refresh"] = true;
                setFilterData(filters);
            }
        },
        formEdit : {
            OnSubmit : function(evt) {
                evt.preventDefault();
                Handle.formEdit.OnSubmit(data.countries, setFilterData);
            }
        }
    }
    //console.log(data);
    return (<div >
        <div key="filter" id="filter-search">
            <form id="form-filter-search" action="" onSubmit={handleEvents.filterButton.OnSubmit} key={"form-filter-search-" + filterData.key_form}>
                <Feature.FilterSearch data={data} filterData={filterData} handleEvents={handleEvents} />
            </form>
            <div className="row">
                <Feature.CreateButton />
                <Feature.EditModeButton />
                <div id="btn-select-limit-row">
                    <Feature.SelectLimitRow limit={filterData.limit[0]} handleEvents={handleEvents.selectLimit} />
                </div>
            </div>
        </div>
        <div key="content" id="content">
            <div id="title-content">Public Holidays</div>
            <Feature.FormTable data={data} filters={filterData} handleEvents={handleEvents} setFilterData={setFilterData}/>
        </div>
        <CreateCalendar data={data} setFilterData={setFilterData} />
        <CreateItem data={data} setFilterData={setFilterData} />
        
        <div id="temp-loading" className="hide">
            <Html.Preloading addon={{
                css: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    margin: "auto"
                }
            }} />
        </div>
    </div>);
}

const Feature = {};
Feature.FormTable = ({data, filters, handleEvents, setFilterData}) => {
    return (<form id="form-edit-content" method="post" action="" onSubmit={handleEvents.formEdit.OnSubmit}>
        <div id="save-content">
            <div 
                id="save-status" 
                className={"card-panel hide"}
                style={{float: "left"}}>
                <span className="blue-text text-darken-2"></span>
            </div>
            <Button
                node="button"
                type="submit"
                className={"blue darken-3 hide"}
                id="btn-save-content" 
                style={{float: "left"}}
            >
                Save
            </Button>
            <div 
                id="save-loading"
                className={"hide"} 
                style={{float: "left"}}
            >
                <Html.Preloading addon={{class: "small"}} />
            </div>
        </div>
        <TableContent data={data} filters={filters} handleEvents={handleEvents} setFilterData={setFilterData}/>
    </form>);
}
Feature.EditModeButton = () => {
    return (<Button
        node="button"
        //className="light-blue lighten-2"
        className="btn"
        id="btn-edit-mode" 
        data-mode="0" 
        data-changed="0"
        style={{float: "right"}}
        onClick={Handle.editModeButton.OnClick}
        >
        Edit
    </Button>);
}
Feature.CreateButton = () => {
    return (<div id="btn-create-new">
        <Dropdown
        id="dropdown-create-new"
        options={{
            alignment: 'left',
            autoTrigger: true,
            closeOnClick: true,
            constrainWidth: true,
            container: null,
            coverTrigger: true,
            hover: false,
            inDuration: 150,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 250
        }}
        trigger={
            <Button className="blue darken-3" node="button" style={{width: "135px"}}>Create</Button>
        }>
        <span className="btn-open-modal" data-type="create-calendar">New Calendar</span>
        <span className="btn-open-modal" data-type="create-item">New Item</span>
        </Dropdown>
    </div>)
}
Feature.FilterSearch = ({data, filterData, handleEvents}) => {
    //console.log("Filter Search", filterData);
    const countries = data.countries;
    const lastyears = data.lastyears;
    let user_sess = ('user_sess' in window.$user_profile) ? window.$user_profile["user_sess"] : [];
    //user_sess["userCanAccessAllCountries"] = false;
    let work_loca = window.$user_profile.work_loca;
    let employed_by = window.$user_profile.employed_by;
    let is_exo_group = window.$user_profile.is_exo_group;
    let filter_country = countries;
    if((!('userCanAccessAllCountries' in user_sess) || !(user_sess["userCanAccessAllCountries"])) && is_exo_group != "yes"){
        filter_country = Func.SearchObject(countries, [employed_by], "country_name");
    }
    //console.log(countries, filter_country);
    if('country_name' in filterData){
        let filter_country_data = Func.SearchObject(countries, filterData.country_name, "country_name");
        filterData.country_id = Object.keys(filter_country_data);        
    }
    if(!('country_id' in filterData)) {
        filterData.country_id = [];
    }
    //console.log("Filters", filterData);
    return (<div className="filter-inputs row">
        <FilterSearch country={filter_country} filterData={filterData} />
        <div key="filter-name" className="filter-input-field">
            <TextInput
                /*icon={<Icon>search</Icon>}*/
                placeholder="Search..."
                id="filter-inp-name" 
                name="holiday_name" 
                label="Holiday Name Search"
            />
        </div>
        <div key="filter-start-date" className="filter-input-field filter-date">
            <TextInput
                id="filter-inp-start-date" 
                name="holiday_start_date" 
                label="Custom Start Date"
                type="date"
                onChange={Handle.inputFilter.OnChangeStartDate}
            />
        </div> 
        <div key="filter-end-date" id="filter-end-date" className="filter-input-field filter-date">
            <TextInput
            id="filter-inp-end-date" 
            name="holiday_end_date" 
            label="Custom End Date"
            type="date"
            onChange={Handle.inputFilter.OnChangeEndDate}
            />
            <span className="select-helper-text hide" id="helper-text-end-date" style={{width: "160px"}}>
                Custom date range search will be limited to 12 months date range
            </span>
        </div>
        <div key="filter-submit" id="filter-submit" className="filter-input-field">
            <Button
                node="button"
                type="submit"
                className=""
                id="btn-filter"
            >
                Search
            </Button>
            <Button
                node="button"
                type="button"
                className="blue-grey lighten-5"
                id="btn-clear-filter"
                style={{color: "#000"}}
                onClick={handleEvents.clearFilterButton.OnClick}
            >
                Clear All
            </Button>
        </div>
        <input type="hidden" name="limit" value={filterData.limit} />
        <input type="hidden" name="page" value={filterData.page} />
    </div>)
}
Feature.SelectLimitRow = ({limit, handleEvents}) => {
    let optionData = [];
    optionData.push({name: 10, value: 10});
    optionData.push({name: 20, value: 20});
    optionData.push({name: 30, value: 30});

    return (<Select 
        label="" 
        multiple={false} 
        id="select-limit-row" 
        name="limit-row" 
        options={{
            classes: '',
            dropdownOptions: {
                alignment: 'left',
                autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                coverTrigger: true,
                hover: false,
                inDuration: 150,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 250
            }
        }} 
        defaultValue={limit}
        onChange={handleEvents.OnChange}>
        <Html.Options data={optionData} addon={{key: "limit-row"}} />
    </Select>);
}