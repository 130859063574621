import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Handle from "./modal.handle";
import ModalDone from "./modal.done";
import ModalError from "./modal.error";
import ModalWarning from "./modal.warning";

export default function BtnEditSubmit({ events, header }) {
  const [resData, setResData] = useState({ status: "", err: 0, err_msg: "" });
  const callbackEvent = {
    OnClose: function () {
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnComplete: function () {
      events.OnComplete();
    },
  };
  const handleEvents = {
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnSubmit: function () {
      Handle.loading.OnShow();
      const id = $("#inp-req-reason").attr("req-id");
      const reason = $("#inp-req-reason").val();
      const type = $("#inp-req-type").val();
      const mainFunc = async () =>
        await API.post(
          "user/leave/request/" + (type === "delete" ? "delete" : "edit"),
          {
            params: encryptJsonObject({
              userID: window.$user_profile.idu,
              id: id,
              reason: reason,
              type: type,
            }),
          }
        );
      mainFunc()
        .then((res) => {
          Handle.loading.OnHide();
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setResData(decrypt);
          } else {
            setResData({
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          Handle.loading.OnHide();
          setResData({ err: 1, err_msg: "" });
        });
    },
  };
  //console.log("Edit Submit", resData);
  if ("err" in resData && resData.err === 1) {
    return (
      <ModalWarning
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if ("err" in resData && resData.err === 2) {
    return (
      <ModalError
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if (resData.status === "success") {
    return (
      <ModalDone
        header={header}
        content="This update is done!"
        callbackEvent={callbackEvent}
      />
    );
  }
  return (
    <Button
      className=""
      id="btn-submit-edit"
      node="button"
      waves="light"
      onClick={handleEvents.OnSubmit}
    >
      Confirm
    </Button>
  );
}
