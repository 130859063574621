// eslint-disable-next-line import/no-anonymous-default-export
export default {
  serverName: process.env.REACT_APP_SERVERNAME,
  mainDir: process.env.REACT_APP_MAINDIR,
  googleDiscoveryDocs: process.env.REACT_APP_GOOGLEDISCOVERYDOCS,
  googleScopes: process.env.REACT_APP_GOOGLESCOPES,
  googleCalendarHolidayId: process.env.REACT_APP_GOOGLECALENDARHOLIDAYID,
  googleApiKey: process.env.REACT_APP_GOOGLEAPIKEY,
  clientId: process.env.REACT_APP_CLIENTID,
  apiUrl: process.env.REACT_APP_APIURL,
  apiKey: process.env.REACT_APP_APIKEY,
  imageResizeUrl: process.env.REACT_APP_IMAGERESIZEURL,
  googleApiUrl: process.env.REACT_APP_GOOGLEAPIURL,
  baseUrl: process.env.REACT_APP_BASEURL,
  site: {
    home: process.env.REACT_APP_SITE_HOME,
    team: process.env.REACT_APP_SITE_TEAM,
    teamWaiting: process.env.REACT_APP_SITE_TEAMWAITING,
  },
};
