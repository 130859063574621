import React from "react";
import Func from "../../utils/Func";
import BtnView from "./main.content.btn.view";
import BtnEdit from "./main.content.btn.edit";
import BtnDelete from "./main.content.btn.delete";
import BtnCancelChange from "./main.content.btn.cancel.change";

export default function TrContent({data}) {
    const colspan = 7;
    let list = [];
    for(const i in data) {
        list.push(<tr key={"row-" + i}>
            <Feature.TdContent 
                data={data[i]} index={i} />
        </tr>);
    }
    if(data.length == 0) {
        list.push(<tr key="row-1">
            <td colSpan={colspan}>No Data</td>
        </tr>);
    }
    return list
}

const Feature = {};
Feature.TdContent = ({data, index}) => {
    let td = [];
    let btnChange = [];
    if(data.status_code !== "CC" && (data.change_request_status === "Pending" || data.change_request_status === "Approved")) {
        btnChange.push(<BtnCancelChange key="btn-cancel-change-req" id={data.id} />);
    }
    else if(data.status_code == "AP") {
        if(data.leavetype_code != "CA") {
            btnChange.push(<BtnEdit key="btn-edit-req" id={data.id} />);
        }
        const curDate = new Date(Func.GetCurrentDate());
        const startDate = new Date(data.start_date);
        if(startDate > curDate) {
            btnChange.push(<BtnDelete key="btn-delete-req" id={data.id} />);
        }
    }
    else if(data.status_code == "PD" || data.status_code == "RQ") {
        btnChange.push(<BtnDelete  key="btn-delete-req" id={data.id} />);
    }
    td.push(<td key={"row-" + index + "-col-startdate"}>
        <span className="text">{data.full_start_date}</span>
        {(data.full_start_time != "") ? <span className="small-text">{data.full_start_time}</span> : ""}
    </td>);
    td.push(<td key={"row-" + index + "-col-enddate"}>
        <span className="text">{data.full_end_date}</span>
        {(data.full_end_time != "") ? <span className="small-text">{data.full_end_time}</span> : ""}
    </td>);
    td.push(<td key={"row-" + index + "-col-total"}>
        <span className="text total">{data.full_total_days}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-leavetype"}>
        <span className="text">{data.leavetype_name}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-status"}>
        <div className={"status-text " + data.status_code}>
            {data.status_desc}
        </div>
    </td>);
    td.push(<td key={"row-" + index + "-col-view"}>
        <BtnView id={data.id} />
    </td>);
    td.push(<td key={"row-" + index + "-col-change"}>
        {btnChange}
    </td>);
    return td;
}