import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import CreateChart from "./create.chart";
import ModalAdd from "./modal.add";
import ModalEdit from "./modal.edit";
import Btn from "./main.content.btn";

export default function MainContent({
  userData,
  defaultFilters,
  setClearFilters,
}) {
  const userID = userData.idu;
  const defaultData = {
    data: {},
    summary_total: 0,
    total_page: 0,
    active_page: 1,
    status: "",
    err: 0,
    err_msg: "",
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [reqData, setReqData] = useState(defaultData);
  useEffect(() => {
    setReqData({ status: "processing" });
    const mainFunc = async () =>
      await API.post("org/unit/data", {
        params: encryptJsonObject({
          userID: userID,
          filters: filters,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setReqData(decrypt);
        } else {
          setReqData({
            status: "",
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        console.log(`Failes : ${err}`);
        setReqData({ status: "", err: 1, err_msg: err });
      });
  }, [filters]);
  let content = [];
  if ("err" in reqData && reqData.err === 1) {
    content.push(
      <div key="text-error-content" className="text-error">
        Fetch request error: {reqData.err_msg}
      </div>
    );
  } else if (reqData.status !== "success") {
    content.push(
      <div key="preloading-content">
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "50px",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("MainContent, Status => ", reqData.status);
    content.push(
      <div key="org-chart" id="org-chart">
        <CreateChart
          userData={userData}
          orgData={{
            root_id: reqData.data.root_id,
            details: reqData.data.details,
            childs: reqData.data.childs,
          }}
        />
      </div>
    );
  }
  return (
    <div key="main-content" id="main-content">
      <Btn
        setClearFilters={setClearFilters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      {content}
      <ModalAdd
        userData={userData}
        setFilters={setFilters}
        setReqData={setReqData}
      />
      <ModalEdit
        userData={userData}
        setFilters={setFilters}
        setReqData={setReqData}
      />
    </div>
  );
}
