import React, { useState, useEffect } from "react";
import "./App.css";
import { VirtualPath } from "./VirtualPath";
import { AuthProvider } from "./context/AuthContext";

const App = () => {
  useEffect(() => {
    const pathToTitleMap = {
      "/hrms/org": "Organization Charts",
      "/hrms/org/": "Organization Charts",
      "/hrms/org/unit": "Org Chart Admin",
      "/hrms/org/unit/": "Org Chart Admin",
    };

    const pathname = window.location.pathname;
    const documentTitle = pathToTitleMap[pathname] || "E-leave"; // Default title if no match
    document.title = documentTitle;
  }, []);

  return (
    <div id="container" className="container" style={{ width: "99%" }}>
      <AuthProvider>
        <VirtualPath />
      </AuthProvider>
    </div>
  );
};

export default App;
