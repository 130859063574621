import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";

import Handle from "../Container/modal.handle";
import ModalDone from "../Container/modal.done";
import ModalError from "../Container/modal.error";
import ModalWarning from "../Container/modal.warning";

export default function BtnSave({ year, events, header }) {
  const [resData, setResData] = useState({ status: "", err: 0, err_msg: "" });
  const callbackEvent = {
    OnClose: function () {
      Handle.saveButton.OnShow();
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnComplete: function () {
      Handle.saveButton.OnHide();
      events.OnComplete();
    },
  };
  const handleEvents = {
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnSubmit: function () {
      Handle.saveButton.OnProcessing();
      const inputName = "changed_items";
      const changed_items = $("input[name=" + inputName + "]").val();
      let items = changed_items == "" ? [] : JSON.parse(changed_items);
      if (items.length == 0) {
        Handle.saveButton.OnHide();
        return;
      }
      let formData = [];
      for (let i in items) {
        const keyId = items[i];
        const annual_days = $(
          "#inp-req-annual_leave_days[key-id='" + keyId + "']"
        ).val();
        const previous_days = $(
          "#inp-req-last_year_balance[key-id='" + keyId + "']"
        ).val();
        if (isNaN(annual_days)) {
          continue;
        }
        let sp = keyId.split("-");
        let tmp = {
          idu: sp[0],
          country_code: sp[1],
          annual_days: annual_days,
        };
        if (!isNaN(previous_days)) {
          tmp["previous_days"] = previous_days;
        }
        formData.push(tmp);
      }
      if (formData.length === 0) {
        Handle.saveButton.OnHide();
        return;
      }
      //console.log(formData, year);
      const mainFunc = async () =>
        await API.post("hr/assign/save", {
          params: encryptJsonObject({
            forms: formData,
            year: year,
            userID: window.$user_profile.idu,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setResData(decrypt);
          } else {
            setResData({
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          setResData({ err: 1, err_msg: err });
        });
    },
  };
  //console.log("Edit Submit", resData);
  let modal_content = "";
  if ("err" in resData && resData.err == 1) {
    modal_content = (
      <ModalWarning
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if ("err" in resData && resData.err === 2) {
    modal_content = (
      <ModalError
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if (resData.status === "success") {
    modal_content = (
      <ModalDone
        header={header}
        content="This update is done!"
        callbackEvent={callbackEvent}
      />
    );
  }
  return (
    <div className="outer-btn-save">
      <Button
        node="button"
        type="submit"
        className={"btn-save blue darken-3 hide"}
        id="btn-save-assign"
        onClick={handleEvents.OnSubmit}
        style={{ float: "right", marginTop: "-27px" }}
      >
        Save
      </Button>
      <div
        className="save-loading hide"
        style={{ float: "right", marginTop: "-27px" }}
      >
        <Html.Preloading addon={{ class: "small" }} />
      </div>
      {modal_content}
    </div>
  );
}
