import React, { useState, useEffect } from "react";
import $ from 'jquery';
import Func from "../../utils/Func";
import Html from "../../utils/Html";

export default function CreateChart({userData, orgData}) {
    const userID = userData.idu;
    const [chartData, setChartData] = useState({data: orgData, type: 'functional', status: '', err: 0, err_msg: ''});
    var curDown = false,
                curYPos = 0,
                curXPos = 0;
    const handleEvents = {}
    useEffect(() => {
        $("#tree").mousedown(function(m){
        //$("#org-content").on("mousedown", "#tree", function(m){
            curYPos = m.pageY;
            curXPos = $(this).scrollLeft() + m.pageX;
            curDown = true;
        });
        $("#tree").mousemove(function(m){
            if(curDown){
                $(this).scrollLeft(curXPos - m.pageX);
                //console.log("Mousemove");
            }
        });
        $("#tree").mouseup(function(m){
            curDown = false;
        });

        $("#org-content").on("mouseenter", ".div-dept", function(){
            let elem = $(this);
            elem.addClass("hover");
            //console.log("Enter");
        }).on("mouseleave", ".div-dept", function(){
            let elem = $(this);
            elem.removeClass("hover");
            //console.log("Leave");
        });
    }, [chartData]);
    let content = "";
    if ('err' in chartData && chartData.err == 1) {
        content = <div key="text-error-content" className="text-error">Fetch request error: {chartData.err_msg}</div>;
    }
    else if (chartData.status == "processing") {
        content = <div key="preloading-content">
            <Html.Preloading addon={{
                css: {
                    position: "absolute",
                    top: "50px",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    margin: "auto"
                }
            }} />
        </div>;
    }
    else{
        //console.log("create chart", chartData);
        let content_list = [];
        content_list = Feature.Tree(chartData.data, chartData.type, chartData.data.root_id);
        let contents = "";
        if(content_list.length > 0) {
            contents = content_list.join('');
        }
        content = `<ol class="organizational-chart">`;
        content += contents;
        content += `</ol>`;
        content = <div style={{display: "flex", justifyContent: "center"}} dangerouslySetInnerHTML={{__html: content}} />;
    }
    return <div key="inner-org-chart" id="inner-org-chart">
        <div id="tree">
            {content}
        </div>
    </div>
}

const Feature = {};
Feature.Tree = (data, type, elem) => {
    let details = data.details;
    let childs = {...data.childs};
    let queues = [elem];
    let level = 0;
    let content_list = [];
    let is_hide_division = false;
    while(elem != null && elem != "" && queues.length > 0) {
        level++;
        //console.log("elem=" + elem + ", level=" + level, user_depts[elem]);
        content_list.push(`<li class="li-dept">`);
        content_list.push(Feature.SmallBoxChart(details[elem], {
            exclude_top_level: false,
            level: level,
            elem_id: elem,
            count_child_dept: (elem in data["childs"]) ? data["childs"][elem].length : 0,
            is_hide_division: is_hide_division 
        }));

        if(elem in childs && childs[elem].length > 0) {
            let before_elem = elem;
            let ol_class = "";
            let ol_css = "";
            if(elem in data["childs"] && data["childs"][elem].length > 0) {
                ol_class = "sub-dept-" + elem;
                ol_css = (is_hide_division && 'level_id' in details[elem] && details[elem]["level_id"] >= 1) ? "display: none;" : ""; 
            }
            queues.unshift(childs[elem][0]);
            elem = queues[0];
            content_list.push(`<ol class='` + ol_class + `' style='` + ol_css + `'>`);
            continue;
        }
        content_list.push(`</li>`);
        // Find next elem and have child
        let next_elem = null;
        let tmp_queues = [...queues];
        for(let i in tmp_queues) {
            let queue = tmp_queues[i];
            if(queue in childs && childs[queue].length > 0) {
                queues.unshift(childs[queue][0]);
                next_elem = queues[0];
                break;
            }
            if(elem != queue) {
                content_list.push(`</ol></li>`);
            }
            const remove_queue = queues.shift();
            const parent_queue = details[queue]["parent"];
            if(parent_queue == null) {
                break;
            }
            if(parent_queue in childs && childs[parent_queue].length > 0) {
                const tmp_childs = childs[parent_queue].filter(item => item !== queue);
                childs[parent_queue] = tmp_childs;
            }
            level--;
        }
        elem = next_elem;
    }    
    return content_list;
}
Feature.GetUserRoots = (data) => {
    let root_id = data.root_id;
    let childs = data.childs;
    let details = data.details;
    let user_depts = data.user_depts;
    let user_details = data.user_details;
    let res = {dept_root: {}, user_root : []};
    if(root_id == "") {
        return res;
    }
    if(root_id == 1) {
        root_id = 2
    }
    if(!root_id in details) {
        return res;
    }
    res["dept_root"] = details[root_id];
    let root_childs = (root_id in childs) ? childs[root_id] : [];
    if(root_childs.length == 0) {
        return res;
    }
    const user_roots = [];
    let n = root_childs.length;
    for(let i=0;i<n;i++){
        let user_root_id = Feature.GetUserRootId(root_childs[i], user_depts, childs);
        if(user_root_id == 0) continue;
        user_roots.push(user_root_id);
    }    
    res["user_root"] = user_roots;
    return res;
}
Feature.GetUserRootId = (dept_root_id, user_depts, childs) => {
    let user_root_id = 0;
    let queues = [dept_root_id];
    let tmpChilds = {...childs};
    let loop = 0;
    let maxLoop = 100;
    while(user_root_id == 0 && queues.length > 0 && loop <= maxLoop) {
        loop++;
        let el = queues[0];
        if(el in user_depts) {
            user_root_id = user_depts[el][0];
            break;
        }
        if(el in tmpChilds && tmpChilds[el].length > 0) {
            let first_elem = parseInt(tmpChilds[el][0]);
            queues.unshift(first_elem);
            const tmp = tmpChilds[el].filter(item => item !== first_elem);
            tmpChilds[el] = tmp;
        }
    }
    return user_root_id;
}
Feature.SmallBoxChart = (data, addon) => {
    let exclude_top_level = false;
    let level = 0;
    let elem_id = "";
    let count_child_dept = 0;
    let is_hide_division = false;
    let user_id = "";
    if(addon != undefined && !Func.IsEmptyObject(addon)) {
        if('exclude_top_level' in addon) {
            exclude_top_level = addon["exclude_top_level"];
        }
        if('level' in addon) {
            level = addon["level"];
        }
        if('elem_id' in addon) {
            elem_id = addon["elem_id"];
        }
        if('count_child_dept' in addon) {
            count_child_dept = addon["count_child_dept"];
        }
        if('is_hide_division' in addon) {
            is_hide_division = addon["is_hide_division"];
        }
        if('user_id' in addon) {
            user_id = addon["user_id"];
        }
    }
    let is_org = ('is_org' in data) ? data["is_org"] : false;
    let level_id = ('level_id' in data) ? data["level_id"] : 0;
    let parent = ('parent' in data) ? data["parent"] : 0;
    let elem_type = is_org ? 'org' : 'dept';
    //console.log('is_hide_division=' + is_hide_division);
    //console.log(data);
    let div_class = " level div-dept";
    if('deleted' in data && data["deleted"] == "Y") {
        div_class += " div-dept-deleted";
    }
    else if('display_status' in data && data["display_status"] == "N") {
        div_class += " div-dept-display_status";
    }
    let btn_add = '<a class="btn-add btn-floating btn-small" dept-id=' + elem_id + '>';
    btn_add += '<i class="material-icons icon-add">more_horiz</i>';
    btn_add += '</a>';

    let boxContent = [];
    boxContent.push(`<div class="level-` + level + div_class + `">`);
    boxContent.push(`<span style="">` + data.name + btn_add + `</span>`);
    // Btn edit
    let editType = "organization";
    if(!is_org) {
        editType = (level_id > 1) ? "division" : "department";
    }
    let btn = [];
    btn.push('<a class="btn btn-small btn-edit btn-setting" elem-type=' + elem_type + ' elem-id=' + elem_id + ' edit-type="' + editType + '">');
    btn.push('Edit');
    btn.push('</a>');
    boxContent.push(btn.join(''));
    // Btn add organization
    if(is_org && (parent == 1 || parent == null)){
        btn = [];
        btn.push('<a class="btn btn-small btn-add-org btn-setting" elem-id=' + elem_id + ' elem-type=' + elem_type + ' add-type="organization">');
        btn.push('Add Organization');
        btn.push('</a>');
        boxContent.push(btn.join(''));
    }
    // Btn add branch
    if(is_org && parent > 1){
        btn = [];
        btn.push('<a class="btn btn-small btn-add-branch btn-setting" elem-id=' + elem_id + ' elem-type=' + elem_type + ' add-type="branch">');
        btn.push('Add Branch');
        btn.push('</a>');
        boxContent.push(btn.join(''));
    }
    // Btn add department
    if((!is_org && level_id == 0) || (is_org && parent > 1)){
        btn = [];
        btn.push('<a class="btn btn-small btn-add-dept btn-setting" elem-id=' + elem_id + ' elem-type=' + elem_type + ' add-type="department">');
        btn.push('Add Department');
        btn.push('</a>');
        boxContent.push(btn.join(''));
    }
    // Btn add division
    if(!is_org && level_id > 0) {
        btn = [];
        btn.push('<a class="btn btn-small btn-add-division btn-setting" elem-id=' + elem_id + ' elem-type=' + elem_type + ' add-type="division">');
        btn.push('Add Division');
        btn.push('</a>');
        boxContent.push(btn.join(''));
    }
    boxContent.push(`</div>`);

    return boxContent.join('');
}