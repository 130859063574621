import React, { useState, useEffect } from "react";
import {
  Select,
  TextInput,
  Icon,
  Button,
  Checkbox,
  Table,
  Dropdown,
} from "react-materialize";
//import $ from 'jquery';
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import TrHead from "./main.content.trhead";
import TrContent from "./main.content.trcontent";
import SelectLimit from "./main.content.select.limit";
import SelectPage from "./main.content.select.page";
import ModalView from "./modal.view";
import ModalEdit from "./modal.edit";
import ModalCreate from "./modal.create";
//import ModalCancel from "./modal.cancel";

export default function MainContent({ userID }) {
  const [filters, setFilters] = useState({ limit: 10, page: 1 });
  const defaultData = {
    requests: [],
    requests_total: 0,
    total_page: 0,
    active_page: 1,
    status: "",
    err: 0,
    err_msg: "",
  };
  const [reqData, setReqData] = useState(defaultData);

  useEffect(() => {
    setReqData({ status: "processing" });
    const mainFunc = async () =>
      await API.post("user/leave/request", {
        params: encryptJsonObject({
          userID: userID,
          filters: filters,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setReqData(decrypt);
        } else {
          setReqData({
            status: "",
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        //console.log(`Failes : ${err}`);
        setReqData({ status: "", err: 1, err_msg: err });
      });
  }, [filters]);

  let content = "";
  if ("err" in reqData && reqData.err === 1) {
    content = (
      <div className="text-error">Fetch request error: {reqData.err_msg}</div>
    );
  } else if (reqData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "50px",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("Requests", reqData);
    content = (
      <div id="table-content">
        <div id="table-total">
          Total : <span id="table-total__number">{reqData.requests_total}</span>
        </div>
        <Table className="highlight" id="table-leave-summary">
          <thead key="thead">
            <TrHead />
          </thead>
          <tbody key="tbody">
            <TrContent data={reqData.requests} />
          </tbody>
        </Table>
        <SelectPage
          totalPage={reqData.total_page}
          activePage={reqData.active_page}
          setFilters={setFilters}
          setReqData={setReqData}
        />
      </div>
    );
  }
  return (
    <div id="main-content">
      <div className="row title">
        Your leave summary
        <SelectLimit
          limit={filters.limit}
          setFilters={setFilters}
          setReqData={setReqData}
        />
      </div>
      {content}
      <ModalView />
      <ModalEdit setFilters={setFilters} setReqData={setReqData} />
      <ModalCreate setFilters={setFilters} setReqData={setReqData} />
    </div>
  );
}
