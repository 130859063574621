import React from "react";
import { Select } from 'react-materialize';
import $ from 'jquery';
import Html from "../../utils/Html";

export default function SelectLimit({limit, setFilters, setReqData}) {
    const handleEvents = {
        OnChange : function(evt) {
            const limit = $(evt.target).val();
            setFilters({
                limit: limit, 
                page: 1
            });
            setReqData({status: "", err: 0, err_msg: ""});
        }
    }

    let optionData = [];
    optionData.push({name: 10, value: 10});
    optionData.push({name: 20, value: 20});
    optionData.push({name: 30, value: 30});

    return <div key="btn-select-limit-row" id="btn-select-limit-row" style={{marginRight: "0px"}}>
        <Select 
            label="" 
            multiple={false} 
            id="select-limit-row" 
            name="limit-row" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={limit}
            onChange={handleEvents.OnChange}>
            <Html.Options data={optionData} addon={{key: "limit-row"}} />
        </Select>
    </div>
}
