import React, { useState, useEffect } from "react";
import { Button, Modal, Textarea, Select, TextInput } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import Func from "../../utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import BtnCreateSubmit from "./modal.create.btn.submit";

export default function ModalCreate({ setFilters, setReqData }) {
  const [itemData, setItemData] = useState({
    data: {},
    type: "",
    status: "",
    err: 0,
    err_msg: "",
  });
  let header =
    itemData.type == "request" ? "Request a compensation" : "Create leave";
  let content = "";
  let footer = "";
  let btns = [<div className="header">{header}</div>];
  let modalCSS = {};
  modalCSS.height = "80%";
  const handleEvents = {
    BtnSubmit: {
      OnComplete: function () {
        setItemData({ data: {}, type: "", status: "", err: 0, err_msg: "" });
        setReqData({ status: "", err: 0, err_msg: "" });
        setFilters({
          limit: $("#select-limit-row").val(),
          page: 1,
        });
      },
    },
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setItemData({ data: {}, type: "", status: "", err: 0, err_msg: "" });
    },
    OnSubmit: function (evt) {
      // Form Submit
      evt.preventDefault();
    },
  };
  useEffect(() => {
    $("#top-content").on("click", "#btn-request-compensation", function (evt) {
      setItemData({ type: "request", status: "processing" });
      const mainFunc = async () =>
        await API.post("user/leave/request/create", {
          params: encryptJsonObject({
            userID: window.$user_profile.idu,
            type: "request",
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setItemData(decrypt);
          } else {
            setItemData({
              data: {},
              type: "",
              status: "",
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          //console.log(`Failes : ${err}`);
          setItemData({ data: {}, type: "", status: "", err: 1, err_msg: err });
        });
    });
    $("#top-content").on("click", "#btn-create-new", function (evt) {
      setItemData({ type: "", status: "processing" });
      const mainFunc = async () =>
        await API.post("user/leave/request/create", {
          params: encryptJsonObject({
            userID: window.$user_profile.idu,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setItemData(decrypt);
          } else {
            setItemData({
              data: {},
              type: "",
              status: "",
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          //console.log(`Failes : ${err}`);
          setItemData({ data: {}, type: "", status: "", err: 1, err_msg: err });
        });
    });
  }, []);

  if (itemData.status == "") {
    return "";
  } else if ("err" in itemData && itemData.err == 1) {
    modalCSS.height = "170px";
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="text-error">Fetch request error: {itemData.err_msg}</div>
    );
  } else if (itemData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("Create", itemData);
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="modal-content-outer">
        <div key="modal-content-inner" className="modal-content-inner">
          <form
            key="form-create-new"
            id="form-create-new"
            method="post"
            onSubmit={handleEvents.OnSubmit}
          >
            <Feature.Content data={itemData.data} type={itemData.type} />
          </form>
        </div>
        <div key="modal-loading" className="modal-loading hide">
          <Html.Preloading
            addon={{
              css: {
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
              },
            }}
          />
        </div>
      </div>
    );
    footer = (
      <div className="modal-footer-bottom">
        <BtnCreateSubmit events={handleEvents.BtnSubmit} header={header} />
      </div>
    );
  }

  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={false}
      className="modal-user modal-user-footer"
      id="modal-user-create"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.OnClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
      {footer}
    </Modal>
  );
}

const Feature = {};
Feature.Content = function ({ data, type }) {
  const handleEvents = {
    InpDuration: {
      OnChange: function (evt) {
        const duration = $(evt.target).val();
        if (duration == "MD") {
          $("#inp-req-enddate").removeAttr("disabled");
          $("#inp-req-starttime").removeAttr("disabled");
          $("#inp-req-endtime").removeAttr("disabled");
          return;
        }
        const starttime = data.working_time["start_time"];
        const endtime = data.working_time["end_time"];
        $("#inp-req-enddate").attr("disabled", true);
        //$("#inp-req-starttime").removeClass("hide");
        //$("#inp-req-endtime").removeClass("hide");
        $("#inp-req-starttime").val(starttime).attr("value", starttime);
        $("#inp-req-endtime").attr("disabled", true);
        if (duration == "FD") {
          //$("#inp-req-starttime").attr("disabled", true);
          $("#inp-req-endtime").val(endtime).attr("value", endtime);
          return;
        }
        let hour = "12";
        if (duration == "1H") {
          hour = "09";
        } else if (duration == "2H") {
          hour = "10";
        } else if (duration == "3H") {
          hour = "11";
        }
        $("#inp-req-endtime")
          .val(hour + ":30")
          .attr("value", hour + ":30");
        //$("#inp-req-starttime").removeAttr("disabled");
      },
    },
    InpStartDate: {
      OnChange: function (evt) {
        let startdate = $(evt.target).val();
        $("#inp-req-enddate").val(startdate).attr("value", startdate);
      },
    },
    InpStartTime: {
      OnChange: function (evt) {
        let duration = $("#inp-req-duration").val();
        const starttime_def = data.working_time["start_time"];
        const starttime_limit = "06:00";
        const endtime_limit = data.working_time["end_time_limit"];
        let starttime = $(evt.target).val().toString();
        let endtime = Func.GetEndTime(starttime, duration);
        if (starttime < starttime_limit || endtime > endtime_limit) {
          starttime = starttime_def;
          endtime = Func.GetEndTime(starttime, duration);
          $("#inp-req-starttime").val(starttime).attr("value", starttime);
        }
        $("#inp-req-endtime").val(endtime).attr("value", endtime);
      },
    },
    InpLeaveType: {
      OnChange: function (evt) {
        const leaveType = $(evt.target).val();
        let elem = $("#content-req-user .error");
        elem.addClass("hide");
        if (leaveType == "CL" || leaveType == "SL") {
          const searchObj = Func.SearchObject(
            data.leave_type,
            leaveType,
            "code"
          );
          const desc = Func.GetArrayColumn(searchObj, "description");
          elem.text(desc);
          elem.removeClass("hide");
        }
        let elemUpload = $("#content-req-upload");
        if (leaveType == "SL") {
          elemUpload.find("input[type=file]").val("");
          elemUpload.find("input[type=text]").val("");
          elemUpload.removeClass("hide");
        } else if (!elemUpload.hasClass("hide")) {
          elemUpload.find("input[type=file]").val("");
          elemUpload.find("input[type=text]").val("");
          elemUpload.addClass("hide");
        }
      },
    },
  };
  let content = [];
  let userPhoto = "";
  if (data.photo != "") {
    userPhoto = (
      <div
        className="image-circle"
        style={{
          background: "url('" + data.photo + "') no-repeat center",
          backgroundSize: "cover",
        }}
      />
    );
  } else {
    userPhoto = (
      <div className="image-circle">
        <i className="material-icons">person</i>
      </div>
    );
  }
  const curDate = Func.GetCurrentDate();
  content.push(
    <div
      key="content-req-user"
      className="row content-req"
      id="content-req-user"
    >
      <strong className="col s4 title">{userPhoto}</strong>
      <div className="col s8 desc">
        <span className="name">{data.full_name}</span>
        <span className={"error" + (type == "request" ? "" : " hide")}>
          {type == "request" ? data.leave_type[0]["description"] : ""}
        </span>
      </div>
    </div>
  );
  content.push(
    <div key="content-req-leavetype" className="row content-req">
      <strong className="col s4 inp-title">Leave Type</strong>
      <div className="col s8 desc">
        <Select
          multiple={false}
          id={"inp-req-leavetype"}
          name="leavetype"
          options={{
            classes: "inp-req",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value="AL"
          onChange={handleEvents.InpLeaveType.OnChange}
        >
          <Html.Options
            data={data.leave_type}
            addon={{ key: "name", value: "code" }}
          />
        </Select>
      </div>
    </div>
  );
  content.push(
    <div
      key="content-req-reason"
      className="row content-req"
      id="content-req-reason"
    >
      <strong className="col s4 inp-title">Reason</strong>
      <div className="col s8 desc">
        <Textarea className="inp-req" id="inp-req-reason" name="reason" />
      </div>
    </div>
  );
  content.push(
    <div key="content-req-duration" className="row content-req">
      <strong className="col s4 inp-title">Duration</strong>
      <div className="col s8 desc">
        <Select
          multiple={false}
          id={"inp-req-duration"}
          name="duration"
          options={{
            classes: "inp-req",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value="FD"
          onChange={handleEvents.InpDuration.OnChange}
        >
          <Html.Options
            data={data.leave_duration}
            addon={{ key: "name", value: "code" }}
          />
        </Select>
      </div>
    </div>
  );
  content.push(
    <div key="content-req-from" className="row content-req">
      <strong className="col s4 inp-title">From</strong>
      <div className="col s5 desc-1">
        <TextInput
          className="inp-req"
          id="inp-req-startdate"
          name="startdate"
          type="date"
          defaultValue={curDate}
          onChange={handleEvents.InpStartDate.OnChange}
        />
      </div>
      <div className="col s3 desc-2">
        <TextInput
          className="inp-req"
          id="inp-req-starttime"
          name="starttime"
          type="time"
          defaultValue={data.working_time["start_time"]}
          onChange={handleEvents.InpStartTime.OnChange}
        />
      </div>
    </div>
  );
  content.push(
    <div key="content-req-to" className="row content-req">
      <strong className="col s4 inp-title">To</strong>
      <div className="col s5 desc-1">
        <TextInput
          className="inp-req"
          id="inp-req-enddate"
          name="enddate"
          type="date"
          defaultValue={curDate}
          disabled
        />
      </div>
      <div className="col s3 desc-2">
        <TextInput
          className="inp-req"
          id="inp-req-endtime"
          name="endtime"
          type="time"
          defaultValue={data.working_time["end_time"]}
          disabled
        />
      </div>
    </div>
  );
  content.push(
    <div
      key="content-req-upload"
      className="row content-req hide"
      id="content-req-upload"
    >
      <strong className="col s4 inp-title">Upload</strong>
      <div className="col s8 desc">
        <div className="file-field input-field file-upload">
          <div className="btn btn-upload">
            <span>Upload</span>
            <input type="file" id="inp-req-upload" multiple />
          </div>
          <div className="file-path-wrapper">
            <input
              className="file-path validate"
              type="text"
              placeholder="Upload one or more files"
            />
          </div>
        </div>
      </div>
    </div>
  );
  content.push(
    <input
      key="content-req-countrycode"
      type="hidden"
      name="country_code"
      value={data.country_code}
    />
  );
  content.push(
    <input
      key="content-req-countryid"
      type="hidden"
      name="country_id"
      value={data.country_id}
    />
  );
  content.push(
    <input
      key="content-req-approverid"
      type="hidden"
      name="approver_id"
      value={data.approver_id}
    />
  );

  return <div>{content}</div>;
};
