import React from "react";
import { Button } from 'react-materialize';
import $ from 'jquery';
import Func from "../../utils/Func";
 
export default function Btn({defaultFilters, setFilters, setReqData}) {
    const handleEvents = {
        BtnClear: {
            OnClick : function(evt) {
                evt.preventDefault();
                let filters = defaultFilters;
                filters["limit"] = $("#select-limit-row").val();
                setFilters(filters);
                setReqData({status: "", err: 0, err_msg: ""});
            }
        },
        BtnSearch : {
            OnClick : function(evt) {
                evt.preventDefault();
                let arr = ["country", "start-date"];
                let isEmpty = false;
                for(let i in arr) {
                    let name = arr[i];
                    let value = $("#filter-inp-" + name).val();
                    const helper = $("#helper-text-" + name);
                    if(value.length == 0) {
                        if(!isEmpty) {
                            isEmpty = true;
                        }
                        helper.removeClass("hide");
                    }
                    else {
                        helper.addClass("hide");
                    }
                }
                if(isEmpty) {
                    return;
                }
                let filters = Func.GetDataForm($("#form-filter-request").serializeArray());
                filters["limit"] = $("#select-limit-row").val();
                filters["page"] = 1; 
                setFilters(filters);
                setReqData({status: "", err: 0, err_msg: ""});
            }
        }
    }

    return <div id="main-btn" className="row">
        <Button
            node="button"
            type="button"
            className="blue-grey lighten-5"
            id="btn-clear"
            onClick={handleEvents.BtnClear.OnClick}>
            Clear All
        </Button>
        <Button 
            node="button" 
            id="btn-search"
            onClick={handleEvents.BtnSearch.OnClick}>
            Search
        </Button>
    </div>
}