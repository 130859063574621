import React, { useState, useEffect } from "react";
import { Button, Modal } from 'react-materialize';
import $ from 'jquery';
import Html from "../../utils/Html";
import Func from "../../utils/Func";
import BtnCopySubmit from "./modal.copy.btn.submit";

export default function ModalCopy({year, setFilters, setReqData, setActionData}) {
    const [itemData, setItemData] = useState({data: {}, status: "", err: 0, err_msg: ""});
    let header = "Copy Annual Leave";
    let content = "";
    let footer = "";
    let btns = [
        <div className="header">
            {header}
        </div>
    ];
    let modalCSS = {};
    modalCSS.height = "300px";
    const handleEvents = {
        BtnConfirm : {
            OnComplete : function() {
                setItemData({data: {}, status: "", err: 0, err_msg: ""});
                setReqData({status: "", err: 0, err_msg: ""});
                let filterData = Func.GetDataForm($("#form-filter-request").serializeArray());
                filterData["limit"] = $("#select-limit-row").val();
                filterData["page"] = 1; 
                setFilters(filterData);
                setActionData({key: 0, items: [], checked: false, pages: []});
            }
        },
        OnClose : function() {
            $("body").css({"overflow": "auto"});
            setItemData({data: {}, status: "", err: 0, err_msg: ""});
        },
        OnSubmit : function(evt) {
            // Form Submit
            evt.preventDefault();
        } 
    }
    useEffect(() => {
        $("#main-content").on("click", "#btn-action-copy", function(evt){
            let items = $("#form-edit-assign").find("input[name=action_items]").val();
            items = (items == "") ? [] : JSON.parse(items);
            if(items.length == 0){
                setItemData({
                    data: {}, 
                    status: "error", 
                    err: 1, 
                    err_msg: "Please select staff!"
                });
                return;
            }
            setItemData({
                data: {},
                status: "success",
                err: 0,
                err_msg: ""
            });
        });
    }, []);

    if(itemData.status === "") {
        return "";
    }
    else if ('err' in itemData && itemData.err === 1) {
        modalCSS.height = "200px";
        btns.push(<Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>);
        content = <div className="text-error">Fetch request error: {itemData.err_msg}</div>;
    }
    else if (itemData.status !== "success") {
        content = <div>
            <Html.Preloading addon={{
                css: {
                    position: "absolute",
                    top: "30px",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    margin: "auto"
                }
            }} />
        </div>;
    }
    else {
        //console.log("Edit", itemData);
        btns.push(<Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>);
        content = <div className="modal-content-outer">
            <div key="modal-content-inner" className="modal-content-inner">
                <Feature.Content year={year} data={itemData.data} />
            </div>
            <div key="modal-loading" className="modal-loading hide">
                <Html.Preloading addon={{
                    css: {
                        position: "absolute",
                        top: "30px",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        margin: "auto"
                    }
                }} />
            </div>
        </div>
        footer = <div className="modal-footer-bottom">
            <span className="modal-error-text hide"></span>
            <BtnCopySubmit events={handleEvents.BtnConfirm} header={header} year={year} />
        </div>
    }

    return (<Modal
        actions={btns}
        bottomSheet={false}
        fixedFooter={false}
        className="modal-user modal-user-footer"
        id="modal-user-edit"
        open={true}
        options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: handleEvents.OnClose,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '5%'
        }}
        root={document.getElementById("root")}
        style={modalCSS}
        >
        {content}
        {footer}
    </Modal>);
}

const Feature = {};
Feature.Content = function({year, data}) {
    let content = [];
    content.push(<div key="content-assign_days" className="row" id="content-assign_days">
        <strong className="col s6 title">
            From Year
        </strong>
        <span className="col s6 desc" style={{lineHeight: "30px", height: "30px"}}>
            {year[0] - 1}
        </span>
        <span className="col s12" style={{color: "red", marginTop: "15px", fontSize: "14px"}}>
            Please be reminded that some users' annual leave might be missing if they don't exist in the previous year.
        </span>
    </div>);
    return <div>
        {content}
    </div>
}