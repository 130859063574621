import React, { useState, useEffect } from "react";
import {
  Select,
  TextInput,
  Icon,
  Button,
  Checkbox,
  Table,
  Modal,
} from "react-materialize";
import config from "../../config";
import API from "../../utils/API";
import Func from "../../utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Html from "./html";
import $ from "jquery";

export default function CreateItem({ data, setFilterData }) {
  //const [isOpen, setOpen] = useState(false);
  //const [isComplete, setComplete] = useState(false);
  let work_loca = window.$user_profile.work_loca;
  let employed_by = window.$user_profile.employed_by;
  let is_exo_group = window.$user_profile.is_exo_group;
  const [createItemData, setCreateItemData] = useState("");
  const filters = {
    country_name: is_exo_group == "yes" ? work_loca : employed_by,
  };

  useEffect(() => {
    $("#container").on("click", ".btn-open-modal", function () {
      const dataType = $(this).attr("data-type");
      if (dataType == "create-item") {
        setCreateItemData("open");
      }
    });
  }, []);

  const handleEvents = {
    onClose: function () {
      setCreateItemData("");
    },
    onSubmit: function (e) {
      e.preventDefault();
      const validCheck = checkValidate(
        $("#form-create-item").find(".validate")
      );
      if (validCheck) {
        return;
      }
      //console.log("Submit Created");
      let formData = Func.GetDataForm($("#form-create-item").serializeArray());
      formData["country_name"] = [
        data.countries[formData["country_id"][0]].country_name,
      ];
      formData["limit"] = $("#select-limit-row").val();
      modalEvents.loading.onShow();
      //onsole.log(formData);
      SubmitCreateEvent(formData);
    },
  };

  const SubmitCreateEvent = (eventData) => {
    const mainFunc = async () =>
      await API.post("holiday/create/item", {
        params: encryptJsonObject({
          forms: eventData,
          userID: window.$user_profile.idu,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success" && decrypt?.id) {
          modalEvents.loading.onHide();
          modalEvents.error.onHide();
          setCreateItemData("success");
          // Refresh
          let filters = Func.GetDataForm(
            $("#form-filter-search").serializeArray()
          );
          filters["country_id"] = [decrypt.country_id];
          filters["year"] = [decrypt.year];
          filters["page"] = [decrypt.page];
          filters["limit"] = [decrypt.limit];
          filters["holiday_name"] = [""];
          filters["is_edit_mode"] = $("#btn-edit-mode").attr("data-mode");
          filters["delete_items"] = [];
          filters["is_refresh"] = true;
          filters["key_form"] = Math.random();
          setFilterData(filters);
        } else {
          modalEvents.loading.onHide();
          modalEvents.error.onShow("Failed : " + decrypt?.err_msg);
        }
      })
      .catch((err) => {
        modalEvents.loading.onHide();
        modalEvents.error.onShow(`Failed : ${err}`);
      });
  };

  let header = "Create New Item";
  let content = "";
  let btns = [];
  let modalCSS = {};
  if (createItemData === "") {
    return "";
  } else if (createItemData === "success") {
    modalCSS.height = "225px";
    btns = [
      <Button flat modal="close" node="button" waves="red">
        Close
      </Button>,
    ];
    content = <div className="modal-done-text">Done</div>;
  } else {
    //console.log(data);
    modalCSS.height = "70%";
    btns = [
      <span className="modal-error-text hide">Error</span>,
      <Button
        className="blue darken-3"
        id="btn-submit-create-item"
        node="button"
        waves="green"
        onClick={handleEvents.onSubmit}
      >
        Create
      </Button>,
      <Button flat modal="close" node="button" waves="red">
        Close
      </Button>,
    ];
    content = [];
    content.push(
      <div key="modal-content-inner" className="modal-content-inner">
        <CreateForm data={data} filters={filters} />
      </div>
    );
    content.push(
      <div key="modal-loading" className="modal-loading hide">
        {Html.Preloading({
          css: {
            position: "absolute",
            top: "50px",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
          },
        })}
      </div>
    );
  }
  //console.log("create item");
  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={true}
      header={header}
      id="modal-create-item"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.onClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
    </Modal>
  );
}

const modalEvents = {
  loading: {
    onHide: function () {
      $(".modal-loading").addClass("hide");
      $(".modal-content-inner").removeClass("hide");
      $(".modal-footer").removeClass("hide");
    },
    onShow: function () {
      $(".modal-loading").removeClass("hide");
      $(".modal-content-inner").addClass("hide");
      $(".modal-footer").addClass("hide");
    },
  },
  error: {
    onHide: function () {
      $(".modal-error-text").text("").addClass("hide");
    },
    onShow: function (err) {
      $(".modal-error-text").text(err).removeClass("hide");
    },
  },
};

const checkValidate = (elems) => {
  let check = false;
  let count = elems.length;
  for (let i = 0; i < count; i++) {
    let value = $(elems[i]).val();
    let helper = $(elems[i]).parent().find(".helper-text");
    if (value != "") {
      $(helper).text("");
      continue;
    }
    let errText = $(helper).attr("data-error");
    $(helper).text(errText).css({ color: "red" });
    if (!check) {
      check = true;
    }
  }
  return check;
};

const FilterDetails = ({ filters, countryData, detailData }) => {
  const [filterData, setFilterData] = useState(filters);
  useEffect(() => {
    $("#create-filter-inp-country").off("change");
    $("#create-filter-inp-country").on("change", function (e) {
      setFilterData({
        country_id: e.target.value,
      });
    });
  }, []);
  const country_code =
    "country_id" in filterData
      ? countryData[filterData.country_id].country_code
      : "";
  if (country_code == "" || !(country_code in detailData)) {
    return [];
  }
  let html = [];
  for (let key in detailData[country_code]) {
    let item = detailData[country_code][key];
    if (item.name == "region") {
      let name = item.name;
      let options = JSON.parse(item.options);
      let default_value = JSON.parse(item.default_value);
      html.push(
        <div key={"create-filter-" + name} className="row">
          <strong className="col s4">{item.label}</strong>
          <div className="col s8">
            <Select
              multiple={true}
              id={"create-filter-inp-" + name}
              name={name}
              options={{
                classes: "",
                dropdownOptions: {
                  alignment: "left",
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  coverTrigger: true,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                },
              }}
              value={default_value}
            >
              {Html.Options(options, { key: name })}
            </Select>
          </div>
        </div>
      );
    }
  }
  return html;
};

const CreateForm = ({ data, filters }) => {
  if (!"countries" in data) {
    return;
  }
  let user_sess = window.$user_profile["user_sess"];
  //user_sess["userCanAccessAllCountries"] = false;
  let employed_by_code = window.$user_profile["employed_by_code"];
  let filter_country = data.countries;
  if (
    (!("userCanAccessAllCountries" in user_sess) ||
      !user_sess["userCanAccessAllCountries"]) &&
    employed_by_code != "GROUP"
  ) {
    filter_country = Func.SearchObject(
      data.countries,
      window.$user_profile["employed_by"],
      "country_name"
    );
  }
  let filter_country_id = "";
  let filter_country_code = "";
  if ("country_id" in filters) {
    filter_country_id = filters.country_id;
    filter_country_code = filter_country[filter_country_id]["country_code"];
  } else if ("country_name" in filters) {
    let filter_country_data = Func.SearchObject(
      filter_country,
      [filters.country_name],
      "country_name"
    );
    filter_country_id =
      Object.keys(filter_country_data).length > 0
        ? Object.keys(filter_country_data)[0]
        : "";
    filter_country_code = filter_country[filter_country_id]["country_code"];
  }
  let form = [];
  form.push(
    <div key="create-filter-country" className="row ">
      <strong className="col s4">Country</strong>
      <div className="col s8">
        <Select
          multiple={false}
          id="create-filter-inp-country"
          name="country_id"
          options={{
            classes: "",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value={filter_country_id}
          /*onChange={events.onChangedCountry}*/
        >
          {Html.Options(filter_country, {
            key: "country",
            name: "country_name",
            value: "id",
          })}
        </Select>
      </div>
    </div>
  );
  form.push(
    <div key="create-filter-name" className="row">
      <strong className="col s4">Holiday Name</strong>
      <div className="col s8">
        <TextInput
          id="create-filter-inp-name"
          name="holiday_name"
          error="Please input holiday name"
          validate
        />
      </div>
    </div>
  );
  form.push(
    <div key="create-filter-date" className="row">
      <strong className="col s4">Date</strong>
      <div className="col s8">
        <TextInput
          id="create-filter-inp-date"
          name="holiday_date"
          type="date"
          error="Please select date"
          validate
        />
      </div>
    </div>
  );
  form.push(
    <div key="create-filter-fixed_holiday" className="row">
      <strong className="col s4">Fixed Holiday</strong>
      <div className="col s8">
        <Select
          multiple={false}
          id="create-filter-inp-fixed_holiday"
          name="fixed_holiday"
          options={{
            classes: "",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value="N"
        >
          {Html.Options(
            [
              { name: "Yes", value: "Y" },
              { name: "No", value: "N" },
            ],
            { key: "fixed_holiday" }
          )}
        </Select>
      </div>
    </div>
  );
  form.push(
    <div key="create-filter-bank_holiday" className="row">
      <strong className="col s4">Bank Holiday</strong>
      <div className="col s8">
        <Select
          multiple={false}
          id="create-filter-inp-bank_holiday"
          name="bank_holiday"
          options={{
            classes: "",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value="N"
        >
          {Html.Options(
            [
              { name: "Yes", value: "Y" },
              { name: "No", value: "N" },
            ],
            { key: "bank_holiday" }
          )}
        </Select>
      </div>
    </div>
  );
  return (
    <form key="form-create-item" id="form-create-item" action="">
      {form}
      <FilterDetails
        filters={{ country_id: filter_country_id }}
        countryData={data.countries}
        detailData={data.details}
      />
    </form>
  );
};
