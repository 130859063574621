import React, { useState, useEffect } from "react";
import { Select, TextInput, Icon, Button, Checkbox, Dropdown, Pagination } from 'react-materialize';
import $, { htmlPrefilter } from 'jquery';
import config from "../../config";
import Func from "../../utils/Func";
import Handle from "./handle";

const Events = {
    HandleDeleteItemChanged : function(evt){
        let elem = $(evt.target);
        let checked = false;
        if(elem.is(":checked")){
            checked = true;
        }
        const formElem = elem.parents("form").first();
        const id = elem.val();
        let items = $(formElem).find("input[name=delete_items]").val();
        if(items == ""){
            items = [];
        }
        else{
            items = JSON.parse(items);
        }
        const pos = items.indexOf(id);
        //console.log(id + " pos=" + pos);
        if(checked){
            if(pos == -1){
                items.push(id);
            }
        }
        else{
            if(pos > -1){
                items.splice(pos, 1);
            }
        }
        $(formElem).find("input[name=delete_items]").val(JSON.stringify(items));
        //console.log("single", checked, evt.target.checked);
    }
}

export default {
    Paginations : function(totalPage, activePage, className, events){
        return (<Pagination
            activePage={activePage}
            items={totalPage}
            leftBtn={<Icon>chevron_left</Icon>}
            rightBtn={<Icon>chevron_right</Icon>}
            className={className}
            onSelect={events.onSelectPage}
            />
        );
    },
    BtnCreateNew : function(events){
        return <div id="btn-create-new">
            <Dropdown
            id="dropdown-create-new"
            options={{
                alignment: 'left',
                autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                container: null,
                coverTrigger: true,
                hover: false,
                inDuration: 150,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 250
            }}
            trigger={
                <Button className="blue darken-3" node="button" style={{width: "135px"}}>Create</Button>
            }>
            <span className="btn-open-modal" data-type="create-calendar" onClick={events.onCreateCalendar}>New Calendar</span>
            <span className="btn-open-modal" data-type="create-item" onClick={events.onCreateItem}>New Item</span>
            </Dropdown>
        </div>
    },
    TrHead : function(res, isEditMode, isDraft, events){
        let columns = ["Country", "Holiday Name", "Date"];
        if('details' in res){
            for(const i in res.details) {
                columns.push(res.details[i].label);
            }
        }
        let listItems = [];
        let index = 0;
        for(let i in columns){
            let name = columns[i].replace(" ", "-").toLowerCase();
            listItems.push(<th key={"row-0-col-" + (index++)} className={"th-" + name}>
                {columns[i]}
            </th>);
        }
        let thClass = "td-edit-mode" + ((isEditMode == 1) ? "" : " hide");
        listItems.push(<th key={"row-0-col-" + (index++)} className={thClass + " th-delete"}>Delete</th>);
        listItems.push(<th key={"row-0-col-" + (index++)} className={thClass + " th-bulk-action"}>
            <Dropdown
                id={"dropdown-bulk-action" + (isDraft ? "-draff" : "")}
                options={{
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    container: null,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }}
                trigger={
                <Button flat node="button" style={{float: "left", width: "110px", fontWeight: "bold", padding: "0px", whiteSpace: "nowrap", fontSize: "13px"}}>
                    <i className="material-icons right">arrow_drop_down</i>Bulk Actions
                </Button>
                }
            >
                <span className="btn-open-modal" data-type="delete-item"  onClick={events.DeleteOpenModal}>Delete</span>
            </Dropdown>
            <Checkbox
                checked={false}
                filledIn
                name="delete-all-item"
                label=""
                value="1"
                onChange={events.DeleteAllItemChanged}
            />
        </th>);
        
        return (<tr key="row-0">
            {listItems}
        </tr>)
    },
    TrContent : function(res, isEditMode, deleteData){
        let data = res.data;
        let details = ('details' in res) ? res.details : [];
        let delete_items = ('items' in deleteData) ? deleteData.items : [];
        //console.log("Delete Items", delete_items);
        let listItems = [];
        let tdClass = "td-edit-mode" + ((isEditMode == 1) ? "" : " hide");
        for (const i in data) {
            let row = data[i];
            //console.log("row=" + i, row);
            let td = [];
            let index = 0;
            let deleteChecked = (delete_items.indexOf((row.id).toString()) > -1) ? true : false;
            /*else if('checked' in deleteItems && deleteItems.checked != "") {
                deleteChecked = deleteItems.checked;
            }*/
            //console.log(row.id + " " + deleteChecked);
            td.push(this.TdContent({
                name: "country_name",
                value: row.country_name,
                index: i,
                indexCol: (index++)
            }, isEditMode));
            td.push(this.TdContent({
                name: "holiday_name",
                value: row.holiday_name,
                type: "text",
                index: i,
                indexCol: (index++),
                dataID: row.id
            }, isEditMode));
            td.push(this.TdContent({
                name: "holiday_date",
                value: row.calendar_date,
                type: "date",
                index: i,
                indexCol: (index++),
                dataID: row.id
            }, isEditMode));
            for(const j in details) {
                if(row.country_code != details[j].country_code){
                    td.push(<td key={"row-" + i + "-col-" + (index++)}></td>);
                    continue;
                }
                let detail_name = details[j].name;
                let values = [];
                if ('details' in row) {
                    values = row.details[detail_name];
                }
                let type = "";
                let options = [];
                if(detail_name == "region"){
                    type = details[j].type;
                    options = JSON.parse(details[j].options);
                }
                td.push(this.TdContent({
                    name: detail_name,
                    value: values,
                    index: i,
                    indexCol: (index++),
                    type: type,
                    options: options,
                    dataID: row.id
                }, isEditMode));
            }
            td.push(<td key={"row-" + i + "-col-" + (index++)} className={tdClass} style={{textAlign: "center"}}>
                <div className="btn-delete-single-item" data-id={row.id}>
                    <Icon>close</Icon>
                </div>
            </td>);
            td.push(<td key={"row-" + i + "-col-" + (index++)} className={tdClass} style={{textAlign: "center"}}>
                <Checkbox
                    key={"delete-item-" + row.id + "-" + deleteData.key}
                    key-number={deleteData.key}
                    id={"delete-item-" + row.id}
                    checked={deleteChecked}
                    filledIn
                    name="delete-item"
                    label=""
                    value={`${row.id}`}
                    onClick={Events.HandleDeleteItemChanged}
                />
            </td>);
            listItems.push(<tr key={"row-" + i}>
                {td}
            </tr>);
        }
        if(listItems.length == 0){
            let colspan = 3;
            if(details.length > 0){
                colspan += details.length;
            }
            if(isEditMode == 1){
                colspan += 2;
            } 
            listItems.push(<tr key="row-1">
                <td colSpan={colspan}>No Data</td>
            </tr>);
        }
        return listItems;
    },
    TdContent : function(res, isEditMode){
        let type = ('type' in res) ? res.type : "";
        let td = [];
        let spanClass = "";
        let inpClass = "";
        if(type !== ""){
            spanClass = "row-span" + (isEditMode == 1 ? " hide" : "");
            inpClass = "row-input" + (isEditMode == 1 ? "" : " hide");
        }
        let spanValue = (Array.isArray(res.value)) ? res.value.join(", ") : res.value;
        if(type == "date"){
            spanValue = Func.ConvertDateFormat(res.value);
        }
        td.push(<span key={"row-span-" + res.index + "-" + res.name} className={spanClass}>{spanValue}</span>);
        let input = "";
        if(type == "text"){
            input = <TextInput
                key={"row-input-" + res.name + "-" + res.dataID}
                id={"row-input-" + res.name + "-" + res.dataID}
                name={res.name + "--" + res.dataID} 
                defaultValue={res.value}
                onChange={Handle.inputForm.OnChange}
            />
        }
        else if(type == "date"){
            input = <TextInput
                key={"row-input-" + res.name + "-" + res.dataID}
                id={"row-input-" + res.name + "-" + res.dataID}
                name={res.name + "--" + res.dataID} 
                defaultValue={res.value}
                type="date"
                className={inpClass}
                onChange={Handle.inputForm.OnChange}
            />
        }
        else if(type == "multiselect"){
            input = <Select 
                key={"row-input-" + res.name + "-" + res.dataID}
                id={"row-input-" + res.name + "-" + res.dataID}
                name={res.name + "--" + res.dataID}
                label="" 
                multiple={true} 
                value={res.value}
                onChange={Handle.inputForm.OnChange}
            >
                {this.Options(res.options, {key: res.name})}
            </Select>
        }
        if(input !== ""){
            td.push(<div className={inpClass} key={"row-input-" + res.index + "-" + res.name}>
                {input}
            </div>);
        }
    
        return (<td key={"row-" + res.index + "-col-" + res.indexCol}>
            {td}
        </td>);
    },
    Options : function(data, addon){
        let keyName = addon.key;
        let columnName = ('name' in addon) ? addon.name : "name";
        let columnValue = ('value' in addon) ? addon.value : "value";
        let options = []
        for (const i in data) {
            let row = data[i];
            options.push(<option key={"option-" + keyName + "-" + i} value={(columnValue in row) ? row[columnValue] : row[columnName]}>{row[columnName]}</option>);
        }
        return options;
    },
    Preloading : function(addon){
        let addClass = ('class' in addon && addon.class != "" && addon.class != undefined) ? " " + addon.class : "";
        let addCSS = ('css' in addon && addon.css != "" && addon.css != undefined) ? addon.css : {};
        let loading = <div className={"preloader-wrapper active" + addClass} style={addCSS}>
            <div className="spinner-layer spinner-green-only">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div><div className="gap-patch">
                    <div className="circle"></div>
                </div><div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
        return loading;
    }
}