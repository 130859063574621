import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Handle from "../Container/modal.handle";
import ModalDone from "../Container/modal.done";
import ModalError from "../Container/modal.error";
import ModalWarning from "../Container/modal.warning";

export default function BtnRestoreTables({ header }) {
  const [resData, setResData] = useState({ status: "", err: 0, err_msg: "" });
  const callbackEvent = {
    OnClose: function () {
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnComplete: function () {
      setResData({ status: "", err: 0, err_msg: "" });
    },
  };
  const handleEvents = {
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setResData({ status: "", err: 0, err_msg: "" });
    },
    BtnSubmit: {
      OnClick: function (evt) {
        evt.preventDefault();
        setResData({ status: "inprocess" });
        const mainFunc = async () =>
          await API.post("setting/restore/leave", {
            params: encryptJsonObject({
              userID: window.$user_profile.idu,
            }),
          });
        mainFunc()
          .then((res) => {
            const decrypt = decryptWithApiResponse(res?.data);
            // console.log("response=", decrypt);
            if (decrypt?.status === "success") {
                setResData(decrypt);
            } else {
                setResData({
                err: 1,
                err_msg: `Failes : ` + decrypt?.err_msg ?? "",
              });
            }
          })
          .catch((err) => {
            setResData({ err: 1, err_msg: "" });
          });
      },
    },
  };
  //console.log("Create Submit", resData);
  if ("err" in resData && resData.err === 1) {
    return (
      <ModalWarning
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if ("err" in resData && resData.err === 2) {
    return (
      <ModalError
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if (resData.status === "inprocess") {
    return (
      <div
        style={{
          position: "relative",
          float: "left",
          height: "37px",
          width: "200px",
        }}
      >
        <Html.Preloading
          addon={{
            class: "small",
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else if (resData.status === "success") {
    return (
      <ModalDone
        header={header}
        content="The update is done!"
        callbackEvent={callbackEvent}
      />
    );
  }

  return (
    <Button
      key="btn-restore-tables"
      node="button"
      className=""
      id="btn-restore-tables"
      onClick={handleEvents.BtnSubmit.OnClick}
      style={{
        background: "#1565c0",
        float: "left",
        marginRight: "15px",
        marginTop: "15px",
      }}
    >
      2. {header}
    </Button>
  );
}
