import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select, Checkbox } from 'react-materialize';
import Html from "../../utils/Html";

export default function FilterDeleted({data, filters}) {
    data = [{name: "YES", id: "Y"}, {name: "NO", id: "N"}];
    const [deletedFilters, setDeletedFilters] = useState({
        data: filters.data, 
        key_form: filters.key_form, 
        checked: false,
        disable: filters.disable
    });
    let handleEvents = {
        OnChange : function(evt){
            
        }
    };
    useEffect(() => {
        
    }, []);
    //console.log("Filter Deleted");
    return <div key={"filter-deleted" + deletedFilters.key_form} className="filter-input-field select" id="filter-deleted">
        <Select 
            multiple={false} 
            key={"filter-inp-deleted" + deletedFilters.key_form}
            id="filter-inp-deleted" 
            name="deleted" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: false,
                    constrainWidth: true,
                    coverTrigger: false,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            defaultValue={deletedFilters.data}
            onChange={handleEvents.OnChange}
            disabled={deletedFilters.disable}
        >
            <Html.Options data={data} addon={{
                key: "deleted", 
                name: "name", 
                value: "id",
                title: "Deleted"
            }} />   
        </Select>
        <span className="select-helper-text hide" id="helper-text-deleted">Please select deleted</span>
    </div>
}