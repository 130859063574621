import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import UploadAPI from "../../utils/Upload.API";
import { decryptWithApiResponse } from "../../utils/encryption";
import Handle from "./modal.handle";
import ModalDone from "./modal.done";
import ModalError from "./modal.error";
import ModalWarning from "./modal.warning";

export default function BtnCreateSubmit({ events, header }) {
  const [resData, setResData] = useState({ status: "", err: 0, err_msg: "" });
  const callbackEvent = {
    OnClose: function () {
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnComplete: function () {
      events.OnComplete();
    },
  };
  const handleEvents = {
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnSubmit: function () {
      Handle.loading.OnShow();
      let elemForm = document.getElementById("form-create-new");
      let formData = new FormData(elemForm);
      var files = $("input[type=file]")[0].files;
      for (var i = 0; i < files.length; i++) {
        formData.append("images[]", files[i], files[i]["name"]);
      }
      // formData.append("starttime", $("input[name='starttime']").val());
      formData.append("enddate", $("input[name='enddate']").val());
      formData.append("endtime", $("input[name='endtime']").val());
      formData.append("userID", window.$user_profile.idu);
      // Log each form value to the console
      const mainFunc = async () =>
        await UploadAPI.post("user/leave/request/create/submit", formData);
      mainFunc()
        .then((res) => {
          Handle.loading.OnHide();
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setResData(decrypt);
          } else {
            setResData({
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Handle.loading.OnHide();
          setResData({ err: 1, err_msg: "" });
        });
    },
  };
  //console.log("Create Submit", resData);
  if ("err" in resData && resData.err === 1) {
    return (
      <ModalWarning
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if ("err" in resData && resData.err === 2) {
    return (
      <ModalError
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if (resData.status === "success") {
    return (
      <ModalDone
        header={header}
        content="Your request has been submitted!"
        callbackEvent={callbackEvent}
      />
    );
  }
  return (
    <Button
      id="btn-submit-create"
      className="btn-right-1"
      node="button"
      waves="light"
      onClick={handleEvents.OnSubmit}
    >
      Submit
    </Button>
  );
}
