import React, { useState, useEffect } from "react";
import { Table, Button } from 'react-materialize';
import $ from 'jquery';
import TrHead from './main.content.trhead';
import TrContent from './main.content.trcontent';

export default function TableContent({data}) {
    
    return <div id="table-content">
        <Table className="highlight" id="">
            <thead key="thead"> 
                <TrHead />    
            </thead>
            <tbody key="tbody">
                <TrContent 
                    data={data} />
            </tbody>
        </Table>
    </div>
}