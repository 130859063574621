import React from "react";
import { Button, Icon } from 'react-materialize';
import $ from 'jquery';
import Func from "../../utils/Func";
 
export default function Btn({setClearFilters, setFilters, defaultFilters}) {
    const handleEvents = {
        BtnViewUser : {
            OnClick : function(evt) {
                evt.preventDefault();
                $("#org-table").slideToggle("slow");
            }
        },
        BtnClear: {
            OnClick : function(evt) {
                evt.preventDefault();
                setFilters(defaultFilters);
                setClearFilters(Math.random());
            }
        },
        BtnSearch : {
            OnClick : function(evt) {
                evt.preventDefault();
                let filters = Func.GetDataForm($("#form-filter-request").serializeArray());
                filters["limit"] = $("#select-limit-row").val();
                filters["page"] = 1;
                setFilters(filters);
            }
        }
    };

    return <div id="main-btn">
        <Button
            id="btn-view-user"
            node="button"
            onClick={handleEvents.BtnViewUser.OnClick}
            >
            View users
            <Icon left>list</Icon>
        </Button>
        <Button
            node="button"
            type="button"
            className="blue-grey lighten-5"
            id="btn-clear"
            onClick={handleEvents.BtnClear.OnClick}>
            Clear All
        </Button>
        <Button 
            node="button" 
            id="btn-search"
            onClick={handleEvents.BtnSearch.OnClick}>
            Search
        </Button>
    </div>
}