import React from "react";

export default function BtnCancelChange({id}) {
    const style = {
        background: "#fb0000",
        borderRadius: "15px",
        lineHeight: "13px",
        textTransform: "none",
        width: "100%"
    }
    return <a className="btn-delete waves-effect waves-light btn-small" req-id={id} is-change-rq={1} style={style}>
        Cancel<br />Change RQ
    </a>
}