import React, { Component, useState, useEffect } from 'react';
import "./style.css";
import MainContent from "./main.content";
import Html from "../../utils/Html";

const TeamWaiting = (props) => {
    let userData = window.$user_profile;
    if (!('user_sess' in userData) || !userData["user_sess"].userCanAccessTeamView){
        return <Html.NoAccess />;
    }
    return (<div id="main-content">
        <MainContent userData={userData} />
    </div>);
}
export default TeamWaiting;
