import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Select, TextInput, Icon } from "react-materialize";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";

import RelateFilter from "./top.content.relate.filter";
import FilterOrganization from "./top.content.filter.organization";

export default function TopContent({ defaultFilters, key_form }) {
  let filters = defaultFilters;
  filters["key_form"] = key_form;
  filters["organization"] =
    "organization" in defaultFilters ? defaultFilters["organization"] : [];
  //const [orgFilters, setOrgFilters] = useState({data: filters["organization"], key_form: key_form});
  const [relateFilters, setRelateFilters] = useState({
    data: filters["organization"],
    key_form: key_form,
  });
  const [filterData, setFilterData] = useState({
    data: "",
    err: 0,
    err_msg: "",
    status: "",
  });
  useEffect(() => {
    //console.log("Effect => TopContent");
    const mainFunc = async () =>
      await API.post("org/filter", {
        params: encryptJsonObject({
          filters: filters,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setFilterData(decrypt);
        } else {
          setFilterData({
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        setFilterData({ err: 1, err_msg: `Failes : ${err}` });
      });
  }, [filters]);

  if (filterData.err == 1) {
    return <div>Fetch request error: {filterData.err_msg}</div>;
  } else if (filterData.data == "") {
    return (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  }
  //console.log("TopContent", filters);
  //let relateFilter = <RelateFilter filters={filters} data={filterData} />;
  //let relateFilter = <RelateFilter filters={relateFilters} data={filterData} />;
  return (
    <div id="top-content">
      <form
        key={"form-filter-request" + filters.key_form}
        id="form-filter-request"
        method="post"
      >
        <div className="filter-inputs row">
          <FilterOrganization
            data={filterData["data"]["organizations"]}
            filters={{
              data: filters["organization"],
              key_form: filters["key_form"],
            }}
          />
          <RelateFilter filters={relateFilters} data={filterData} />
          <Feature.FilterName />
        </div>
      </form>
    </div>
  );
}

const Feature = {};
Feature.FilterName = () => {
  return (
    <div key="filter-name" className="filter-input-field">
      <TextInput
        /*icon={<Icon>search</Icon>}*/
        placeholder=""
        id="filter-inp-name"
        name="name"
        label="Name, Email and Position"
      />
    </div>
  );
};
