import React, { useState, useEffect } from "react";
import { Button, Modal, Textarea } from "react-materialize";
import Html from "../../utils/Html";
import Func from "../../utils/Func";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import BtnSubmit from "./modal.edit.btn.submit";

export default function ModalEdit({ setFilters, setReqData, addon }) {
  let userData = window.$user_profile;
  const [itemData, setItemData] = useState({
    data: {},
    status: "",
    err: 0,
    err_msg: "",
  });
  let header = "Review Request";
  let content = "";
  let btns = [<div className="header">{header}</div>];
  let modalCSS = {};
  modalCSS.height = "85%";
  modalCSS.minWidth = "480px";
  modalCSS.maxWidth = "690px";
  modalCSS.maxHeight = "500px";
  const handleEvents = {
    BtnSubmit: {
      OnComplete: function () {
        setItemData({ data: {}, status: "", err: 0, err_msg: "" });
        setReqData({ status: "", err: 0, err_msg: "" });
        let filters = Func.GetDataForm(
          $("#form-filter-request").serializeArray()
        );
        filters["limit"] = $("#select-limit-row").val();
        filters["page"] = $("#select-limit-page").val();
        if (!Func.IsEmptyObject(addon)) {
          if ("status" in addon) {
            filters["status"] = addon["status"];
          }
        }
        setFilters(filters);
      },
    },
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setItemData({ data: {}, status: "", err: 0, err_msg: "" });
    },
  };
  useEffect(() => {
    $("#team-content").on(
      "click",
      ".btn-edit, .btn-check, .btn-delete",
      function (evt) {
        let action = "";
        if ($(this).hasClass("btn-check")) {
          action = "approve";
        } else if ($(this).hasClass("btn-delete")) {
          action = "reject";
        }
        setItemData({ status: "processing" });
        const reqId = $(this).attr("req-id");
        const mainFunc = async () =>
          await API.post("team/request/id", {
            params: encryptJsonObject({
              id: reqId,
              userID: userData.idu,
            }),
          });
        mainFunc()
          .then((res) => {
            const decrypt = decryptWithApiResponse(res?.data);
            // console.log("response=", decrypt);
            if (decrypt?.status === "success" && decrypt?.data) {
              decrypt.data.action = action;
              setItemData(decrypt);
            } else {
              setItemData({
                data: {},
                status: "",
                err: 1,
                err_msg: `Failes : ` + decrypt?.err_msg ?? "",
              });
            }
          })
          .catch((err) => {
            //console.log(`Failes : ${err}`);
            setItemData({ data: {}, status: "", err: 1, err_msg: err });
          });
      }
    );
  }, []);

  if (itemData.status == "") {
    return "";
  } else if ("err" in itemData && itemData.err == 1) {
    modalCSS.height = "170px";
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="text-error">Fetch request error: {itemData.err_msg}</div>
    );
  } else if (itemData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("View", itemData.data);
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="modal-content-outer">
        <div key="modal-content-inner" className="modal-content-inner row">
          <Feature.ContentLeft data={itemData.data} />
          <Feature.ContentRight
            data={itemData.data}
            handleEvents={handleEvents}
            header={header}
          />
          <Feature.ContentBottom data={itemData.data} />
        </div>
        <div key="modal-loading" className="modal-loading hide">
          <Html.Preloading
            addon={{
              css: {
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
              },
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={false}
      className="modal-user"
      id="modal-team-edit"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.OnClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
    </Modal>
  );
}

const Feature = {};
Feature.ContentBottom = function ({ data }) {
  if (data.teams.length == 0) {
    return "";
  }
  let teams = [];
  for (let i in data.teams) {
    let item = data.teams[i];
    teams.push(
      <div key={"team-sub-" + i} className="team-sub">
        <span className="name">{item.full_name}</span> takes{" "}
        <span className="days">{item.total_days}</span> leave from{" "}
        <span className="date">{item.full_start_date}</span>
      </div>
    );
  }

  return (
    <div id="modal-content-bottom" className="col s12">
      <span className="col s4 title">Team Leave Note</span>
      <div className="col s8">{teams}</div>
    </div>
  );
};
Feature.ContentRight = function ({ data, handleEvents, header }) {
  let content = [];
  content.push(
    <div key="content-right-leavedays" className="row">
      <div className="col sub-box">
        <Feature.SubBox
          type="balance"
          data={data.leave_days_balance}
          title="Days Available"
        />
        <Feature.SubBox
          type="used"
          data={data.leave_days_taken}
          title="Days Used"
        />
      </div>
    </div>
  );
  let btn = "";
  let placeholder = "";
  if (data.status_code == "PD" || data.status_code == "RQ") {
    placeholder =
      "Please enter your reason for approving or denying the request if there is any concern. Please leave it empty if there is none.";
    btn = (
      <BtnSubmit
        events={handleEvents.BtnSubmit}
        header={header}
        action={data.action}
      />
    );
  }
  /*else if(data.status_code == "AP" || data.status_code == "RJ") {
        btn = <Feature.BtnStatusApprove handleEvents={handleEvents} />
    }*/
  content.push(
    <div key="content-right-note" className="row">
      <form
        id="form-team-request-update"
        className="col s12"
        style={{ padding: "0", marginTop: "10px" }}
      >
        <span className="title">Note from Approver</span>
        <Textarea
          id="inp-req-note"
          req-id={data.id}
          name="note_from_approver"
          defaultValue={data.note_from_approver}
          placeholder={placeholder}
        />
        <input type="hidden" name="req-id" value={data.id} />
        <span className="modal-error-text hide"></span>
        {btn}
      </form>
    </div>
  );

  return (
    <div id="modal-content-right" className="col s5">
      {content}
    </div>
  );
};
Feature.ContentLeft = function ({ data }) {
  let content = [];
  let userPhoto = "";
  if (data.photo != "") {
    userPhoto = (
      <div
        className="image-circle"
        style={{
          background: "url('" + data.photo + "') no-repeat center",
          backgroundSize: "cover",
        }}
      />
    );
  } else {
    userPhoto = (
      <div className="image-circle">
        <i className="material-icons">person</i>
      </div>
    );
  }
  content.push(
    <div
      key="content-req-user"
      className="row content-req"
      id="content-req-user"
    >
      <strong className="col s4 title">{userPhoto}</strong>
      <div className="col s8 desc">{data.full_name}</div>
    </div>
  );
  content.push(
    <div key="content-req-leavetype" className="row content-req">
      <strong className="col s4 title">Leave Type</strong>
      <div className="col s8 desc">{data.leavetype_name}</div>
    </div>
  );
  content.push(
    <div key="content-req-reason" className="row content-req">
      <strong className="col s4 title">Reason</strong>
      <div className="col s8 desc">
        {data.reason != "" ? data.reason : "N/A"}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-duration" className="row content-req">
      <strong className="col s4 title">Duration</strong>
      <div className="col s8 desc">{data.duration_desc}</div>
    </div>
  );
  content.push(
    <div key="content-req-from" className="row content-req">
      <strong className="col s4 title">From</strong>
      <div className="col s8 desc">
        {data.full_start_date +
          (data.full_start_time != "" ? ", " + data.full_start_time : "")}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-to" className="row content-req">
      <strong className="col s4 title">To</strong>
      <div className="col s8 desc">
        {data.full_end_date +
          (data.full_end_time != "" ? ", " + data.full_end_time : "")}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-totaldays" className="row content-req">
      <strong className="col s4 title">Total</strong>
      <div className={"col s8 desc textcolor-green"}>
        {data.full_total_days}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-status" className="row content-req">
      <strong className="col s4 title">Status</strong>
      <div className={"col s8 desc " + data.status_code}>
        {data.status_desc}
      </div>
    </div>
  );
  if (data.leavetype_code == "SL" && "file" in data) {
    content.push(
      <div
        key="content-req-file"
        className="row content-req"
        id="content-req-file"
      >
        <strong className="col s4 title">File</strong>
        <div className={"col s8 desc"}>
          <a target="_blank" href={data["file"].url}>
            <img className="responsive-img" src={data["file"].url} />
          </a>
        </div>
      </div>
    );
  }

  return (
    <div id="modal-content-left" className="col s7">
      {content}
    </div>
  );
};
Feature.SubBox = ({ type, data, title }) => {
  let textDay = data.days + " day" + (data.days > 1 ? "s" : "");
  let textHour = "";
  if (data.hours > 0) {
    textHour = "(+" + data.hours + " hour" + (data.hours > 1 ? "s" : "") + ")";
  }
  return (
    <div key={"sub-box-" + type} id={"sub-box-" + type} className="col s6">
      <span className="title">{title}</span>
      <span className="text-day textcolor">{textDay}</span>
      <span className={"text-hour textcolor" + (textHour == "" ? " hide" : "")}>
        {textHour}
      </span>
    </div>
  );
};
Feature.BtnStatusPending = ({ handleEvents, action }) => {
  let content = [];
  if (action == "reject" || action == "") {
    content.push(
      <Button
        key="btn-cancel"
        node="button"
        type="button"
        className="grey lighten-3"
        id="btn-cancel"
        onClick={handleEvents.BtnCancel.OnClick}
      >
        Reject
      </Button>
    );
  }
  if (action == "approve" || action == "") {
    content.push(
      <Button
        key="btn-approve"
        node="button"
        className=""
        id="btn-approve"
        onClick={handleEvents.BtnApprove.OnClick}
      >
        Approve
      </Button>
    );
  }

  return (
    <div key="content-right-btn" className="col s12" id="content-right-btn">
      {content}
    </div>
  );
};
/*Feature.BtnStatusApprove = ({handleEvents}) => {
    return <div key="content-right-btn" className="col s12" id="content-right-btn">
        <Button 
            node="button" 
            className=""
            id="btn-approve"
            onClick={handleEvents.BtnEdit.OnClick}>
            Edit
        </Button>
    </div>
}*/
