import React, { Component, useState, useEffect } from "react";
import { Select, TextInput, Icon, Button, Checkbox, Table, Dropdown } from 'react-materialize';
import $ from 'jquery';
import config from "../../config";
import API from "../../utils/API";
import Func from "../../utils/Func";
import Html from "../../utils/Html";
import M from 'materialize-css';  



const DynamicSelect = ({itemData}) => {
    useEffect(() => {
      M.AutoInit();
    }, []);
    
    return (
      <div key="test">
        This is dynamic select element demo
        <div>
          <span>Select user</span> :
          <select 
            multiple
            id="filter-inp-country" 
            name="country_id">
            <Html.Options data={itemData.country} addon={{key: "country", name: "country_name", value: "id", selected: itemData.country_id}} />
          </select>
            
          
        </div>
      </div>
    );
}

export default DynamicSelect;