import $ from 'jquery';

const Handle = {
    loading: {
        OnHide: function(){
            $(".modal-loading").addClass("hide");
            $(".modal-content-inner").removeClass("hide");
            $(".modal-footer-bottom").removeClass("hide");
        },
        OnShow: function(){
            $(".modal-loading").removeClass("hide");
            $(".modal-content-inner").addClass("hide");
            $(".modal-footer-bottom").addClass("hide");
        }
    },
    error: {
        OnHide: function(){
            $(".modal-error-text").text("").addClass("hide");
        },
        OnShow: function(err){
            $(".modal-error-text").text(err).removeClass("hide");
        }
    },
    saveButton : {
        OnShow: function() {
            $(".btn-save").removeClass("hide");
            $(".save-loading").addClass("hide");
        },
        OnHide: function() {
            $(".btn-save").addClass("hide");
            $(".save-loading").addClass("hide");
        },
        OnProcessing: function() {
            $(".save-loading").removeClass("hide");
            $(".btn-save").addClass("hide");
        }
    }
}
export default Handle;