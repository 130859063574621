import React, { Component, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Content from "./content";
import "./style.css";

const Holiday = (props) => {
  const [itemData, setItemData] = useState({
    status: "processing",
    data: [],
    err_msg: "",
  });

  useEffect(() => {
    const mainFunc = async () => await API.post("holiday/filter", {
      params: encryptJsonObject({})
    });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setItemData(decrypt);
        } else {
          setItemData({
            status: "error",
            data: [],
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        setItemData({ status: "error", data: [], err_msg: `Failed : ${err}` });
      });
  }, []);

  if (itemData.status == "error") {
    return <div>Fetch request error: {itemData.err_msg}</div>;
  } else if (itemData.status == "processing") {
    return (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  }

  //console.log("HOLIDAY INDEX");
  return <Content data={itemData.data} />;
};
export default Holiday;
