import React, { useState, useEffect } from "react";
import { Button, Modal, Icon } from "react-materialize";
import Html from "../../utils/Html";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Function from "./function";

export default function ModalViewStaff({ userData }) {
  const userID = userData.idu;
  const [itemData, setItemData] = useState({
    data: {},
    status: "",
    err: 0,
    err_msg: "",
  });
  let header = "View Staff";
  let content = "";
  let btns = [<div className="header">{header}</div>];
  let modalCSS = {};
  modalCSS.height = "70%";
  const handleEvents = {
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setItemData({ data: {}, status: "", err: 0, err_msg: "" });
    },
    BtnSendMail: {
      OnClick: function (evt) {
        //evt.preventDefault();
        window.open(
          "mailto:nattanan@exotravel.com?subject=Subject&body=Body%20goes%20here"
        );
        let elem = evt.target;
        if (!$(evt.target).is(":button")) {
          elem = $(elem).parents("button").first();
        }
        let email = $(elem).attr("data-email");
        //console.log(email);
        //$(elem).attr('href', 'mailto:' + email + '?subject=hello');
        window.location.href = "mailto:" + email;
      },
    },
  };
  useEffect(() => {
    Function.ImagesLoad("org-view-staff", itemData.status, {});

    $("#org-content").off("click", ".staff");
    $("#org-content").on("click", ".staff", function (evt) {
      setItemData({ status: "processing" });
      const userId = $(this).attr("key");
      const mainFunc = async () =>
        await API.post("org/user/data", {
          params: encryptJsonObject({
            userID: userID,
            idu: userId,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setItemData(decrypt);
          } else {
            setItemData({
              data: {},
              status: "",
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          //console.log(`Failes : ${err}`);
          setItemData({ data: {}, status: "", err: 1, err_msg: err });
        });
    });

    $("#org-view-staff").off("click", ".btn-more-manages");
    $("#org-view-staff").on("click", ".btn-more-manages", function () {
      let isActive = false;
      if ($(this).hasClass("active")) {
        isActive = true;
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
      }
      let mainBox = $(this).parents(".box-manages").first();
      let boxs = $(mainBox).find(".box-small-staff");
      let n = boxs.length;
      for (let i = 0; i < n; i++) {
        if (i <= 4) {
          continue;
        }
        let box = boxs[i];
        if (!isActive) {
          $(box).removeClass("hide");
        } else {
          $(box).addClass("hide");
        }
      }
    });
  }, [itemData]);

  if (itemData.status === "") {
    return "";
  } else if ("err" in itemData && itemData.err === 1) {
    modalCSS.height = "170px";
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="text-error">Fetch request error: {itemData.err_msg}</div>
    );
  } else if (itemData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("View", itemData);
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div key="modal-content-inner" className="modal-content-inner">
        <Feature.Content data={itemData.data} handleEvents={handleEvents} />
      </div>
    );
  }

  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={false}
      className="modal-user"
      id="modal-view-staff"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.OnClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
    </Modal>
  );
}

const Feature = {};
Feature.Content = function ({ data, handleEvents }) {
  //console.log(data);
  let content = [];
  content.push(
    <div
      key="content-req-user"
      className="row content-req"
      id="content-req-user"
    >
      <strong className="col s4 title">
        {Function.ImageCircle(data.photo, data.idu)}
      </strong>
      <div className="col s8 desc">{data.fname + " " + data.lname}</div>
    </div>
  );
  content.push(
    <div key="content-req-country" className="row content-req">
      <strong className="col s4 title">Country</strong>
      <div className="col s8 desc">{data.work_loca}</div>
    </div>
  );
  content.push(
    <div key="content-req-position" className="row content-req">
      <strong className="col s4 title">Position</strong>
      <div className="col s8 desc">{data.position}</div>
    </div>
  );
  content.push(
    <div key="content-req-department" className="row content-req">
      <strong className="col s4 title">Department</strong>
      <div className="col s8 desc">
        {data.department == "" ? "-" : data.department}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-division" className="row content-req">
      <strong className="col s4 title">Division</strong>
      <div className="col s8 desc">
        {data.division == null ? "-" : data.division}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-managed-by" className="row content-req">
      <strong className="col s4 title">Managed By</strong>
      <div className="col s8 desc">{Function.StaffManagedBy(data)}</div>
    </div>
  );
  content.push(
    <div key="content-req-manages" className="row content-req">
      <strong className="col s4 title">Manages</strong>
      <div className="col s8 desc">{Function.StaffManages(data, 4)}</div>
    </div>
  );
  //content.push(<a href={`mailto:${data.email}?subject=SendMail&body=Description`}>EmailButton</a>);
  /*content.push(<div key="content-req-other" className="row content-req">
    <strong className="col s4 title"></strong>
    <div className="col s8 desc">   
        <Button 
        flat 
        node="button" 
        waves="" 
        className="btn-sendmail" 
        data-email={data.email}
        onClick={handleEvents.BtnSendMail.OnClick} >    
        <i className="material-icons">mail_outline</i>
    </Button>
    </div>
    </div>)*/

  return (
    <div key="org-view-staff" id="org-view-staff">
      {content}
    </div>
  );
};
