import React, { Component, useState, useEffect } from 'react';
import "./org.unit.css";
import $ from 'jquery';
import Html from "../../utils/Html";
import TopContent from "./top.content";
import MainContent from '../OrgUnit/main.content';

const OrgUnit = (props) => {
    //let { reqId } = useParams();
    let userData = window.$user_profile;
    //userData["user_sess"].isHR = true;
    //userData["user_sess"].isAdmin = false;
    //console.log("userData => ", userData);
    const [clearFilters, setClearFilters] = useState(0);
    useEffect(() => {
        $("#container").css({maxWidth: "none"});
    }, []);
    if (!('user_sess' in userData) || (!userData["user_sess"].isAdmin && !userData["user_sess"].isHR)){
        return <Html.NoAccess />;
    }
    let organization = [];
    if('user_sess' in userData && !userData["user_sess"].isAdmin) {
        organization = userData.user_org;
    }
    const defaultFilters = {
        organization: organization,
        key_form: 0
    };
    return (<div id="org-unit">
        <div id="org-content" className="inner-container">
            <TopContent defaultFilters={defaultFilters} key_form={clearFilters} />
            <MainContent userData={userData} 
                defaultFilters={defaultFilters}
                setClearFilters={setClearFilters} />
        </div>
    </div>)
}
export default OrgUnit;