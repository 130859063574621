import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import Container from "./components/Container";
import Holiday from "./components/Holiday";
import Calendar from "./components/Calendar";
import Team from "./components/Team";
import TeamWaiting from "./components/TeamWaiting";
import TeamActionApprove from "./components/TeamAction/approve";
import TeamActionReject from "./components/TeamAction/reject";
import Hr from "./components/Hr";
import HrAssign from "./components/HrAssign";
import Setting from "./components/Setting";
import Org from "./components/Org";
import OrgUnit from "./components/OrgUnit";
import API from "./utils/API";
import Func from "./utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "./utils/encryption";
window.$google_email = "";
window.$user_profile = {};

const VirtualPath = () => {
  const { googleProfile } = useAuth();
  const [userProfile, setUserProfile] = useState({});

  // Check login status on component mount
  useEffect(() => {
    if (googleProfile?.email !== "") {
      const mainFunc = async () =>
        await API.post("user", {
          params: encryptJsonObject({
            email: googleProfile?.email,
            path: window.location.pathname,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("user=", decrypt);
          if (decrypt?.status === "success" && decrypt?.idu) {
            setUserProfile(decrypt);
          } else {
            setUserProfile({
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          //console.log(err);
          setUserProfile({ err: 1, err_msg: `Failes : ${err}` });
        });
    }
  }, [googleProfile?.email]);

  if (googleProfile?.email === "" && googleProfile?.err === 0) {
    return VirtualPathFunc.HtmlPageLoading();
  }
  if (googleProfile?.err === 1) {
    return VirtualPathFunc.HtmlPageError(googleProfile.err_msg);
  }
  if (userProfile?.err === 1) {
    return VirtualPathFunc.HtmlPageError(userProfile.err_msg);
  }
  window.$google_email = googleProfile.email;
  if (!Func.IsEmptyObject(userProfile)) {
    window.$user_profile = userProfile;
    if (
      userProfile?.emailadd !== process.env.REACT_APP_ADMIN_USER_EMAIL &&
      process.env.REACT_APP_SYSTEM_CLOSED === "true"
    ) {
      return (
        <div id="container" className="container" style={{ width: "99%" }}>
          <div
            style={{
              textAlign: "center",
              fontSize: "2rem",
              color: "#333",
              marginTop: "0%",
              fontFamily: "Arial, sans-serif",
            }}
          >
            <p>
              We regret to inform you that our system is temporarily
              unavailable.
            </p>
            <p>
              We will resume operations on <strong>Monday</strong>.
            </p>
            <p>
              We apologize for any inconvenience and appreciate your
              understanding.
            </p>
          </div>
        </div>
      );
    }
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/hrms/holiday/" component={Holiday} />
            <Route exact path="/hrms/calendar/" component={Calendar} />
            <Route exact path="/hrms/team/waiting/" component={TeamWaiting} />
            <Route
              exact
              path="/hrms/team/approve/:reqId"
              component={TeamActionApprove}
            />
            <Route
              exact
              path="/hrms/team/reject/:reqId"
              component={TeamActionReject}
            />
            <Route exact path="/hrms/team/" component={Team} />
            <Route exact path="/hrms/hr/assign/" component={HrAssign} />
            <Route exact path="/hrms/hr/" component={Hr} />
            <Route exact path="/hrms/setting/" component={Setting} />
            <Route exact path="/hrms/org/" component={Org} />
            <Route exact path="/hrms/org/unit/" component={OrgUnit} />
            <Route exact path={["/hrms", "/hrms/"]} component={Container} />
          </Switch>
        </Router>
      </div>
    );
  }
  return VirtualPathFunc.HtmlPageLoading();
};
const VirtualPathFunc = {
  HtmlPageError(err_msg) {
    let html = (
      <div className="container">
        <h5>Error : {err_msg}</h5>
      </div>
    );
    return html;
  },
  HtmlPageLoading() {
    let html = (
      <div className="container">
        <div className="row App-loading-wrapper">
          <div className="App-loading-item">
            <span>Waiting...</span>
            <div className="progress">
              <div className="indeterminate"></div>
            </div>
          </div>
        </div>
      </div>
    );
    return html;
  },
};

export { VirtualPath, VirtualPathFunc };
