import React from 'react';
import { TextInput, Button, Icon } from 'react-materialize';
import $ from 'jquery';
import FilterOrganization from "./filter.organization";
import FilterDepartment from "./filter.department";
import FilterStatus from "./filter.status";
import FilterOrgLevel from "./filter.orglevel";
import FilterDeleted from "./filter.deleted";

export default {
    GetContentModalOrg(data, type, handleEvents, addon) {
        let isEdit = false;
        if(addon != undefined) {
            if('isEdit' in addon) {
                isEdit = addon["isEdit"];
            }
        }
        let content = [];
        content.push(<div key="content-req-name" className="row content-req">
            <strong className="col s4 inp-title">Name</strong>
            <div className="col s8 desc">
                <TextInput
                    className="inp-req"
                    id="inp-req-name" 
                    name="name"
                    type="text"
                    defaultValue={('name' in data) ? data["name"] : ""}
                />
            </div>
        </div>);
        content.push(<div key="content-req-organization" className="row content-req">
            <strong className="col s4 inp-title">Organization</strong>
            <div className="col s8 desc">
                <FilterOrganization 
                    data={data["organizations"]} 
                        filters={{
                            data: [data["parent_id"]],
                            disable: true,
                            key_form: 0
                    }} />
            </div>
        </div>);
        let orglevelFilterData = "";
        if('org_level' in data) {
            orglevelFilterData = data["org_level"];
        }
        else if(type == "organization") {
            orglevelFilterData = "Geographical";
        }
        else {
            orglevelFilterData = "City";
        }
        content.push(<div key="content-req-org_level" className="row content-req">
            <strong className="col s4 inp-title">Level</strong>
            <div className="col s8 desc">
                <FilterOrgLevel 
                    data={[]} 
                    filters={{
                        data: orglevelFilterData,
                        disable: false,
                        isBranch: (type == "branch") ? true : false,
                        key_form: 0
                    }} />
            </div>
        </div>);
        content.push(<div key="content-req-country" className="row content-req">
            <strong className="col s4 inp-title">Country Code</strong>
            <div className="col s8 desc">
                <TextInput
                    className="inp-req"
                    id="inp-req-country_code" 
                    name="country_code"
                    type="text"
                    defaultValue={String(data["country_code"])}
                    disabled={(type == "branch" || isEdit) ? true : false}
                />
            </div>
        </div>);
        content.push(<div key="content-req-status" className="row content-req">
            <strong className="col s4 inp-title">Display Status</strong>
            <div className="col s8 desc">
                <FilterStatus 
                    data={[]} 
                        filters={{
                            data: ('display_status' in data) ? data["display_status"] : "Y",
                            disable: false,
                            key_form: 0
                    }} />
            </div>
        </div>);
        if(isEdit) {
            content.push(<div key="content-req-deleted" className="row content-req">
                <strong className="col s4 inp-title">Deleted</strong>
                <div className="col s8 desc">
                    <FilterDeleted 
                        data={[]} 
                            filters={{
                                data: ('deleted' in data) ? data["deleted"] : "N",
                                disable: false,
                                key_form: 0
                        }} />
                </div>
            </div>);
        }
        content.push(<input type="hidden" name="elem_id" value={data["elem_id"]} key="content-req-elem_id" />);
        content.push(<input type="hidden" name="elem_type" value={data["elem_type"]} key="content-req-elem_type" />);
        return <div key="modal-content-org" id="modal-content-org">
            {content}
        </div>
    },
    GetContentModalDept(data, type, handleEvents, addon) {
        let isEdit = false;
        if(addon != undefined) {
            if('isEdit' in addon) {
                isEdit = addon["isEdit"];
            }
        }
        let content = [];
        content.push(<div key="content-req-name" className="row content-req">
            <strong className="col s4 inp-title">Name</strong>
            <div className="col s8 desc">
                <TextInput
                    className="inp-req"
                    id="inp-req-name" 
                    name="name"
                    type="text"
                    defaultValue={('name' in data) ? data["name"] : ""}
                />
            </div>
        </div>);
        content.push(<div key="content-req-organization" className="row content-req">
            <strong className="col s4 inp-title">Organization</strong>
            <div className="col s8 desc">
                <FilterOrganization 
                    data={data["organizations"]} 
                        filters={{
                            data: [data["org_id"]],
                            disable: true,
                            key_form: 0
                    }} />
            </div>
        </div>);
        content.push(<div key="content-req-parent" className="row content-req">
            <strong className="col s4 inp-title">Parent</strong>
            <div className="col s8 desc">
                <FilterDepartment 
                    data={data["departments"]} 
                    filters={{
                        data: [data["parent_id"]],
                        disable: true,
                        key_form: 0
                    }} />
            </div>
        </div>);
        content.push(<div key="content-req-level" className="row content-req">
            <strong className="col s4 inp-title">Level</strong>
            <div className="col s8 desc">
                <TextInput
                    className="inp-req"
                    id="inp-req-level" 
                    name="level_id"
                    type="text"
                    defaultValue={String(data["level_id"])}
                    disabled
                />
            </div>
        </div>);
        content.push(<div key="content-req-status" className="row content-req">
            <strong className="col s4 inp-title">Display Status</strong>
            <div className="col s8 desc">
                <FilterStatus 
                    data={[]} 
                        filters={{
                            data: ('display_status' in data) ? data["display_status"] : "Y",
                            disable: false,
                            key_form: 0
                    }} />
            </div>
        </div>);
        if(isEdit) {
            content.push(<div key="content-req-deleted" className="row content-req">
                <strong className="col s4 inp-title">Deleted</strong>
                <div className="col s8 desc">
                    <FilterDeleted 
                        data={[]} 
                            filters={{
                                data: ('deleted' in data) ? data["deleted"] : "N",
                                disable: false,
                                key_form: 0
                        }} />
                </div>
            </div>);
        }
        content.push(<input type="hidden" name="elem_id" value={data["elem_id"]} key="content-req-elem_id" />);
        content.push(<input type="hidden" name="elem_type" value={data["elem_type"]} key="content-req-elem_type" />);
        return <div key="modal-content-dept" id="modal-content-dept">
            {content}
        </div>
    }
}