import React, { useState, useEffect } from "react";
import $, { htmlPrefilter } from "jquery";
import Func from "../../utils/Func";
import config from "../../config";
import API from "../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";

const Handle = {
  formEdit: {
    OnSubmit: function (countries, setFilterData) {
      let formData = Func.GetDataForm(
        $("#form-edit-content").serializeArray(),
        "save"
      );
      if (Object.keys(formData).length === 0) {
        return;
      }
      //console.log(formData);return;
      Handle.saveButton.OnProcessing();
      const mainFunc = async () =>
        await API.post("holiday/edit", {
          params: encryptJsonObject({
            forms: formData,
            userID: window.$user_profile.idu,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            Handle.saveButton.OnComplete();
          } else {
            Handle.saveButton.OnError(`Failed : ${decrypt?.err_msg}`);
          }
        })
        .catch((err) => {
          Handle.saveButton.OnError(`Failed : ${err}`);
        });
    },
  },
  inputForm: {
    OnChange: function (evt) {
      if (evt.which === 13) {
        return;
      }
      let elem = $(evt.target);
      if (!elem.hasClass("changed")) {
        elem.addClass("changed");
      }
      const formElem = elem.parents("form");
      if (formElem.length === 0) {
        return;
      }
      const formId = $(formElem[0]).attr("id");
      if (formId !== "form-edit-content") {
        return;
      }
      $("#btn-edit-mode").attr("data-changed", 1);
      Handle.saveButton.OnShow();
    },
  },
  saveButton: {
    OnShow: function () {
      $("#btn-save-content").removeClass("hide");
      $("#save-loading").addClass("hide");
    },
    OnHide: function () {
      $("#btn-save-content").addClass("hide");
      $("#save-loading").addClass("hide");
    },
    OnProcessing: function () {
      $("#save-loading").removeClass("hide");
      $("#btn-save-content").addClass("hide");
    },
    OnComplete: function () {
      $("#btn-edit-mode").attr("data-changed", 0);
      $("#btn-save-content").addClass("hide");
      $("#save-loading").addClass("hide");
      $("#save-status")
        .text("Changes Saved")
        .css({ color: "green" })
        .removeClass("hide");
      setTimeout(function () {
        $("#save-status").addClass("hide");
      }, 4000);
    },
    OnError: function (err) {
      $("#btn-save-content").removeClass("hide");
      $("#save-loading").addClass("hide");
      $("#save-status").text(err).css({ color: "red" }).removeClass("hide");
      setTimeout(function () {
        $("#save-status").addClass("hide");
      }, 4000);
    },
  },
  editModeButton: {
    OnShow: function () {
      $(".row-span").addClass("hide");
      $(".row-input").removeClass("hide");
      $(".td-edit-mode").removeClass("hide");
      $("#btn-edit-mode").css({ backgroundColor: "#4fc3f7" });
    },
    OnHide: function () {
      $(".row-span").removeClass("hide");
      $(".row-input").addClass("hide");
      $(".td-edit-mode").addClass("hide");
      $("#btn-edit-mode").css({ backgroundColor: "#1565c0" });
    },
    OnClick: function (evt) {
      evt.preventDefault();
      const elem = $(evt.target);
      let mode = elem.attr("data-mode");
      let changed = elem.attr("data-changed");
      let newMode = 0;
      if (mode == 0) {
        newMode = 1;
      }
      if (newMode == 1) {
        Handle.editModeButton.OnShow();
        if (changed == 1) {
          Handle.saveButton.OnShow();
        } else {
          Handle.saveButton.OnHide();
        }
      } else {
        //document.getElementById("btn-edit-mode").blur();
        Handle.editModeButton.OnHide();
        Handle.saveButton.OnHide();
      }
      elem.attr("data-mode", newMode);
    },
  },
  inputFilter: {
    OnChangeEndDate: function (evt) {
      let elem = $(evt.target);
      const startDate = $("#filter-inp-start-date").val();
      const maxEndDate = Func.GetNextMonth(startDate, 6);
      const endDate = elem.val();
      const d1 = new Date(maxEndDate);
      const d2 = new Date(endDate);
      const helper = $("#helper-text-end-date");
      if (d2 > d1) {
        //const text = $(helper).attr("data-error");
        //$(helper).text(text).css({color: "red", "text-align": "left", width: "160px"});
        helper.removeClass("hide");
        $("#filter-inp-end-date").val(maxEndDate).attr("value", maxEndDate);
      } else {
        helper.addClass("hide");
      }
    },
    OnChangeStartDate: function (evt) {
      let elem = $(evt.target);
      const startDate = elem.val();
      const endDate = Func.GetNextMonth(startDate, 6);
      $("#filter-inp-end-date").val(endDate).attr("value", endDate);
    },
  },
  loading: {
    GetIdName: function (parentName) {
      return parentName.replace("#", "").replace(".", "") + "__loading";
    },
    OnCreate: function (parentName, idName, addon) {
      let clone = $("#temp-loading").clone();
      clone.attr("id", idName);
      if ("style" in addon) {
        clone.css(addon.style);
      }
      $(parentName).append(clone);
    },
    OnShow: function (parentName, addon) {
      const idName = Handle.loading.GetIdName(parentName);
      let loadingElem = $("#" + idName);
      if (loadingElem.length == 0) {
        Handle.loading.OnCreate(parentName, idName, addon);
        loadingElem = $("#" + idName);
      }
      loadingElem.removeClass("hide");
      let childs = $(parentName)
        .children()
        .not("#" + idName);
      for (let i = 0; i < childs.length; i++) {
        $(childs[i]).addClass("hide");
      }
    },
    OnHide: function (parentName) {
      const idName = Handle.loading.GetIdName(parentName);
      let loadingElem = $("#" + idName);
      if (loadingElem.length == 0) {
        return;
      }
      loadingElem.remove();
      let childs = $(parentName)
        .children()
        .not("#" + idName);
      for (let i = 0; i < childs.length; i++) {
        $(childs[i]).removeClass("hide");
      }
    },
  },
};
export default Handle;
