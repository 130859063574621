import React, { useState } from "react";
import { Button, Modal } from 'react-materialize';
import $ from 'jquery';

export default function ModalError({header, content, callbackEvent}) {
    const [isOpen, setOpen] = useState(true);
    let btns = [
        <div className="header">
            {header}
        </div>,
        <Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>
    ];
    let modalCSS = {};
    modalCSS.height = "225px";
    let timeout = "";
    const handleEvents = {
        OnComplete : function() {
            timeout = setTimeout(function(){
                $("body").css({"overflow": "auto"});
                setOpen(false);
                if('OnClose' in callbackEvent) {
                    callbackEvent.OnClose();
                }
            }, 2500);
        },
        OnClose : function() {
            $("body").css({"overflow": "auto"});
            setOpen(false);
            if('OnClose' in callbackEvent) {
                callbackEvent.OnClose();
            }
            if(timeout != "") {
                clearTimeout(timeout);
            }
        }
    }

    if(!isOpen) {
        return "";
    }
    return (<Modal
        key="modal-error" 
        actions={btns}
        bottomSheet={false}
        fixedFooter={false}
        className="modal-user"
        id="modal-error"
        open={true}
        options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: handleEvents.OnClose,
            onOpenEnd: null,
            onOpenStart: handleEvents.OnComplete,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '5%'
        }}
        root={document.getElementById("root")}
        style={modalCSS}
        >
        <div id="modal-error-content">
            <a className="btn-floating waves-effect waves-light red">
                <i className="material-icons">close</i>
            </a>
            <div className="text" dangerouslySetInnerHTML={{__html: content}} />
        </div>
    </Modal>);
}