import React, { useState } from "react";
import { Button, Modal } from 'react-materialize';
import $ from 'jquery';
import Func from '../../utils/Func';

export default function ModalDone({header, content, callbackEvent, addon}) { 
    let setTime = 2500;
    let height = "225px";
    if(addon != undefined || addon != null) {
        setTime = ('setTime' in addon) ? addon.setTime : 2500;
        height = ('height' in addon) ? addon.height : "225px";
    }
    const [isOpen, setOpen] = useState(true);
    let btns = [
        <div className="header">
            {header}
        </div>,
        <Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>
    ];
    let modalCSS = {};
    modalCSS.height = height;
    let timeout = "";
    const handleEvents = {
        OnComplete : function() {
            if(setTime == "no") {
                return;
            }
            timeout = setTimeout(function(){
                $("body").css({"overflow": "auto"});
                setOpen(false);
                if('OnComplete' in callbackEvent) {
                    callbackEvent.OnComplete();
                }
            }, setTime);
        },
        OnClose : function() {
            $("body").css({"overflow": "auto"});
            setOpen(false);
            if('OnComplete' in callbackEvent) {
                callbackEvent.OnComplete();
            }
            if(timeout != "") {
                clearTimeout(timeout);
            }
        }
    }

    if(!isOpen) {
        return "";
    }
    return (<Modal
        key="modal-done"
        actions={btns}
        bottomSheet={false}
        fixedFooter={false}
        className="modal-user"
        id="modal-done"
        open={true}
        options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: handleEvents.OnClose,
            onOpenEnd: null,
            onOpenStart: handleEvents.OnComplete,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '5%'
        }}
        root={document.getElementById("root")}
        style={modalCSS}
        >
        <div id="modal-done-content">
            <a className="btn-floating waves-effect waves-light">
                <i className="material-icons">check</i>
            </a>
            <div className="text" dangerouslySetInnerHTML={{__html: content}} />
        </div>
    </Modal>);
}