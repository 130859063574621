import CryptoJS from "crypto-js";

export function checkIsEncrypt() {
    return process.env.REACT_APP_SERVERNAME === "live" ? true : false;
}

export function encryptData(data, password, isEncrypt) {
    if (password === undefined || password === null) {
      password = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    }

    if(isEncrypt === undefined) isEncrypt = checkIsEncrypt();
    if (!isEncrypt) return data; 

    // Generate a random salt (8 bytes)
    const salt = CryptoJS.lib.WordArray.random(8);

    // Derive key and IV using PBKDF2
    const keyAndIV = CryptoJS.PBKDF2(password, salt, {
        keySize: 48 / 4, // 32 bytes key + 16 bytes IV
        iterations: 1000,
    });

    // Split key and IV
    const derivedKey = CryptoJS.lib.WordArray.create(keyAndIV.words.slice(0, 32 / 4)); // 32 bytes key
    const iv = CryptoJS.lib.WordArray.create(keyAndIV.words.slice(32 / 4)); // 16 bytes IV

    // Encrypt the data using AES
    const encrypted = CryptoJS.AES.encrypt(data, derivedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    // Concatenate the salt and encrypted data
    const saltedCiphertext = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_SALT_PREFIX)
            .concat(salt)
            .concat(encrypted.ciphertext)
    );

    return saltedCiphertext;
}

export function encryptJsonObject(jsonObject, password, isEncrypt) {
    if (!password) {
        password = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    }

    // Convert JSON object to string
    const data = JSON.stringify(jsonObject);

    if(isEncrypt === undefined) isEncrypt = checkIsEncrypt();
    if (!isEncrypt) return data; 

    // Generate a random salt (8 bytes)
    const salt = CryptoJS.lib.WordArray.random(8);

    // Derive key and IV using PBKDF2
    const keyAndIV = CryptoJS.PBKDF2(password, salt, {
        keySize: 48 / 4, // 32 bytes key + 16 bytes IV
        iterations: 1000,
    });

    // Split key and IV
    const derivedKey = CryptoJS.lib.WordArray.create(keyAndIV.words.slice(0, 32 / 4)); // 32 bytes key
    const iv = CryptoJS.lib.WordArray.create(keyAndIV.words.slice(32 / 4)); // 16 bytes IV

    // Encrypt the data using AES
    const encrypted = CryptoJS.AES.encrypt(data, derivedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    // Concatenate the salt and encrypted data
    const saltedCiphertext = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_SALT_PREFIX)
            .concat(salt)
            .concat(encrypted.ciphertext)
    );

    return saltedCiphertext;
}

export function decryptData(encryptedData, password) {
    if (password === undefined || password === null) {
        password = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    }

    // Convert the Base64-encoded string back to a WordArray
    const saltedCiphertext = CryptoJS.enc.Base64.parse(encryptedData);

    // Extract the "Salted__" prefix (8 bytes) and the salt (8 bytes)
    // const saltedPrefix = saltedCiphertext.clone().words.slice(0, 2); // "Salted__" prefix
    const salt = CryptoJS.lib.WordArray.create(saltedCiphertext.words.slice(2, 4), 8); // Next 8 bytes for salt

    // Extract the actual ciphertext (remaining bytes after salt)
    const ciphertext = CryptoJS.lib.WordArray.create(saltedCiphertext.words.slice(4), saltedCiphertext.sigBytes - 16);

    // Derive key and IV using PBKDF2
    const keyAndIV = CryptoJS.PBKDF2(password, salt, {
        keySize: 48 / 4, // 32 bytes key + 16 bytes IV
        iterations: 1000,
    });

    // Split key and IV
    const derivedKey = CryptoJS.lib.WordArray.create(keyAndIV.words.slice(0, 32 / 4)); // 32 bytes key
    const iv = CryptoJS.lib.WordArray.create(keyAndIV.words.slice(32 / 4)); // 16 bytes IV

    // Decrypt the data using AES
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, derivedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    // Convert decrypted WordArray to a UTF-8 string
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function decryptWithApiResponse(encryptedData, password) {
    if (encryptedData !== undefined && checkIsEncrypt()) {
        return JSON.parse(decryptData(encryptedData, password));
    }
    return encryptedData;
}