import React, { useState, useEffect } from "react";
import { Button, Modal, Textarea, Select, TextInput } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import Func from "../../utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import BtnEditSubmit from "./modal.edit.btn.submit";

export default function ModalEdit({ setFilters, setReqData, userData }) {
  const [itemData, setItemData] = useState({
    data: {},
    status: "",
    err: 0,
    err_msg: "",
  });
  let header = "Edit leave";
  let content = "";
  let footer = "";
  let btns = [<div className="header">{header}</div>];
  let modalCSS = {};
  modalCSS.height = "70%";
  const handleEvents = {
    BtnEdit: {
      OnComplete: function () {
        setItemData({ data: {}, status: "", err: 0, err_msg: "" });
        setReqData({ status: "", err: 0, err_msg: "" });
        let filters = Func.GetDataForm(
          $("#form-filter-request").serializeArray()
        );
        filters["limit"] = $("#select-limit-row").val();
        filters["page"] = 1;
        setFilters(filters);
        /*setFilters({
                    country: [((userData.is_exo_group == "yes") ? "GROUP" : userData.employed_by_code)],
                    limit: $("#select-limit-row").val(), 
                    page: 1
                });*/
      },
    },
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setItemData({ data: {}, status: "", err: 0, err_msg: "" });
    },
    OnSubmit: function (evt) {
      // Form Submit
      evt.preventDefault();
    },
  };
  useEffect(() => {
    $("#main-content").on("click", ".btn-edit", function (evt) {
      setItemData({ status: "processing" });
      const reqId = $(this).attr("req-id");
      const mainFunc = async () => await API.post("hr/request/edit", {
        params: encryptJsonObject({
          id: reqId,
        })
      });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setItemData(decrypt);
          } else {
            setItemData({
              data: {},
              status: "",
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          //console.log(`Failes : ${err}`);
          setItemData({ data: {}, status: "", err: 1, err_msg: err });
        });
    });
  }, []);

  if (itemData.status == "") {
    return "";
  } else if ("err" in itemData && itemData.err == 1) {
    modalCSS.height = "170px";
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="text-error">Fetch request error: {itemData.err_msg}</div>
    );
  } else if (itemData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("Edit", itemData);
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="modal-content-outer">
        <div key="modal-content-inner" className="modal-content-inner">
          <form
            key="form-edit-request"
            id="form-edit-request"
            method="post"
            onSubmit={handleEvents.OnSubmit}
          >
            <Feature.Content data={itemData.data} type={itemData.type} />
          </form>
        </div>
        <div key="modal-loading" className="modal-loading hide">
          <Html.Preloading
            addon={{
              css: {
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
              },
            }}
          />
        </div>
      </div>
    );
    footer = (
      <div className="modal-footer-bottom">
        <span className="modal-error-text hide"></span>
        <BtnEditSubmit events={handleEvents.BtnEdit} header={header} />
      </div>
    );
  }

  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={false}
      className="modal-user modal-user-footer"
      id="modal-user-edit"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.OnClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
      {footer}
    </Modal>
  );
}

const Feature = {};
Feature.Content = function ({ data, type }) {
  const handleEvents = {
    InpDuration: {
      OnChange: function (evt) {
        const duration = $(evt.target).val();
        if (duration == "MD") {
          $("#inp-req-enddate").removeAttr("disabled");
          $("#inp-req-starttime").removeAttr("disabled");
          $("#inp-req-endtime").removeAttr("disabled");
          return;
        }
        const starttime = data.working_time["start_time"];
        const endtime = data.working_time["end_time"];
        $("#inp-req-enddate").attr("disabled", true);
        //$("#inp-req-starttime").removeClass("hide");
        //$("#inp-req-endtime").removeClass("hide");
        $("#inp-req-starttime").val(starttime).attr("value", starttime);
        $("#inp-req-endtime").attr("disabled", true);
        if (duration == "FD") {
          //$("#inp-req-starttime").attr("disabled", true);
          $("#inp-req-endtime").val(endtime).attr("value", endtime);
          return;
        }
        let hour = "12";
        if (duration == "1H") {
          hour = "09";
        } else if (duration == "2H") {
          hour = "10";
        } else if (duration == "3H") {
          hour = "11";
        }
        $("#inp-req-endtime")
          .val(hour + ":30")
          .attr("value", hour + ":30");
        //$("#inp-req-starttime").removeAttr("disabled");
      },
    },
    InpStartDate: {
      OnChange: function (evt) {
        let startdate = $(evt.target).val();
        $("#inp-req-enddate").val(startdate).attr("value", startdate);
      },
    },
    InpStartTime: {
      OnChange: function (evt) {
        let duration = $("#inp-req-duration").val();
        const starttime_def = data.working_time["start_time"];
        const starttime_limit = "06:00";
        const endtime_limit = data.working_time["end_time_limit"];
        let starttime = $(evt.target).val().toString();
        let endtime = Func.GetEndTime(starttime, duration);
        if (starttime < starttime_limit || endtime > endtime_limit) {
          starttime = starttime_def;
          endtime = Func.GetEndTime(starttime, duration);
          $("#inp-req-starttime").val(starttime).attr("value", starttime);
        }
        $("#inp-req-endtime").val(endtime).attr("value", endtime);
      },
    },
    InpLeaveType: {
      OnChange: function (evt) {
        const leaveType = $(evt.target).val();
        let elem = $("#content-req-user .error");
        elem.addClass("hide");
        if (leaveType == "CL" || leaveType == "SL") {
          const searchObj = Func.SearchObject(
            data.leave_type,
            leaveType,
            "code"
          );
          const desc = Func.GetArrayColumn(searchObj, "description");
          elem.text(desc);
          elem.removeClass("hide");
        }
        let elemUpload = $("#content-req-upload");
        if (leaveType == "SL") {
          elemUpload.find("input[type=file]").val("");
          elemUpload.find("input[type=text]").val("");
          elemUpload.removeClass("hide");
        } else if (!elemUpload.hasClass("hide")) {
          elemUpload.find("input[type=file]").val("");
          elemUpload.find("input[type=text]").val("");
          elemUpload.addClass("hide");
        }
      },
    },
  };
  let content = [];
  let userPhoto = "";
  if (data.photo != "") {
    userPhoto = (
      <div
        className="image-circle"
        style={{
          background: "url('" + data.photo + "') no-repeat center",
          backgroundSize: "cover",
        }}
      />
    );
  } else {
    userPhoto = (
      <div className="image-circle">
        <i className="material-icons">person</i>
      </div>
    );
  }
  content.push(
    <div
      key="content-req-user"
      className="row content-req"
      id="content-req-user"
    >
      <strong className="col s4 title">{userPhoto}</strong>
      <div className="col s8 desc">
        <span className="name">{data.full_name}</span>
        <span className={"error" + (type == "request" ? "" : " hide")}></span>
      </div>
    </div>
  );
  content.push(
    <div key="content-req-leavetype" className="row content-req">
      <strong className="col s4 inp-title">Leave Type</strong>
      <div className="col s8 desc">
        <Select
          multiple={false}
          id={"inp-req-leavetype"}
          name="leavetype"
          options={{
            classes: "inp-req",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value={data.leavetype_code}
          onChange={handleEvents.InpLeaveType.OnChange}
        >
          <Html.Options
            data={data.leave_type}
            addon={{ key: "name", value: "code" }}
          />
        </Select>
      </div>
    </div>
  );
  content.push(
    <div
      key="content-req-reason"
      className="row content-req"
      id="content-req-reason"
    >
      <strong className="col s4 inp-title">Reason</strong>
      <div className="col s8 desc">
        <Textarea
          className="inp-req"
          id="inp-req-reason"
          name="reason"
          defaultValue={data.reason}
          disabled
        />
      </div>
    </div>
  );
  content.push(
    <div key="content-req-duration" className="row content-req">
      <strong className="col s4 inp-title">Duration</strong>
      <div className="col s8 desc">
        <Select
          multiple={false}
          id={"inp-req-duration"}
          name="duration"
          options={{
            classes: "inp-req",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value={data.duration_type}
          onChange={handleEvents.InpDuration.OnChange}
        >
          <Html.Options
            data={data.leave_duration}
            addon={{ key: "name", value: "code" }}
          />
        </Select>
      </div>
    </div>
  );
  content.push(
    <div key="content-req-from" className="row content-req">
      <strong className="col s4 inp-title">From</strong>
      <div className="col s5 desc-1">
        <TextInput
          className="inp-req"
          id="inp-req-startdate"
          name="startdate"
          type="date"
          defaultValue={data.start_date}
          onChange={handleEvents.InpStartDate.OnChange}
        />
      </div>
      <div className="col s3 desc-2">
        <TextInput
          className="inp-req"
          id="inp-req-starttime"
          name="starttime"
          type="time"
          defaultValue={data.start_time}
          onChange={handleEvents.InpStartTime.OnChange}
        />
      </div>
    </div>
  );
  content.push(
    <div key="content-req-to" className="row content-req">
      <strong className="col s4 inp-title">To</strong>
      <div className="col s5 desc-1">
        <TextInput
          className="inp-req"
          id="inp-req-enddate"
          name="enddate"
          type="date"
          defaultValue={data.end_date}
          disabled={data.duration_type != "MD" ? true : false}
        />
      </div>
      <div className="col s3 desc-2">
        <TextInput
          className="inp-req"
          id="inp-req-endtime"
          name="endtime"
          type="time"
          defaultValue={data.end_time}
          disabled={data.duration_type != "MD" ? true : false}
        />
      </div>
    </div>
  );
  if (data.leavetype_code == "SL" && "file" in data) {
    content.push(
      <div
        key="content-req-file"
        className="row content-req"
        id="content-req-file"
        style={{ marginTop: "15px" }}
      >
        <strong className="col s4 title">File</strong>
        <div className={"col s8 desc"}>
          <a target="_blank" href={data["file"].url}>
            <img className="responsive-img" src={data["file"].url} />
          </a>
        </div>
      </div>
    );
  }
  /*content.push(<div key="content-req-upload" className="row content-req hide" id="content-req-upload">
        <strong className="col s4 inp-title">Upload</strong>
        <div className="col s8 desc">
            <div className="file-field input-field file-upload">
                <div className="btn btn-upload">
                    <span>Upload</span>
                    <input type="file" id="inp-req-upload" multiple />
                </div>
                <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" placeholder="Upload one or more files" />
                </div>
            </div>
        </div>
    </div>);*/
  content.push(
    <input
      key="content-req-countrycode"
      type="hidden"
      name="country_code"
      value={data.country_code}
    />
  );
  content.push(
    <input
      key="content-req-countryid"
      type="hidden"
      name="country_id"
      value={data.country_id}
    />
  );
  content.push(
    <input
      key="content-req-id"
      type="hidden"
      name="request_id"
      value={data.id}
    />
  );

  return <div>{content}</div>;
};
