import React from "react";
import { Button, Icon } from 'react-materialize';

export default function BtnExport({}) {
    const handleEvents = {
        OnClick : function(evt) {
            evt.preventDefault();
        }
    }
    return <Button
        flat
        node="button"
        waves="light"
        className="btn-export textcolor-green"
        id="btn-export"
    >
        Export to sheet
        <Icon right>launch</Icon>
    </Button>
}
