import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Html from "../../utils/Html";
import Func from "../../utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Btn from "./main.content.btn";
import CreateChart from "./create.chart";
import SelectLimit from "./main.content.select.limit";
import SelectPage from "./main.content.select.page";
import TableContent from "./main.content.table";
import ModalViewStaff from "./modal.view.staff";
import BtnExport from "./main.content.btn.export";
import ModalExport from "./modal.export";
import ModalDownload from "./modal.download";

export default function MainContent({
  userData,
  defaultFilters,
  setClearFilters,
}) {
  const userID = userData.idu;
  const defaultData = {
    data: {},
    summary_total: 0,
    total_page: 0,
    active_page: 1,
    status: "",
    err: 0,
    err_msg: "",
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [reqData, setReqData] = useState(defaultData);
  useEffect(() => {
    setReqData({ status: "processing" });
    const mainFunc = async () => await API.post("org/data", {
      params: encryptJsonObject({
        userID: userID,
        filters: filters,
      })
    });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setReqData(decrypt);
        } else {
          setReqData({
            status: "",
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        console.log(`Failes : ${err}`);
        setReqData({ status: "", err: 1, err_msg: err });
      });
  }, [filters]);
  let content = [];
  if ("err" in reqData && reqData.err === 1) {
    content.push(
      <div key="text-error-content" className="text-error">
        Fetch request error: {reqData.err_msg}
      </div>
    );
  } else if (reqData.status !== "success") {
    content.push(
      <div key="preloading-content">
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "50px",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    console.log("MainContent, Status => ", reqData.status);
    if (reqData.data.summary_total > 0) {
      let orgCSS = { display: "none" };
      if (
        ("branch" in filters && filters["branch"].length > 0) ||
        ("department" in filters && filters["department"].length > 0) ||
        ("name" in filters && filters["name"].length > 0) ||
        ("select_page" in filters && filters["select_page"]) ||
        ("select_limit" in filters && filters["select_limit"])
      ) {
        orgCSS = {};
      }
      content.push(
        <div key="outer-org-table" id="outer-org-table">
          <div id="org-table" className={""} style={orgCSS}>
            <div className="row" style={{ position: "relative" }}>
              <div className="title">
                Total :{" "}
                <span id="table-total__number">
                  {reqData.data.summary_total}
                </span>
              </div>
              <div className="box-select-limit">
                <BtnExport />
                <SelectLimit
                  limit={filters.limit}
                  setFilters={setFilters}
                  setReqData={setReqData}
                />
              </div>
            </div>
            <TableContent
              data={reqData.data.users}
              summary_total={reqData.data.summary_total}
            />
            <SelectPage
              totalPage={reqData.data.total_page}
              activePage={reqData.data.active_page}
              setFilters={setFilters}
              setReqData={setReqData}
            />
          </div>
        </div>
      );
    }
    let elem = reqData.data.root_id;
    content.push(
      <div key="org-chart" id="org-chart">
        <CreateChart
          userData={userData}
          orgData={{
            root_id: reqData.data.root_id,
            details: reqData.data.details,
            childs: reqData.data.childs,
            user_depts: reqData.data.user_depts,
            user_details: reqData.data.user_details,
            user_childs: reqData.data.user_childs,
          }}
        />
      </div>
    );
  }
  return (
    <div key="main-content" id="main-content">
      <Btn
        setClearFilters={setClearFilters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      {content}
      <ModalViewStaff userData={userData} />
      <ModalExport />
      <ModalDownload />
      <div id="tmp-print"></div>
      <div id="preview-print"></div>
    </div>
  );
}
