import React, { useState, useEffect } from "react";
import {
  Select,
  TextInput,
  Icon,
  Button,
  Checkbox,
  Table,
} from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Func from "../../utils/Func";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import InnerHtml from "./html";
import Handle from "./handle";
import DeleteItem from "./deleteItem";
//import CreateCalendar from "./createCalendar";

export default function TableContent({
  data,
  filters,
  handleEvents,
  setFilterData,
}) {
  const isEditMode = "is_edit_mode" in filters ? filters["is_edit_mode"] : 0;
  const [tableData, setTableData] = useState({
    data: {},
    status: "processing",
    text: "",
  });
  const form_id = "form-edit-content";
  const [deleteItems, setDeleteItems] = useState({ key: 0, items: [] });
  const [isOpenDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    const mainFunc = async () =>
      await API.post("holiday", {
        params: encryptJsonObject({
          filters: filters,
        }),
      });
    mainFunc()
      .then((res) => {
        filters["is_refresh"] = false;
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          setTableData({ ...decrypt, text: "" });
        } else {
          setTableData({
            data: {},
            status: "error",
            text: `Failes : ` + decrypt?.err_msg ?? "",
            err: 1,
            err_msg: `Failes : ` + decrypt?.err_msg ?? "",
          });
        }
      })
      .catch((err) => {
        filters["is_refresh"] = false;
        setTableData({ data: {}, status: "error", text: `Failed : ${err}` });
      });
  }, [filters]);

  handleEvents.DeleteAllItemChanged = function (evt) {
    let checked = false;
    if ($(evt.target).is(":checked")) {
      checked = true;
    }
    let form = $(evt.target).parents("form").first();
    let items = $(form).find("input[name=delete_items]").val();
    if (items == "") {
      items = [];
    } else {
      items = JSON.parse(items);
    }
    $(form)
      .find("input[name='delete-item']")
      .each(function () {
        let value = $(this).val();
        const pos = items.indexOf(value);
        if (checked) {
          if (pos == -1) {
            items.push(value);
          }
        } else {
          if (pos > -1) {
            items.splice(pos, 1);
          }
        }
      });
    //console.log(items);
    setDeleteItems({ key: Math.floor(Math.random() * 10000 + 1), items: [] });
    filters.delete_items = items;
  };
  handleEvents.DeleteOpenModal = function (evt) {};

  //console.log(handleEvents);
  if (
    tableData.status == "processing" ||
    ("is_refresh" in filters && filters.is_refresh)
  ) {
    return (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "150px",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else if (tableData.status == "error") {
    return <div>Fetch request error: {tableData.text}</div>;
  }
  //console.log("Tables", tableData.status);
  let detailData = [];
  if ("detail_country_codes" in tableData.data && "details" in data) {
    for (const i in tableData.data["detail_country_codes"]) {
      const detail_country_code = tableData.data["detail_country_codes"][i];
      if (detail_country_code in data.details) {
        for (const j in data.details[detail_country_code]) {
          detailData.push(data.details[detail_country_code][j]);
        }
      }
    }
  }
  tableData.data["details"] = detailData;
  let deleteData = {
    key: deleteItems.key,
    items: [],
  };
  if ("delete_items" in filters) {
    deleteData.items = filters.delete_items;
  }
  //console.log("Table");
  return (
    <div id="content-table">
      <div className="total-text">
        Total :{" "}
        <span id="total-text__number">{tableData.data["total_data"]}</span>
      </div>
      <div style={{ minHeight: "460px" }}>
        <Table className="highlight">
          <thead key="thead">
            {InnerHtml.TrHead(
              { details: detailData },
              isEditMode,
              0,
              handleEvents
            )}
          </thead>
          <tbody key="tbody">
            {InnerHtml.TrContent(tableData.data, isEditMode, deleteData)}
          </tbody>
        </Table>
      </div>
      <input
        type="hidden"
        name="delete_items"
        value={JSON.stringify(deleteData.items)}
      />
      <Html.Paginations
        totalPage={tableData.data["total_page"]}
        activePage={parseInt(tableData.data["active_page"])}
        className={"table-pagination"}
        handleEvents={handleEvents.selectPage.OnClick}
      />
      <DeleteItem form_id={form_id} setDeleteItems={setDeleteItems} />
    </div>
  );
}
