import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-materialize";
import Html from "../../utils/Html";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import BtnResend from "./modal.resend.btn";

export default function ModalView() {
  const [itemData, setItemData] = useState({
    data: {},
    status: "",
    err: 0,
    err_msg: "",
  });
  let header = "View leave";
  let content = "";
  let btns = [<div className="header">{header}</div>];
  let modalCSS = {};
  modalCSS.height = "70%";
  const handleEvents = {
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setItemData({ data: {}, status: "", err: 0, err_msg: "" });
    },
    BtnResend: {
      OnComplete: function () {
        setItemData({ data: {}, type: "", status: "", err: 0, err_msg: "" });
      },
    },
  };
  useEffect(() => {
    $("#main-content").on("click", ".btn-view", function (evt) {
      setItemData({ status: "processing" });
      const reqId = $(this).attr("req-id");
      const mainFunc = async () =>
        await API.post("user/leave/request/id", {
          params: encryptJsonObject({
            id: reqId,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("request id=", decrypt);
          if (decrypt?.status === "success") {
            setItemData(decrypt);
          } else {
            setItemData({
              data: {},
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          //console.log(`Failes : ${err}`);
          setItemData({ data: {}, status: "", err: 1, err_msg: err });
        });
    });
  }, []);

  if (itemData.status === "") {
    return "";
  } else if ("err" in itemData && itemData.err === 1) {
    modalCSS.height = "170px";
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="text-error">Fetch request error: {itemData.err_msg}</div>
    );
  } else if (itemData.status !== "success") {
    content = (
      <div>
        <Html.Preloading
          addon={{
            css: {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
            },
          }}
        />
      </div>
    );
  } else {
    //console.log("View", itemData);
    btns.push(
      <Button
        flat
        modal="close"
        node="button"
        waves="red"
        className="modal-close"
      >
        <i className="material-icons">close</i>
      </Button>
    );
    content = (
      <div className="modal-content-outer">
        <div key="modal-content-inner" className="modal-content-inner">
          <Feature.Content data={itemData.data} handleEvents={handleEvents} />
        </div>
        <div key="modal-loading" className="modal-loading hide">
          <Html.Preloading
            addon={{
              css: {
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
              },
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <Modal
      actions={btns}
      bottomSheet={false}
      fixedFooter={false}
      className="modal-user modal-user-footer"
      id="modal-user-view"
      open={true}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: handleEvents.OnClose,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "5%",
      }}
      root={document.getElementById("root")}
      style={modalCSS}
    >
      {content}
    </Modal>
  );
}

const Feature = {};
Feature.Content = function ({ data, handleEvents }) {
  let content = [];
  let userPhoto = "";
  if (data.photo != "") {
    userPhoto = (
      <div
        className="image-circle"
        style={{
          background: "url('" + data.photo + "') no-repeat center",
          backgroundSize: "cover",
        }}
      />
    );
  } else {
    userPhoto = (
      <div className="image-circle">
        <i className="material-icons">person</i>
      </div>
    );
  }
  content.push(
    <div
      key="content-req-user"
      className="row content-req"
      id="content-req-user"
    >
      <strong className="col s4 title">{userPhoto}</strong>
      <div className="col s8 desc">{data.full_name}</div>
    </div>
  );
  content.push(
    <div key="content-req-leavetype" className="row content-req">
      <strong className="col s4 title">Leave Type</strong>
      <div className="col s8 desc">{data.leavetype_name}</div>
    </div>
  );
  content.push(
    <div key="content-req-reason" className="row content-req">
      <strong className="col s4 title">Reason</strong>
      <div className="col s8 desc">
        {data.reason != "" ? data.reason : "N/A"}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-duration" className="row content-req">
      <strong className="col s4 title">Duration</strong>
      <div className="col s8 desc">{data.duration_desc}</div>
    </div>
  );
  content.push(
    <div key="content-req-from" className="row content-req">
      <strong className="col s4 title">From</strong>
      <div className="col s8 desc">
        {data.full_start_date +
          (data.full_start_time != "" ? ", " + data.full_start_time : "")}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-to" className="row content-req">
      <strong className="col s4 title">To</strong>
      <div className="col s8 desc">
        {data.full_end_date +
          (data.full_end_time != "" ? ", " + data.full_end_time : "")}
      </div>
    </div>
  );
  content.push(
    <div key="content-req-status" className="row content-req">
      <strong className="col s4 title">Status</strong>
      <div className={"col s8 desc " + data.status_code}>
        {data.status_desc}
      </div>
    </div>
  );
  if (data.leavetype_code == "SL" && "file" in data) {
    content.push(
      <div
        key="content-req-file"
        className="row content-req"
        id="content-req-file"
      >
        <strong className="col s4 title">File</strong>
        <div className={"col s8 desc"}>
          <a target="_blank" href={data["file"].url}>
            <img className="responsive-img" src={data["file"].url} />
          </a>
        </div>
      </div>
    );
  }
  if (data.status_code == "PD" || data.status_code == "RQ") {
    content.push(
      <div
        key="content-req-resend"
        className="row content-req"
        id="content-req-resend"
      >
        <BtnResend reqID={data.id} events={handleEvents.BtnResend} />
      </div>
    );
  }
  return <div>{content}</div>;
};
