import React from "react";
import { TextInput } from "react-materialize";
import $ from "jquery";
import Handle from "../Container/modal.handle";

export default function InputAnnual({ data, type, userSess }) {
  const handleEvents = {
    OnChange: function (evt) {
      let elem = $(evt.target);
      const inputName = "changed_items";
      const curValue = elem.val();

      const oriValue = elem.attr("ori-value");
      const id = elem.attr("key-id");
      const formElem = elem.parents("form").first();
      let items = $(formElem)
        .find("input[name=" + inputName + "]")
        .val();
      items = items == "" ? [] : JSON.parse(items);
      const pos = items.indexOf(id);
      if (oriValue != curValue && pos == -1) {
        items.push(id);
        $(formElem)
          .find("input[name=" + inputName + "]")
          .val(JSON.stringify(items));
      } else if (oriValue == curValue && pos > -1) {
        items.splice(pos, 1);
        $(formElem)
          .find("input[name=" + inputName + "]")
          .val(JSON.stringify(items));
      }
      if (items.length == 0) {
        Handle.saveButton.OnHide();
      } else {
        Handle.saveButton.OnShow();
      }
      //console.log(items);
    },
  };
  //console.log(userSess["isAdmin"]);
  let cannotEdit = true;
  if (type === "last_year") {
    //userSess["isAdmin"] = false;
    cannotEdit = "isAdmin" in userSess && userSess["isAdmin"] ? false : true;
    return (
      <div key="input-last-year-item">
        <TextInput
          className={
            "inp-assign" + (cannotEdit ? "-disable" : "") + " no-default"
          }
          key-id={data.idu + "-" + data.country_code}
          id="inp-req-last_year_balance"
          name="last_year_balance"
          type="text"
          defaultValue={data.last_year_balance.toString()}
          ori-value={data.last_year_balance}
          onChange={handleEvents.OnChange}
          disabled={cannotEdit}
        />
      </div>
    );
  } // else if (type === "annual_leave_days") {
  //     cannotEdit = "isAdmin" in userSess && userSess["isAdmin"] ? false : true;
  //     return (
  //       <div key="input-annual-item">
  //         <TextInput
  //           className={
  //             "inp-assign" + (cannotEdit ? "-disable" : "") + " no-default"
  //           }
  //           key-id={data.idu + "-" + data.country_code}
  //           id="inp-req-annual_leave_days"
  //           name="annual_leave_days"
  //           type="text"
  //           defaultValue={data.annual_leave_days.toString()}
  //           ori-value={data.annual_leave_days}
  //           onChange={handleEvents.OnChange}
  //           disabled={cannotEdit}
  //         />
  //       </div>
  //     );
  //   }

  //   return (
  //     <div key="input-days-entitled-item">
  //       <TextInput
  //         className="inp-assign no-default"
  //         key-id={data.idu + "-" + data.country_code}
  //         id="inp-req-days_entitled"
  //         name="days_entitled"
  //         type="text"
  //         defaultValue={(data?.days_entitled ?? 0).toString()}
  //         ori-value={data?.days_entitled ?? 0}
  //         onChange={handleEvents.OnChange}
  //       />
  //     </div>
  //   );
  return (
    <div key="input-annual-item">
      <TextInput
        className="inp-assign no-default"
        key-id={data.idu + "-" + data.country_code}
        id="inp-req-annual_leave_days"
        name="annual_leave_days"
        type="text"
        defaultValue={data.annual_leave_days.toString()}
        ori-value={data.annual_leave_days}
        onChange={handleEvents.OnChange}
      />
    </div>
  );
}
