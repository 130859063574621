import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select, Checkbox } from 'react-materialize';
import Html from "../../utils/Html";

export default function FilterOrganization({data, filters}) {
    const [orgFilters, setOrgFilters] = useState({
        data: filters.data, 
        key_form: filters.key_form, 
        checked: false,
        disable: filters.disable
    });
    let handleEvents = {
        OnChange : function(evt){
            
        }
    };
    useEffect(() => {
        
    }, []);
    data.sort(function (x, y) {
        let a = x.id,
            b = y.id;
        return a == b ? 0 : a > b ? 1 : -1;
    });
    //console.log("Filter Organization");
    return <div key={"model-filter-organization" + orgFilters.key_form} className="filter-input-field select" id="model-filter-organization">
        <Select 
            multiple={true} 
            key={"model-filter-inp-organization" + orgFilters.key_form}
            id="model-filter-inp-organization" 
            name="organization" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: false,
                    constrainWidth: true,
                    coverTrigger: false,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            defaultValue={orgFilters.data}
            onChange={handleEvents.OnChange}
            disabled={orgFilters.disable}
        >
            <Html.Options data={data} addon={{
                key: "organization", 
                name: "name", 
                value: "id",
                title: "Organization"
            }} />   
        </Select>
        <span className="select-helper-text hide" id="helper-text-organization">Please select organization</span>
    </div>
}