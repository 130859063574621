import React, { useState, useEffect } from "react";
import { Select, TextInput, Icon, Button, Checkbox, Dropdown, Pagination } from 'react-materialize';
import $, { htmlPrefilter } from 'jquery';
import config from "../config";
import Func from "./Func";

export default {
    NoAccess: function(){
        return (<div key="noaccess-content">
            <h5 style={{textAlign: "center"}}>
                Cannot access this report!
            </h5>
        </div>)
    },
    Paginations : function({totalPage, activePage, className, handleEvents}){
        if(totalPage <= 1){
            return "";
        }
        /*return (<Pagination
            activePage={activePage}
            items={totalPage}
            leftBtn={<Icon>chevron_left</Icon>}
            rightBtn={<Icon>chevron_right</Icon>}
            className={className}
            onSelect={handleEvents}
            />
        );*/
        var beforePage = activePage-1;
        var afterPage = activePage + 1;
        var li = [];
        li.push(<li key="page-index-1" className={((activePage == 1) ? "active" : "waves-effect")}>
            <a className="btn-page" href="#" page-index="1" onClick={handleEvents}>1</a>
        </li>);
        let i = Math.max(2, activePage - 5);
        if (i > 2)
            li.push(<li  key="page-index-dot-1" style={{lineHeight: "34px"}}>...</li>);
        for (; i < Math.min(activePage + 6, totalPage); i++) {
            var liClass = (activePage == i) ? "active" : "waves-effect";
            li.push(<li  key={"page-index-" + i} className={liClass}>
                <a className="btn-page" href="#" page-index={i} onClick={handleEvents}>{i}</a>
            </li>);
        }
        if (i != totalPage)
            li.push(<li  key="page-index-dot-2" style={{lineHeight: "34px"}}>...</li>);
        li.push(<li  key={"page-index-" + totalPage} className={((activePage == totalPage) ? "active" : "waves-effect")}>
            <a className="btn-page" href="#" page-index={totalPage} onClick={handleEvents}>{totalPage}</a>
        </li>);
    
        return (<ul className="pagination" style={{textAlign: "center"}}>
            <li key="page-index-before" className={(beforePage <= 0 ? "disabled" : "waves-effect")}>
                <a className="btn-page" href="#" page-index={(beforePage <= 0 ? 1: beforePage)} onClick={handleEvents}>
                    <i className="material-icons">chevron_left</i>
                </a>
            </li>
            {li}
            <li key="page-index-after" className={(afterPage > totalPage ? "disabled" : "waves-effect")}>
                <a className={"btn-page a" + afterPage + " p" + totalPage} href="#" page-index={(afterPage > totalPage ? totalPage : afterPage)} onClick={handleEvents}>
                    <i className="material-icons">chevron_right</i>
                </a>
            </li>
        </ul>);
    },
    Options : function({data, addon}){
        let keyName = addon.key;
        let columnName = ('name' in addon) ? addon.name : "name";
        let columnValue = ('value' in addon) ? addon.value : "value";
        let title = ('title' in addon) ? addon.title : "";

        let options = []
        if(title != "") {
            options.push(<option key={"option-" + keyName} disabled value="">{title}</option>);
        }
        for (const i in data) {
            let row = data[i];
            let value = (columnValue in row) ? row[columnValue] : row[columnName];
            if('isHead' in row && row.isHead) {
                options.push(<option key={"option-" + keyName + "-" + i} disabled value={value}>
                    {row[columnName]}
                </option>);
                continue;
            }
            options.push(<option key={"option-" + keyName + "-" + i} value={value}>
                {row[columnName]}
            </option>);
            //if(keyName=="leavestatus") console.log(row[columnName]);
        }
        return options;
    },
    Preloading : function({addon}){
        let addClass = ('class' in addon && addon.class != "" && addon.class != undefined) ? " " + addon.class : "";
        let addCSS = ('css' in addon && addon.css != "" && addon.css != undefined) ? addon.css : {};
        let loading = <div className={"preloader-wrapper active" + addClass} style={addCSS}>
            <div className="spinner-layer spinner-green-only">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div>
                <div className="gap-patch">
                    <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
        return loading;
    }
}