import React, { useState, useEffect } from "react";
import { Button, Modal, Checkbox } from 'react-materialize';
import $ from 'jquery';
import Html from "../../utils/Html";
//import BtnExportSubmit from "./modal.export.btn.submit";
import html2canvas from "html2canvas";


export default function ModalDownload() {
    const [itemData, setItemData] = useState({data: {}, type: "", status: "", err: 0, err_msg: ""});
    //const [exportType, setExportType] = useState({displayed: 0, all: 1, key: 1});
    let header = "Download to PNG";
    let content = "";
    let footer = "";
    let btns = [
        <div className="header">
            {header}
        </div>
    ];
    let modalCSS = {};
    modalCSS.height = "240px";
    const handleEvents = {
        BtnDownload : {
            OnComplete : function() {
                setItemData({data: {}, type: "", status: "", err: 0, err_msg: ""});
                //setExportType({displayed: 0, all: 1, key: 1});
            }
        },
        OnClose : function() {
            $("body").css({"overflow": "auto"});
            setItemData({data: {}, type: "", status: "", err: 0, err_msg: ""});
        },
        OnSubmit : function(evt) {
            // Form Submit
            evt.preventDefault();
        } 
    }
    const downloadPNG = function(){
        console.log("Print");
        const tmpElemId = "tmp-tree";
        let clone = $(".organizational-chart").clone();
        let tmpElem = document.createElement("div");
        $(tmpElem).attr("id", "tmp-tree").css({
            "display": "flex",
            "justify-content": "left"
        }).
         html(clone);
        $("#tmp-print").html(tmpElem);
        $("#" + tmpElemId + " .staff .image-circle").css({
            "background-size": "cover"
        });
        let canvasWidth = $("#" + tmpElemId + " .organizational-chart").innerWidth(); 
        let canvasHeight = $("#" + tmpElemId + " .organizational-chart").innerHeight() + 30;
        //console.log(canvasWidth, canvasHeight);
        html2canvas(document.querySelector("#" + tmpElemId),{ 
            logging: true, 
            letterRendering: 1, 
            allowTaint: false, 
            useCORS: true ,
            width: canvasWidth, 
            height: canvasHeight
        }).then(canvas => {
            var ctx = canvas.getContext('2d');
            ctx.webkitImageSmoothingEnabled = false;
            ctx.mozImageSmoothingEnabled = false;
            ctx.imageSmoothingEnabled = false;
            var anchorTag = document.createElement("a");
            document.body.appendChild(anchorTag);
            //document.getElementById("preview-print").appendChild(canvas);		
            anchorTag.download = "organization.png";
            //anchorTag.href = canvas.toDataURL('image/jpeg');
            anchorTag.href = canvas.toDataURL('image/png',1.0);
            anchorTag.target = '_blank';
            anchorTag.click();
            $("#" + tmpElemId).remove();
            setItemData({
                data: {},
                status: "success",
                err: 0,
                err_msg: ""
            }); 
        });
        return;
    }
    useEffect(() => {
        $("#main-content").on("click", "#btn-download-chart", function(evt){
            setItemData({
                data: {},
                status: "inprocess",
                err: 0,
                err_msg: ""
            });
            downloadPNG();
        });
    }, []);

    if(itemData.status === "") {
        return "";
    }
    else if ('err' in itemData && itemData.err === 1) {
        modalCSS.height = "170px";
        btns.push(<Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>);
        content = <div className="text-error">Fetch request error: {itemData.err_msg}</div>;
    }
    else if (itemData.status !== "success") {
        content = <div>
            <Html.Preloading addon={{
                css: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    margin: "auto"
                }
            }} />
        </div>;
    }
    else {
        //console.log("Edit", itemData);
        btns.push(<Button flat modal="close" node="button" waves="red" className="modal-close">
            <i className="material-icons">close</i>
        </Button>); 
        /*content = <div className="modal-content-outer">
            <div key="modal-content-inner" className="modal-content-inner">
                <Feature.Content 
                    data={exportType} 
                    handleEvents={handleEvents.CheckboxExport} />
            </div>
            <div key="modal-loading" className="modal-loading hide">
                <Html.Preloading addon={{
                    css: {
                        position: "absolute",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        margin: "auto"
                    }
                }} />
            </div>
        </div>
        footer = <div className="modal-footer-bottom">
            <span className="modal-error-text hide"></span>
            <BtnExportSubmit 
                events={handleEvents.BtnExport} 
                header={header} />
        </div>*/
        content = <div className="modal-content-outer">
            <div key="modal-content-inner" className="modal-content-inner">
                <a className="btn-floating waves-effect waves-light">
                    <i className="material-icons">check</i>
                </a>
                <span style={{marginLeft: "20px"}}>
                    Your Organization Chart has been downloaded.
                </span>
            </div>
            <div key="modal-loading" className="modal-loading hide">
                <Html.Preloading addon={{
                    css: {
                        position: "absolute",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        margin: "auto"
                    }
                }} />
            </div>
        </div>
        footer = "";
    }

    return (<Modal
        actions={btns}
        bottomSheet={false}
        fixedFooter={false}
        className="modal-user modal-user-footer"
        id="modal-user-download"
        open={true}
        options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: handleEvents.OnClose,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '5%'
        }}
        root={document.getElementById("root")}
        style={modalCSS}
        >
        {content}
        {footer}
    </Modal>);
}

const Feature = {};
Feature.Content = function({data, handleEvents}) {
    let content = [];
    content.push(<div key="content-export_displayed" className="row" id="content-export_displayed" style={{width: "80%"}}>
        <div className="col s4 title" style={{marginTop: "5px", textAlign: "right"}}>
            <Checkbox
                filledIn
                checked={(data.displayed == 1) ? true : false}
                key={"export-column-displayed-" + data.key}
                key-number={data.key}
                name="export-column-displayed"
                label=""
                value={"displayed"}
                onChange={handleEvents.OnChange}
            />
        </div>
        <span className="col s8 desc" style={{lineHeight: "30px", height: "30px"}}>
            Columns as displayed
        </span>
    </div>);
    content.push(<div key="content-export_all" className="row" id="content-export_all" style={{width: "80%"}}>
        <div className="col s4 title" style={{marginTop: "5px", textAlign: "right"}}>
            <Checkbox
                filledIn
                checked={(data.all == 1) ? true : false}
                key={"export-column-all-" + data.key}
                key-number={data.key}
                name="export-column-all"
                label=""
                value="all"
                onChange={handleEvents.OnChange}
            />
        </div>
        <span className="col s8 desc" style={{lineHeight: "30px", height: "30px"}}>
            All Columns
        </span>
    </div>);
    return <div style={{paddingTop: "15px"}}>
        {content}
    </div>
}