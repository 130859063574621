import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Select, TextInput } from 'react-materialize';
import Html from "../../utils/Html";
import Func from "../../utils/Func";
import FilterCountry from "./top.content.filter.country";
import FilterLocation from "./top.content.filter.location";
import FilterDepartment from "./top.content.filter.department";

export default function TopContent({userData, filterData}) {
    const [filters, setFilters] = useState({
        country: [((userData.is_exo_group == "yes") ? "GROUP" : userData.employed_by_code)],
        year: [Func.GetCurrentYear()], 
        key_form: 0
    });
    useEffect(() => {
        $("#btn-clear").on("click", function(){
            setFilters({
                country: [((userData.is_exo_group == "yes") ? "GROUP" : userData.employed_by_code)],
                year: [Func.GetCurrentYear()], 
                key_form: Math.random() 
            });
        });
    }, []);
    const handleEvents = {
        FilterDate : {
            OnChangeEndDate : function(evt) {
                let elem = $(evt.target);
                const startDate = $("#filter-inp-start-date").val();
                const maxEndDate = Func.GetNextMonth(startDate,6);
                const endDate = elem.val();
                const d1 = new Date(maxEndDate); 
                const d2 = new Date(endDate);
                const helper = $("#helper-text-end-date");
                if(d2 > d1){
                    helper.removeClass("hide");
                    $("#filter-inp-end-date").val(maxEndDate).attr("value", maxEndDate);
                }
                else{
                    helper.addClass("hide");
                }
            },
            OnChangeStartDate : function(evt) {
                let elem = $(evt.target);
                const startDate = elem.val();
                const endDate = Func.GetNextMonth(startDate,6);
                $("#filter-inp-end-date").val(endDate).attr("value", endDate);
            }
        },
        FormFilter : {
            OnSubmit : function(evt) {
                evt.preventDefault();
                //console.log("Form Submit");
            }
        }
    }
    //<Feature.FilterCountry data={filterData.countries} filters={filters} handleEvents={handleEvents.FilterCountry} />
    //<Feature.FilterLocation data={filterData.locations} filters={filters} userData={userData} />
    //<Feature.FilterDepartment data={filterData.departments} filters={filters} />
    return (<div id="top-content">
        <form key={"form-filter-request" + filters.key_form} id="form-filter-request" method="post" onSubmit={handleEvents.FormFilter.OnSubmit}>
            <div className="filter-inputs row">
                <FilterCountry
                    data={filterData.countries} 
                    filters={{
                        data: ('country' in filters) ? filters.country : [],
                        key_form: filters.key_form
                    }}
                    setFilters={setFilters} />
                <FilterLocation
                    data={filterData.locations} 
                    filters={{
                        data: ('location' in filters) ? filters.location : [],
                        key_form: filters.key_form,
                        country: ('country' in filters) ? filters.country : []
                    }} />
                <FilterDepartment
                    data={filterData.departments} 
                    filters={{
                        data: ('department' in filters) ? filters.department : [],
                        key_form: filters.key_form,
                        country: ('country' in filters) ? filters.country : []
                    }} />
                <Feature.FilterYear data={filterData.years} filters={filters} />
                <Feature.FilterEmail filters={filters} />
                <Feature.FilterName filters={filters} />
            </div>
        </form>
    </div>);
}

const Feature = {};
Feature.FilterYear = ({data, filters}) => {
    let year = Func.GetCurrentYear();
    if('year' in filters && filters["year"].length > 0) {
        year = filters["year"][0];
    }
    return <div key="filter-year" className="filter-input-field select">
        <Select 
            multiple={false} 
            id="filter-inp-duration" 
            name="year" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            defaultValue={year} 
        >
            <Html.Options data={data} addon={{
                key: "year", 
                name: "year", 
                value: "year",
                title: "Year"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-duration">Please select year</span>
    </div>
}
Feature.FilterDuration = ({data, filters}) => {
    return <div key="filter-duration" className="filter-input-field select">
        <Select 
            multiple={true} 
            id="filter-inp-duration" 
            name="duration" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={[]}
        >
            <Html.Options data={data} addon={{
                key: "duration", 
                name: "duration_desc", 
                value: "duration_code",
                title: "Duration"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-duration">Please select duration</span>
    </div>
}
Feature.FilterName = ({filters}) => {
    return <div key="filter-name" className="filter-input-field">
        <TextInput
            //icon={<Icon>search</Icon>}
            placeholder=""
            id="filter-inp-name" 
            name="name" 
            label="Name Search"
        />
    </div>
}
Feature.FilterEmail = ({filters}) => {
    return <div key="filter-email" className="filter-input-field">
        <TextInput
            //icon={<Icon>search</Icon>}
            placeholder=""
            id="filter-inp-email" 
            name="email" 
            label="Email Search"
        />
    </div>
}
Feature.FilterStatus = ({data, filters}) => {
    return <div key="filter-status" className="filter-input-field select">
        <Select 
            multiple={true} 
            id="filter-inp-status" 
            name="status" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={[]}
        >
            <Html.Options data={data} addon={{
                key: "status", 
                name: "name", 
                value: "code",
                title: "Leave Status"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-status">Please select Status</span>
    </div>
}
Feature.FilterLeaveType = ({data, filters}) => {
    let itemData = [];
    let country = [];
    let n = 0;
    if('country' in filters && filters.country.length > 0) {
        country = filters.country;
        n = country.length;
    }
    for(let name in data) {
        let values = data[name];
        if(n > 0) {
            let searchObj = Func.SearchObject(values, country, "country_code");
            if(Object.keys(searchObj).length > 0) {
                itemData.push({name: Func.HtmlDecode(name), value: values[0]["leavetype_code"]});
            }
        }
        else {
            itemData.push({name: Func.HtmlDecode(name), value: values[0]["leavetype_code"]});
        }
    }
    //console.log(itemData);
    return <div key="filter-leavetype" className="filter-input-field select">
        <Select 
            multiple={true} 
            id="filter-inp-leavetype" 
            name="leavetype" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={[]}
        >
            <Html.Options data={itemData} addon={{
                key: "leavetype", 
                name: "name", 
                value: "value",
                title: "Leave Type"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-leavetype">Please select leavetype</span>
    </div>
}
Feature.FilterDepartment = ({data, filters}) => {
    let itemData = [];
    let country = [];
    let n = 0;
    if('country' in filters && filters.country.length > 0) {
        country = filters.country;
        n = country.length;
    }
    for(let name in data) {
        if(n > 0) {
            let values = data[name];
            let searchObj = Func.SearchObject(values, country, "country_code");
            if(Object.keys(searchObj).length > 0) {
                itemData.push({name: Func.HtmlDecode(name)});
            }
        }
        else {
            itemData.push({name: Func.HtmlDecode(name)});
        }
    }
    //console.log("Searches", itemData);
    return <div key="filter-department" className="filter-input-field select">
        <Select 
            multiple={true} 
            id="filter-inp-department" 
            name="department" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={[]}
        >
            <Html.Options data={itemData} addon={{
                key: "department", 
                name: "name", 
                value: "name",
                title: "Department"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-department">Please select department</span>
    </div>
}
Feature.FilterLocation = ({data, filters, userData}) => {
    let itemData = [];
    let searchObj = data;
    let n = 0;
    if('country' in filters && filters.country.length > 0) {
        let country = filters.country;
        n = country.length;
        searchObj = Func.SearchObject(data, country, "country_code");
    }
    for(let i in searchObj) {
        let row = searchObj[i];
        if(n == 0 && row.country_code == "GROUP") continue;
        let name = "";
        if('full_city' in row) {
            name = row.full_city;
        }
        else{
            name = row.city;
            if(n != 1) {
                name += " ("+ row.country_code +")";
            } 
        } 
        itemData.push({name: name, value: row.city});
    }
    return <div key="filter-location" className="filter-input-field select">
        <Select 
            multiple={true} 
            id="filter-inp-location" 
            name="location" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={[]}
        >
            <Html.Options data={itemData} addon={{
                key: "location", 
                name: "name", 
                value: "value",
                title: "Location"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-location">Please select location</span>
    </div>
}
Feature.FilterCountry = ({data, filters, handleEvents}) => {
    let country = "";
    if('country' in filters && filters["country"].length > 0){
        country = filters.country[0];
    }
    return <div key="filter-country" className="filter-input-field select">
        <Select 
            multiple={false} 
            id="filter-inp-country" 
            name="country" 
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }} 
            defaultValue={country}
            onChange={handleEvents.OnChange}
        >
            <Html.Options data={data} addon={{
                key: "country", 
                name: "country_name", 
                value: "country_code",
                title: "Country"
            }} />
        </Select>
        <span className="select-helper-text hide" id="helper-text-country">Please select country</span>
    </div>
}

