import React from "react";
import BtnAction from "./main.content.btn.action";
import Func from "../../utils/Func";

export default function TrHead({setActionData, actionData, active_page, year, userSess, expired_date}) {
    const data = [
        "",
        "Person",
        "Country",
        // "Days entitled",
        "Annual leave days",
        "Last year balance",
        "Current year balance",
        "Leave days taken",
        "Leave days expired",
        "Leave days balance",
        "Sick leave used",
        //"Compensation leave days",
        "Compensatory leave used"
    ];
    
    let from_date = Func.ConvertDateFormat(year + "-01-01");
    let expires_date = "";
    let after_expires_date = "";
    if(expired_date != "" && expired_date != undefined) {
        expires_date = Func.ConvertDateFormat(expired_date);
        let expired_date_arr = Func.GetDateArray(expired_date);
        after_expires_date = Func.ConvertDateFormat(Func.GetNextMonth(expired_date_arr.year + "-" + expired_date_arr.month + "-01", 1));//Func.ConvertDateFormat(expired_date);
    }
    let list = [];
    for(const i in data) {
        let detail = "";
        if(i === 3 || i === 5) {
            detail = <div className="detail">From<br />{from_date}</div>
        }
        else if(i === 4 && expires_date != "") {
            detail = <div className="detail">Expires on<br />{expires_date}</div>
        }
        else if(i === 7 && after_expires_date !== "") {
            detail = <div className="detail">From<br />{after_expires_date}</div>
        }
        list.push(<th key={"col-" + i} className={"th-" + data[i].toLocaleLowerCase()}>
            {data[i]}
            {detail}
        </th>);
    }
    list.push(<th key={"col-" + (data.length)} className="th-bulk-action">
        <BtnAction 
            setActionData={setActionData} 
            actionData={actionData} 
            active_page={active_page}
            year={year}
            userSess={userSess} />
    </th>);
    return <tr key="row-0">
        {list}    
    </tr>
}