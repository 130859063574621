import React, { useState, useEffect } from "react";
import {
  Select,
  TextInput,
  Icon,
  Button,
  Checkbox,
  Table,
  Dropdown,
} from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import Func from "../../utils/Func";
import Html from "../../utils/Html";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import DynamicSelect from "./DynamicSelect";

export default function FilterSearch({ country, filterData }) {
  const min_year = Func.GetCurrentYear() - 2;
  const [itemData, setItemData] = useState({
    max_year: 0,
    country_id: filterData.country_id,
    year: filterData.year,
    country: country,
  });
  const [countryId, setCountryId] = useState(filterData.country_id);

  useEffect(() => {
    let country_id = countryId;
    if ("is_refresh" in filterData && filterData.is_refresh) {
      country_id = filterData.country_id;
    }
    const mainFunc = async () =>
      await API.post("holiday/get/lastyear", {
        params: encryptJsonObject({
          country_id: country_id,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        let lastyear = min_year;
        if (decrypt?.status === "success" && decrypt?.lastyear) {
          lastyear = decrypt.lastyear;
        }
        setItemData({
          max_year: lastyear,
          country_id: country_id,
          year: filterData.year,
          country: country,
        });
      })
      .catch((err) => {
        setItemData({
          max_year: min_year,
          country_id: country_id,
          year: filterData.year,
          country: country,
        });
        //console.log(`Failes : ${err}`);
      });
  }, [countryId, filterData.country_id]);

  const handleEvents = {
    filterCountry: {
      OnChange: function (evt) {
        let elem = $(evt.target);
        let country_id = elem.val();
        setCountryId(country_id);
      },
    },
  };
  //console.log("1. Filter Year", itemData, filterData);
  //console.log(itemData);
  if (itemData.max_year == 0) {
    //return "";
  }
  let content = [];
  //content.push(<div key="test1"><DynamicSelect itemData={itemData} /></div>);
  content.push(
    <div key="filter-country" className="filter-input-field">
      <Select
        label="Country"
        multiple={true}
        id="filter-inp-country"
        name="country_id"
        options={{
          classes: "",
          dropdownOptions: {
            alignment: "left",
            autoTrigger: true,
            closeOnClick: true,
            constrainWidth: true,
            coverTrigger: true,
            hover: false,
            inDuration: 150,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 250,
          },
        }}
        value={itemData.country_id}
        onChange={handleEvents.filterCountry.OnChange}
      >
        <Html.Options
          data={itemData.country}
          addon={{ key: "country", name: "country_name", value: "id" }}
        />
      </Select>
      <span className="select-helper-text hide" id="helper-text-country">
        Please select country
      </span>
    </div>
  );
  content.push(
    <div key="filter-year" className="filter-input-field">
      <Select
        label="Year"
        multiple={true}
        id="filter-inp-year"
        name="year"
        options={{
          classes: "",
          dropdownOptions: {
            alignment: "left",
            autoTrigger: true,
            closeOnClick: true,
            constrainWidth: true,
            coverTrigger: true,
            hover: false,
            inDuration: 150,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 250,
          },
        }}
        value={itemData.year}
      >
        <Html.Options
          data={Func.GetRangeYears(min_year, itemData.max_year)}
          addon={{ key: "year" }}
        />
      </Select>
      <span className="select-helper-text hide" id="helper-text-year">
        Please select year
      </span>
    </div>
  );
  return content;
}
