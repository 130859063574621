import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import $ from "jquery";
import config from "../../config";
import API from "../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../utils/encryption";
import Handle from "../Container/modal.handle";
import ModalDone from "../Container/modal.done";
import ModalError from "../Container/modal.error";
import ModalWarning from "../Container/modal.warning";

export default function BtnModifySubmit({ events, header, year }) {
  const [resData, setResData] = useState({ status: "", err: 0, err_msg: "" });
  const callbackEvent = {
    OnClose: function () {
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnComplete: function () {
      events.OnComplete();
    },
  };
  const handleEvents = {
    OnClose: function () {
      $("body").css({ overflow: "auto" });
      setResData({ status: "", err: 0, err_msg: "" });
    },
    OnSubmit: function () {
      Handle.loading.OnShow();
      const inputName = "action_items";
      const action_items = $("input[name=" + inputName + "]").val();
      let items = action_items == "" ? [] : JSON.parse(action_items);
      let percentage = $("#inp-req-percentage").val();
      if (items.length == 0) {
        Handle.loading.OnHide();
        return;
      }
      if (percentage == "" || isNaN(percentage)) {
        Handle.loading.OnHide();
        return;
      }
      let formData = [];
      for (let i in items) {
        const keyId = items[i];
        let sp = keyId.split("-");
        let tmp = {
          idu: sp[0],
          country_code: sp[1],
          percentage: percentage,
        };
        formData.push(tmp);
      }
      if (formData.length == 0) {
        Handle.loading.OnHide();
        return;
      }
      //console.log(formData, year);return;
      const mainFunc = async () =>
        await API.post("hr/assign/modify", {
          params: encryptJsonObject({
            forms: formData,
            year: year,
            userID: window.$user_profile.idu,
          }),
        });
      mainFunc()
        .then((res) => {
          Handle.loading.OnHide();
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setResData(decrypt);
          } else {
            setResData({
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          Handle.loading.OnHide();
          setResData({ err: 1, err_msg: err });
        });
    },
  };
  //console.log("Edit Submit", resData);
  if (resData?.err === 1) {
    return (
      <ModalWarning
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if (resData?.err === 2) {
    return (
      <ModalError
        header={header}
        content={resData.err_msg}
        callbackEvent={callbackEvent}
      />
    );
  } else if (resData?.status === "success") {
    return (
      <ModalDone
        header={header}
        content="This update is done!"
        callbackEvent={callbackEvent}
      />
    );
  }
  return (
    <Button
      className=""
      id="btn-submit-modify"
      node="button"
      onClick={handleEvents.OnSubmit}
    >
      Confirm
    </Button>
  );
}
