import React from "react";
import { Button, Dropdown, Checkbox } from 'react-materialize';
import $ from 'jquery';

export default function BtnAction({setActionData, actionData, active_page, year, userSess}) {
    const handleEvents = {
        OnChange : function(evt) {
            evt.preventDefault();
            let checked = false;
            if($(evt.target).is(":checked")){
                checked = true;
            }
            // Items
            let form = $(evt.target).parents("form").first();
            let items = $(form).find("input[name=action_items]").val();
            if(items == ""){
                items = [];
            }
            else{
                items = JSON.parse(items);
            }
            $(form).find("input[name='action-item']").each(function () {
                let value = $(this).val();
                const pos = items.indexOf(value);
                if(checked) {
                    if(pos == -1) {
                        items.push(value);
                    }
                }
                else{
                    if(pos > -1) {
                        items.splice(pos, 1);
                    }
                }
            });
            // END Items
            // Pages
            let pages = $(form).find("input[name=action_pages]").val();
            if(pages == ""){
                pages = [];
            }
            else{
                pages = JSON.parse(pages);
            }
            const pos_page = pages.indexOf(active_page);
            if(checked && pos_page == -1) {
                pages.push(active_page);
            }
            else if(!checked && pos_page > -1){
                pages.splice(pos_page, 1);
            }
            // END Pages
            //console.log(items, pages);
            setActionData({
                key: (Math.floor((Math.random() * 10000) + 1)),
                items: items,
                pages: pages,
                checked: checked
            }); 
        }
    }
    let action_pages = ('pages' in actionData) ? actionData.pages : [];
    let actionChecked = (action_pages.indexOf(active_page) > -1) ? true : false;
    let btn_options = [];
    btn_options.push(<span key="btn-action-update" className="btn-open-modal-action" id="btn-action-update" data-type="update">
        Update annual leave
    </span>);
    if(year.length > 0 && year[0] > 2021) {
        btn_options.push(<span key="btn-action-copy" className="btn-open-modal-action" id="btn-action-copy" data-type="copy">
            Copy from the previous year
        </span>);
    }
    if('isAdmin' in userSess && userSess["isAdmin"]) {
        btn_options.push(<span 
            key="btn-action-modify" 
            className="btn-open-modal-action red-text" 
            id="btn-action-modify" data-type="update">
            Modify unused balance
        </span>);
    }

    return  <div key="btn-bulk-action" style={{marginTop: "-9px"}}> 
        <Dropdown
            id={"dropdown-bulk-action"}
            options={{
                alignment: 'left',
                autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                container: null,
                coverTrigger: true,
                hover: false,
                inDuration: 150,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 250
            }}
            trigger={
            <Button flat node="button" style={{float: "left", width: "110px", fontWeight: "bold", padding: "0px", whiteSpace: "nowrap", fontSize: "13px"}}>
                <i className="material-icons right">arrow_drop_down</i>Bulk Actions
            </Button>
            }
        >
            {btn_options}
        </Dropdown>
        <Checkbox
            key={"action-all-item-" + actionData.key}
            checked={actionChecked}
            filledIn
            name="action-all-item"
            label=""
            value="1"
            onClick={handleEvents.OnChange}
        />
    </div>
}
