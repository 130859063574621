import React from "react";
import { Button, Icon } from 'react-materialize';
import $ from 'jquery';
import Html from "../../utils/Html";
import Func from "../../utils/Func";

export default function BtnExport({}) {
    const handleEvents = {
        OnClick : function(evt) {
            evt.preventDefault();
        }
    }
    return <Button
        flat
        node="button"
        waves="light"
        id="btn-export"
        className="btn-export textcolor-green"
    >
        Export to sheet
        <Icon right>launch</Icon>
    </Button>
}
